import {observer} from 'mobx-react';
import React from 'react';

import ModalType from '../stores/ModalType';
import {ModalItem} from '../stores/ModalsStore';

import ModalConfirm from './ModalConfirm';
import ModalProfileForgotPassword, {ForgotPasswordStep} from './ModalProfileForgotPassword';
import ModalForceVersionUpdateApp from './ModalForceVersionUpdateApp';

interface IProps {
  modal: ModalItem;
}

export const NonAuthModalWrapper: React.FC<IProps> = observer((props) => {
  switch (props.modal.type) {
    case ModalType.CONFIRM:
      return <ModalConfirm modal={props.modal} />;

    case ModalType.PROFILE_FORGOT_PASSWORD_EXPIRED_LINK:
      return <ModalProfileForgotPassword modal={props.modal} step={ForgotPasswordStep.EXPIRED_LINK} />;

    case ModalType.FORCE_VERSION_UPDATE_APP:
      return <ModalForceVersionUpdateApp modal={props.modal} />;
  }

  return null;
});

export default NonAuthModalWrapper;
