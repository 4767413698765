import EventEmitter from '../../api/EventEmitter';
import MessageAttachment from '../Attachment/MessageAttachment';
import Channel from '../Channel';
import Chat from '../Chat';
import ContextMenuType from './ContextMenuType';
import Message from '../Message';
import MessagePreview from '../Message/MessagePreview';
import {ITag} from '../MessageTagsStore';
import {ITextSelection} from '../TextSelection';
import Invite from '../Workspaces/Invite';
import WorkspaceMember from '../Workspaces/WorkspaceMember';
import WorkspaceMemberConfig from '../Workspaces/WorkspaceMemberConfig';
import ContextMenuStore from './ContextMenuStore';

export interface IMenuPosition {
  clientX: number;
  clientY: number;
  verticalOffset?: number;
  rightAlign?: boolean;
}

export class ContextMenuData {
  channel?: Channel | null;
  chat?: Chat | null;
  message?: Message | null;
  messagePreview?: MessagePreview | null;
  messages?: Message[] | null;
  albumMessage?: Message | null;
  attachment?: MessageAttachment | null;
  callbacks?: ICallback | null;
  textSelection?: ITextSelection;
  tag?: ITag | null;
  member?: WorkspaceMember | null;
  memberConfig?: WorkspaceMemberConfig | null;
  invite?: Invite | null;
}

export interface IContextMenuItem {
  id: number;
  position: IMenuPosition;
  type: ContextMenuType;
  data: ContextMenuData;
}

export interface ICallback {
  [name: string]: (data: ContextMenuData) => void;
}

export enum ContextMenuEvent {
  CLOSE = 'close',
}

export default class ContextMenuItem extends EventEmitter implements IContextMenuItem {
  id: number;
  position: IMenuPosition;
  type: ContextMenuType;
  data: ContextMenuData;

  constructor(props: IContextMenuItem, private contextMenu: ContextMenuStore) {
    super();

    this.id = props.id;
    this.position = props.position;
    this.type = props.type;
    this.data = props.data;
  }

  close = () => {
    this.emit(ContextMenuEvent.CLOSE);
    this.contextMenu.close();
  };

  trigger = (name: string) => {
    if (this.data.callbacks && this.data.callbacks[name] instanceof Function) {
      this.data.callbacks[name](this.data);
      this.close();
    }
  };
}
