import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';

import {IUpdate} from '../../api/proto';
import {UserChatRouteParams, generatePathToMessage, navigateToChat, navigateToMessage} from '../../routes/Paths';
import {base64ToUint8Array, equalUint8Arrays, uint8ArrayToUuid} from '../../utils/arrayUtils';
import AudioStore from '../AudioStore';
import Channel from '../Channel';
import channelIdFromParam from '../Channel/utils/channelIdFromParam';
import Chat from '../Chat';
import getChatAssignedType from '../Chat/utils/getChatAssignedType';
import getChatPreviewStatus from '../Chat/utils/getChatPreviewStatus';
import ChatTab from '../LayOutStore/ChatTab';
import Message from '../Message';
import MessageTagsStore from '../MessageTagsStore';
import Peer from '../Peer';
import ChatsPoolEvent from '../RawChatsStore/ChatsPoolEvent';
import {IRawChat} from '../RawChatsStore/RawChat';
import initDefaultChatFields from '../RawChatsStore/utils/initDefaultChatFields';
import TextEditorProxy from '../TextEditorProxy';
import VideoPlayerStore from '../VideoPlayerStore';
import WorkspaceStore from '../Workspaces/WorkspaceStore';
import ChatsFilterStore from './ChatsFilterStore';
import ChatsSearch from './ChatsSearch';
import ChatsFoundItem from './utils/ChatsFoundItem';
import composeSearchResult from './utils/composeSearchResult';


const FAKE_CHAT_ID = Long.fromNumber(-1);


export class ChatsView {
  public editorProxy: TextEditorProxy = new TextEditorProxy();
  public fileCaptionEditorProxy: TextEditorProxy = new TextEditorProxy();

  public tags: MessageTagsStore;
  public audioPlayer: AudioStore;
  public videoPlayerStore: VideoPlayerStore;

  public search: ChatsSearch;
  public filter: ChatsFilterStore;

  constructor(protected workspace: WorkspaceStore) {
    this.tags = new MessageTagsStore(workspace.app);
    this.audioPlayer = new AudioStore(workspace.app);
    this.videoPlayerStore = new VideoPlayerStore(workspace.app);
    this.filter = new ChatsFilterStore(this, workspace.app);
    this.search = new ChatsSearch(workspace);

    makeObservable(this);

    workspace.chatsPool.on(ChatsPoolEvent.ANY_CHAT_UPDATE, this.onAnyChatUpdate_);
    workspace.chatsPool.on(ChatsPoolEvent.ANY_CHAT_PARTIAL_UPDATE, this.onAnyChatUpdate_);
    workspace.chatsPool.on(ChatsPoolEvent.CHANNEL_COUNTER_RESET, this.onChannelCounterReset_);
  }

  @observable public activeChatChannelID?: Uint8Array | null = null;
  @observable public activeChatId: Long | null = null;
  @observable public chatIsOpen = false;
  @observable public openingChatID: Long | null = null;

  @observable public activeUsersChat: Chat | null = null;

  @observable private isChatOpeningByNavigationBar_ = false;

  @action public setIsChatOpeningByNavigationBar = (open: boolean) => {
    this.isChatOpeningByNavigationBar_ = open;
  };

  @action public setOpeningChatID = (openingChatID: Long | null) => {
    this.openingChatID = openingChatID;
  };

  @computed public get chats(): Chat[] {
    if (this.search.searchText) {
      return this.search.foundChats;
    }

    return this.workspace.chatsLoader.chats;
  }

  @computed public get chatsStoreKey(): string {
    return this.workspace.chatsLoader.storeKey;
  }

  public getChats = (storeKey: string): Chat[] => {
    return this.workspace.chatsLoader.getChats(storeKey);
  };

  @computed public get foundItems(): ChatsFoundItem[] {
    if (!this.search.enabled) {
      return [];
    }

    return composeSearchResult(
      this.search.foundChats,
      this.search.foundMessages,
      this.search.chatForSearch,
    );
  }

  @action public setActiveChat = (chat?: Chat | null, searchMsgID?: Long | null) => {
    if (this.activeUsersChat) {
      this.activeUsersChat.close();
    }

    // this.activeUsersChat = chat ? new Chat(chat.raw, chat.channel) : null;
    this.activeUsersChat = chat || null;
    this.activeChatChannelID = chat?.channelID;
    this.activeChatId = chat?.id || null;
    this.chatIsOpen = !!chat?.id;

    console.debug(`%c---->ChatsView.setActiveChat`, 'color: brown', chat?.id.toString(), this.activeUsersChat);

    const searchMsg: Message | null = this.activeUsersChat && searchMsgID ?
      new Message(
        {
          localID: searchMsgID,
          chatID: this.activeUsersChat.id,
        },
        this.activeUsersChat,
      ) : null;

    this.activateActiveChat(searchMsg);

    return this.activeUsersChat;
  };

  public activateActiveChat = async (searchMsg?: Message | null) => {
    const activeUsersChat = this.activeUsersChat;
    if (!activeUsersChat) {
      return;
    }

    await activeUsersChat.init();

    if (!this.activeUsersChat?.id?.equals(activeUsersChat.id)) {
      return;
    }

    const currentSelectedChannelID = this.workspace.channels.selectedChannel?.id;

    if (
      currentSelectedChannelID &&
      (this.workspace.chatsLoader.status !== getChatPreviewStatus(activeUsersChat) ||
        this.workspace.chatsLoader.assignedType !== getChatAssignedType(activeUsersChat))
    ) {
      await this.workspace.channels.selectChannel(
        activeUsersChat.channel,
        activeUsersChat.id,
        getChatPreviewStatus(activeUsersChat),
        getChatAssignedType(activeUsersChat),
      );
      if (!this.activeUsersChat?.id?.equals(activeUsersChat.id)) {
        return;
      }
    }

    if (this.workspace.app.layOutStore.rightSideBar.isOpen) {
      await this.workspace.app.layOutStore.rightSideBar.reopen(activeUsersChat);
      if (!this.activeUsersChat?.id?.equals(activeUsersChat.id)) {
        return;
      }
    }

    activeUsersChat.store?.setSearchMessage(searchMsg);
    activeUsersChat.store?.setFoundMessageId(searchMsg?.id);

    if (searchMsg) {
      activeUsersChat.store?.setSearchNavigation(true);

      if (activeUsersChat.store?.messages.some((m) => m.id.equals(searchMsg.id))) {
        activeUsersChat.store?.setTargetMessage(searchMsg);
      } else {
        await activeUsersChat.store?.loadChatHistoryAround(searchMsg.id);
      }
    } else {
      await activeUsersChat.store.loadInitialMessages();
    }

    activeUsersChat.store?.setMessageIdWithUnreadDelimiter(activeUsersChat.firstUnreadInboxMessageId);
  };

  @action public closeActiveChat = () => {
    console.debug(`%c---->chatsView.closeActiveChat`, 'color: red');
    this.workspace.app.layOutStore.rightSideBar.close();
    this.hideActiveChat();
    this.activeChatChannelID = null;
    this.activeChatId = null;
    this.activeUsersChat = null;
  };

  @action public showActiveChat = () => {
    if (this.activeChatId) {
      this.chatIsOpen = true;
    }
  };

  @action public hideActiveChat = () => {
    if (this.activeChatId) {
      this.chatIsOpen = false;
    }
  };

  protected onAnyChatUpdate_ = (rawChat: IRawChat, update: IUpdate) => {
    if (rawChat?.id && this.activeUsersChat?.id?.equals(rawChat.id)) {
      console.debug('%c----->ChatsView.onAnyChatUpdate_', 'color: #afb0b6', rawChat);
      const raw = update?.updateNewChatPreview ? initDefaultChatFields(this.activeUsersChat, rawChat) : rawChat;
      this.activeUsersChat?.update(raw);
    }
  };

  protected onChannelCounterReset_ = ({channelID}: {channelID?: Uint8Array | null}) => {
    if (channelID && equalUint8Arrays(this.activeUsersChat?.channelID, channelID)) {
      console.debug('%c----->ChatsView.onChannelCounterReset_', 'color: #afb0b6');
      this.activeUsersChat?.update({
        inReadPointer: this.activeUsersChat?.lastMessage?.localID || Long.ZERO,
      });
    }
  };

  @action public openChatWithUser = (user: Peer) => {
    if (user.chat) {
      this.setActiveChat(user.chat);
    } else {
      this.activeChatId = FAKE_CHAT_ID;
      this.chatIsOpen = true;
    }
  };

  public openChatByUserId = (channelId: Uint8Array, userId: Long) => {
    const user = this.workspace.findUser(userId, channelId);
    if (user) {
      this.openChatWithUser(user);
    }
  };

  public openUserChatByParams = (params: UserChatRouteParams) => {
    if (this.workspace.channels.isInit && params.channelId && params.userId) {
      console.debug(`%c---->openUserChatByParams`, 'color: brown', params);
      const channelId = channelIdFromParam(params.channelId);
      const userId = Long.fromString(params.userId);

      if (channelId) {
        this.openChatByUserId(channelId, userId);
      }
    } else {
      this.workspace.app.setInitialSelections(params);
    }
  };

  public openChatByParams = async (params: UserChatRouteParams, selectedChannelIDBase64?: string | null) => {
    const channelID = channelIdFromParam(params.channelId);

    if (this.workspace.channels.isInit && channelID && params.chatId) {
      console.debug(`%c---->openChatByParams`, 'color: brown', params, selectedChannelIDBase64);

      const chatID = Long.fromString(params.chatId);
      const channel = this.workspace.findChannel(channelID);

      this.setActiveChat(null);
      this.setOpeningChatID(chatID);


      let chat = this.findChat(chatID, channelID);
      const liteRawChat = chat?.raw || null;

      let fullRawChat = liteRawChat ? null : await this.workspace.chatsPool.get(chatID, channelID);

      const rawChat = liteRawChat || fullRawChat;
      if (!rawChat || !channel) {
        console.error(`%c---->openChatByParams rawChat or channel not found`, 'color: red', liteRawChat, channel);
        return;
      }

      chat = chat || new Chat(rawChat, channel);

      if (!this.openingChatID?.equals(chatID)) {
        return;
      }

      const messageId: Long | null = params.messageId ? Long.fromString(params.messageId) : null;

      const activeChat = this.setActiveChat(chat, messageId);

      this.workspace.app.layOutStore.setActiveTab(ChatTab.CHATS);

      if (this.openingChatID?.equals(chatID)) {
        this.setOpeningChatID(null);
      }

      if (!fullRawChat) {
        fullRawChat = await this.workspace.chatsPool.get(chatID, channelID);
        fullRawChat && activeChat?.update(fullRawChat);
      }

      this.selectProperChannel_(selectedChannelIDBase64, activeChat);
    } else if (this.workspace.channels.isInit && channelID) {
      console.debug(`%c---->openChatByParams`, 'color: brown', params);
      this.closeActiveChat();
      this.workspace.app.channels.setSelectedChannelById(channelID);
      this.workspace.app.layOutStore.setActiveTab(ChatTab.CHATS);
    } else {
      console.debug(`%c---->openChatByParams`, 'color: gray', params);
      this.workspace.app.setInitialSelections(params);
      this.workspace.app.layOutStore.setActiveTab(ChatTab.CHATS);
    }
  };

  protected selectProperChannel_ = async (selectedChannelIDBase64?: string | null, activeChat?: Chat | null) => {
    if (!activeChat?.id || this.openingChatID?.equals(activeChat.id)) {
      return;
    }

    console.debug(
      `%c----->ChatsView.selectProperChannel_ ${uint8ArrayToUuid(activeChat.channel?.id)} ${activeChat?.id?.toString()}`,
      'color: #afb0b6',
    );

    const currentSelectedChannelID = this.workspace.channels.selectedChannel?.id;
    if (!currentSelectedChannelID && !this.isChatOpeningByNavigationBar_) {
      return;
    }

    this.setIsChatOpeningByNavigationBar(false);

    if (
      this.workspace.chatsLoader.status !== getChatPreviewStatus(activeChat) ||
      this.workspace.chatsLoader.assignedType !== getChatAssignedType(activeChat)
    ) {
      await this.workspace.channels.selectChannel(
        activeChat.channel,
        activeChat.id,
        getChatPreviewStatus(activeChat),
        getChatAssignedType(activeChat),
      );
    } else if (!equalUint8Arrays(currentSelectedChannelID, base64ToUint8Array(selectedChannelIDBase64))) {
      await this.workspace.channels.selectChannel(
        activeChat.channel,
        activeChat.id,
      );
    }
  };

  public selectChannel = (channel: Channel | null, chatID?: Long | null) => {
    console.debug(
      `%c----->ChatsView.selectChannel ${uint8ArrayToUuid(channel?.id)} ${chatID?.toString()}`,
      'color: #afb0b6',
    );

    if (
      !this.search.chatForSearch?.channelID ||
      (channel?.id && !equalUint8Arrays(this.search.chatForSearch?.channelID, channel?.id))
    ) {
      this.search.reset();
    }

    this.workspace.channels.selectChannel(channel, chatID);
    this.workspace.app.layOutStore.setActiveTab(ChatTab.CHATS);
  };

  public reactivateChannel = () => {
    console.debug(`%c----->ChatsView.reactivatechannel`, 'color: #afb0b6');
    this.workspace.channels.reactivateChannel();
  };

  public findChat = (chatID?: Long | null, channelID?: Uint8Array | null): Chat | null => {
    if (this.search.searchText) {
      return this.search.findChat(chatID, channelID);
    }
    return this.workspace.chatsLoader.findChat(chatID, channelID);
  };

  public navigateToChat = (chat: Chat) => {
    if (this.workspace.app.layOutStore.rightSideBar.isOpen) {
      this.workspace.app.layOutStore.rightSideBar.chatInfoBarAnimationDisable();
    }

    this.workspace.app.layOutStore.rightSideBar.closePeerGallery();
    this.workspace.app.layOutStore.rightSideBar.closeChatGallery();

    navigateToChat(chat, this.workspace.channels.selectedChannel?.id);
  };

  public navigateToMessage = (message: Message) => {
    const pathToMessage = generatePathToMessage(message);
    const currentPah = window.location.pathname;

    if (currentPah !== pathToMessage) {
      navigateToMessage(message, message.chat.channelID);
      return;
    }

    this.activateActiveChat(message);
  };

  public enableSearchInActiveChat = (chat: Chat) => {
    this.resetFilter();
    this.search.enableSearchInActiveChat(chat);
  };

  public searchInActiveChannel = (searchText: string) => {
    this.resetFilter();
    this.search.searchInChannel(searchText, this.workspace.channels.selectedChannel?.id);

    if (!searchText) {
      this.activeUsersChat?.store?.setFoundMessageId(null);
    }
  };

  @action public reset = () => {
    this.closeActiveChat();
    this.activeUsersChat?.close();
    this.activeUsersChat = null;
    this.activeChatChannelID = null;
    this.activeChatId = null;
    this.chatIsOpen = false;
    this.tags.reset();
    this.filter.reset();
    this.search.reset();
    // this.snippets.reset();
  };

  @action public resetFilter = () => {
    this.workspace.chatsLoader.resetFilters();
  };
}

export default ChatsView;
