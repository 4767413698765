import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';

import {api, MCMethod, MCMethodGetChatsPreview, MCMethodGetPeers} from '../../api/proto';
import {equalUint8Arrays, uint8ArrayToUuid} from '../../utils/arrayUtils';
import isNil from '../../utils/isNil';
import APILayer from '../APILayer';
import Channel from '../Channel';
import channelIdFromParam from '../Channel/utils/channelIdFromParam';
import Chat from '../Chat';
import {ChatsAssignedType} from '../Chat/utils/getChatAssignedType';
import Peer from '../Peer';
import RawChatsStore from '../RawChatsStore';
import {mergeArraysByOffsetType, PAGE_SIZE, PagingOffsetType} from '../RawChatsStore/pagingUtils';
import {IRawChat} from '../RawChatsStore/RawChat';
import RawChatsStoreEvent from '../RawChatsStore/RawChatsStoreEvent';
import isRaisingChat from '../RawChatsStore/utils/isRaisingChat';
import WorkspaceStore from '../Workspaces/WorkspaceStore';
import getChatsDirectPeerKeys from './utils/getChatsDirectPeerKeys';


export default class ChatsLoader extends APILayer {
  @observable private isInit_ = false;

  @action private setInit_ = (isInit: boolean) => {
    this.isInit_ = isInit;
  };

  @action public reset = () => {
    this.isInit_ = false;
    this.clear();
  };

  constructor(protected workspace: WorkspaceStore) {
    super(workspace.app);
    makeObservable(this);

    workspace.chatsPool.on(RawChatsStoreEvent.UPDATE_TOTAL_UNREAD_COUNT, this.onUpdateTotalUnreadCount_);
    workspace.chatsPool.on(RawChatsStoreEvent.UPDATE_TOTAL, this.onUpdateTotal_);
  }


  /* unread section */
  @observable unreadByStatus: {[status: number]: number} = {};

  @action protected setUnreadByStatus_ = (chatStatus?: MCMethodGetChatsPreview.MCChatStatus, totalUnreadCount: number = 0) => {
    const status: number = chatStatus || 0;
    this.unreadByStatus = {...this.unreadByStatus, [status]: totalUnreadCount}
  };

  protected onUpdateTotalUnreadCount_ = (rawChatsStore: RawChatsStore, totalUnreadCount: number) => {
    if (!rawChatsStore.unreadOnly && this.rawChatsStore_?.equalsChannels(rawChatsStore.channelIDs)) {
      this.setUnreadByStatus_(rawChatsStore.status, totalUnreadCount);
    }
  };

  protected initUnreads_ = async () => {
    if (this.rawChatsStore_) {
      this.setUnreadByStatus_(this.rawChatsStore_.status, this.rawChatsStore_.totalUnreadCount);

      const openRawChatsStore = this.workspace.chatsPool.getChatsStore(
        this.rawChatsStore_.channelIDs,
        this.unreadOnly,
        false,
        false,
        MCMethodGetChatsPreview.MCChatStatus.S_OPEN,
        null,
        this.workspace.app.userStore.user?.userId,
      );
      await openRawChatsStore.init();

      this.setUnreadByStatus_(openRawChatsStore.status, openRawChatsStore.totalUnreadCount);
    }
  };
  /* end unread section */

  /* total section */
  @observable totalByStatus: {[status: number]: number} = {};

  @action protected setTotalByStatus_ = (chatStatus?: MCMethodGetChatsPreview.MCChatStatus, total: number = 0) => {
    const status: number = chatStatus || 0;
    this.totalByStatus = {...this.totalByStatus, [status]: total}
  };

  protected onUpdateTotal_ = (rawChatsStore: RawChatsStore, total: number) => {
    if (!rawChatsStore.unreadOnly && this.rawChatsStore_?.equalsChannels(rawChatsStore.channelIDs)) {
      this.setTotalByStatus_(rawChatsStore.status, total);
    }
  };

  protected initTotals_ = async () => {
    if (this.rawChatsStore_) {
      this.setTotalByStatus_(this.rawChatsStore_.status, this.rawChatsStore_.total);

      const openRawChatsStore = this.workspace.chatsPool.getChatsStore(
        this.rawChatsStore_.channelIDs,
        this.unreadOnly,
        false,
        false,
        MCMethodGetChatsPreview.MCChatStatus.S_OPEN,
        null,
        this.workspace.app.userStore.user?.userId,
      );
      await openRawChatsStore.init();

      this.setTotalByStatus_(openRawChatsStore.status, openRawChatsStore.total);
    }
  };
  /* end total section */

  /* filters section */

  @observable unreadOnly = false;
  @observable status: MCMethodGetChatsPreview.MCChatStatus = MCMethodGetChatsPreview.MCChatStatus.S_ALL;
  @observable assignedToMeOnly = false;
  @observable assignedToOtherOnly = false;

  @action public setUnreadOnly = (unreadOnly: boolean) => {
    this.unreadOnly = unreadOnly;
    if (this.rawChatsStore_?.channelIDs) {
      this.select(this.rawChatsStore_?.channelIDs, null, this.status);
    }
  };

  public toggleUnreadOnly = () => {
    this.setUnreadOnly(!this.unreadOnly);
  };

  @action protected setStatus = (status: MCMethodGetChatsPreview.MCChatStatus) => {
    this.status = status;

    if (status !== MCMethodGetChatsPreview.MCChatStatus.S_IN_PROGRESS) {
      this.assignedToMeOnly = false;
      this.assignedToOtherOnly = false;
    }
  };

  @observable assignedType: ChatsAssignedType = ChatsAssignedType.TO_ME;

  @action private setAssignedToByType_ = (type: ChatsAssignedType) => {
    this.assignedType = type;

    switch (type) {
      case ChatsAssignedType.TO_ME:
        this.assignedToMeOnly = true;
        this.assignedToOtherOnly = false;
        break;
      case ChatsAssignedType.TO_OTHER:
        this.assignedToOtherOnly = true;
        this.assignedToMeOnly = false;
        break;
    }
  };

  @action public setAssignedToMeOnly = () => {
    this.assignedToMeOnly = true;
    this.assignedToOtherOnly = false;

    if (this.rawChatsStore_?.channelIDs) {
      this.select(this.rawChatsStore_?.channelIDs, null, this.status);
    }
  };

  @action public setAssignedToOtherOnly = () => {
    this.assignedToOtherOnly = true;
    this.assignedToMeOnly = false;

    if (this.rawChatsStore_?.channelIDs) {
      this.select(this.rawChatsStore_?.channelIDs, null, this.status);
    }
  };

  @action public filterByStatus = (status: MCMethodGetChatsPreview.MCChatStatus) => {
    if (status !== MCMethodGetChatsPreview.MCChatStatus.S_IN_PROGRESS) {
      this.assignedToMeOnly = false;
      this.assignedToOtherOnly = false;
    } else {
      this.assignedToMeOnly = true;
    }

    if (this.rawChatsStore_?.channelIDs) {
      this.select(this.rawChatsStore_?.channelIDs, null, status);
    }
  };

  @action resetFilters = () => {
    this.assignedToMeOnly = false;
    this.assignedToOtherOnly = false;
    this.status = MCMethodGetChatsPreview.MCChatStatus.S_ALL;

    this.setUnreadOnly(false);
  };
  /* end filters section */

  /* active store section */

  @observable private rawChatsStore_: RawChatsStore | null = null;
  @observable chatsLoading = false;

  @computed public get storeKey() {
    return this.rawChatsStore_?.storeKey || '';
  }

  @action private setChatsLoading_ = (chatsLoading: boolean) => {
    this.chatsLoading = chatsLoading;
  };

  @action private setRawChatsStore_ = (rawChatsStore_: RawChatsStore) => {
    if (this.rawChatsStore_) {
      this.setPrevChats_(this.rawChatsStore_.storeKey, this.chats_);
    }

    this.clear();

    this.rawChatsStore_ = rawChatsStore_;

    this.rawChatsStore_.on(RawChatsStoreEvent.ADD_NEW_CHAT, this.onAddNewChat_);
    this.rawChatsStore_.on(RawChatsStoreEvent.UPDATE_CHAT, this.onUpdateChat_);
    this.rawChatsStore_.on(RawChatsStoreEvent.REMOVE_CHAT, this.onRemoveChat_);
    this.rawChatsStore_.on(RawChatsStoreEvent.CLEAR, this.onClear_);
  };

  @action public clear = () => {
    console.debug('%c----->chatsLoader.clear', 'color: blue');
    this.rawChatsStore_?.off(RawChatsStoreEvent.ADD_NEW_CHAT, this.onAddNewChat_);
    this.rawChatsStore_?.off(RawChatsStoreEvent.UPDATE_CHAT, this.onUpdateChat_);
    this.rawChatsStore_?.off(RawChatsStoreEvent.REMOVE_CHAT, this.onRemoveChat_);
    this.rawChatsStore_?.off(RawChatsStoreEvent.CLEAR, this.onClear_);
    this.rawChatsStore_ = null;
    this.setChats_([]);
  };

  protected detectStatus_ = (channelsIDs: Uint8Array[]): MCMethodGetChatsPreview.MCChatStatus => {
    if (channelsIDs.length === 1 && this.workspace.channels.findChannel(channelsIDs[0])?.isWebWidget) {
      return MCMethodGetChatsPreview.MCChatStatus.S_OPEN;
    }

    return MCMethodGetChatsPreview.MCChatStatus.S_ALL;
  };

  select = async (
    channelsIDs: Uint8Array[],
    chatID?: Long | null,
    status?: MCMethodGetChatsPreview.MCChatStatus | null,
    assignedType?: ChatsAssignedType,
  ) => {
    console.debug(
      `%c----->chatsLoader select ${channelsIDs
        .map((channelID) => uint8ArrayToUuid(channelID))
        .join('_')} chatID=${chatID?.toString()} status=${status?.toString()} assignedType=${assignedType?.toString()}`,
      'color: green',
    );

    if (assignedType) {
      this.setAssignedToByType_(assignedType);
    }

    const newStatus = isNil(status) ? this.detectStatus_(channelsIDs) : status;
    this.setStatus(newStatus);

    const nextRawChatsStore = this.workspace.chatsPool.getChatsStore(
      channelsIDs,
      this.unreadOnly,
      this.assignedToMeOnly,
      this.assignedToOtherOnly,
      newStatus,
      this.selectedChannel?.type,
      this.workspace.app.userStore.user?.userId,
    );

    if (this.rawChatsStore_?.storeKey === nextRawChatsStore.storeKey) {
      console.debug(
        `%c----->chatsLoader select canceled ${channelsIDs
          .map((channelID) => uint8ArrayToUuid(channelID))
          .join('_')} chatID=${chatID?.toString()}`,
        'color: orange',
      );
      return;
    }

    this.setRawChatsStore_(nextRawChatsStore);

    this.setChatsLoading_(true);
    const storeKey = nextRawChatsStore?.storeKey;
    const res = await nextRawChatsStore?.load(chatID);
    this.setChatsLoading_(false);

    this.processChatsPage_(res.result, res.offsetType, storeKey);
    /*
    this.initUnreads_();
    this.initTotals_();
    */
  };

  /* end active store section */

  /* chats section */

  @observable private chats_: Chat[] = [];
  private rawChatsMap_: Map<string, IRawChat> = new Map();
  @observable private prevChats_: {[storeKey: string]: Chat[]} = {};
  @observable private partialCollection_: boolean = false;

  @action private setPrevChats_ = (storeKey: string, chats: Chat[]) => {
    this.prevChats_ = {...this.prevChats_, [storeKey]: chats};
  };

  @action private setChats_ = (chats: Chat[]) => {
    console.debug(`%c---->ChatsLoader.setChats_`, 'color: brown');
    this.chats_ = chats;

    this.rawChatsMap_.clear();
    chats.forEach((chat) => this.rawChatsMap_.set(chat.id.toString(), {...chat.raw}));
  };

  @action private setPartialCollection_ = (partialCollection: boolean) => {
    this.partialCollection_ = partialCollection;
  };

  public getChats = (storeKey: string): Chat[] => {
    // console.debug(`%c---->ChatsLoader.getChats ${storeKey} prev=${this.storeKey !== storeKey}`, 'color: brown');
    return (this.storeKey === storeKey ? this.chats : this.prevChats_[storeKey]) || [];
  };

  @computed get chats() {
    return this.chats_;
  }

  @computed get chatsIds(): Long[] {
    return this.chats.map((c) => c.id);
  }

  @computed get lastMessageSentAt(): Long | null {
    return this.chats[this.chats.length - 1]?.lastMessageSentAt || null;
  }

  @computed get firsChatID(): Long | null {
    return this.chatsIds[0] || null;
  }

  @computed get lastChatID(): Long | null {
    return this.chatsIds[this.chatsIds.length - 1] || null;
  }

  @computed get selectedChannel(): Channel | null {
    return this.app.channels.workspace.channels?.selectedChannel || null;
  }

  public loadPrevPage = async () => {
    const storeKey = this.rawChatsStore_?.storeKey;
    const res = await this.rawChatsStore_?.load(this.firsChatID, false);
    if (res) {
      this.processChatsPage_(res.result, res.offsetType, storeKey);
    }
  };

  public loadNextPage = async () => {
    const storeKey = this.rawChatsStore_?.storeKey;
    const res = await this.rawChatsStore_?.load(this.lastChatID, true);
    if (res) {
      this.processChatsPage_(res.result, res.offsetType, storeKey);
    }
  };

  private createChatList_ = async (rawChats: IRawChat[], currentsChats?: Chat[]): Promise<Chat[]> => {
    const _chats: Chat[] = [];
    rawChats.forEach((newChat) => {
      const channel = this.workspace.findChannel(newChat.channelID);

      const _currentsChats: Chat[] =
        currentsChats || (this.app.chatsView.activeUsersChat ? [this.app.chatsView.activeUsersChat] : []);

      const oldUsersChat = _currentsChats.find((oldCh) => newChat.id?.equals(oldCh.id));
      if (oldUsersChat) {
        oldUsersChat.update(newChat);
        _chats.push(oldUsersChat);
      } else if (channel) {
        const newUsersChat = new Chat(newChat, channel);
        _chats.push(newUsersChat);
      }
    });

    return await Promise.resolve(_chats);
  };

  private processChatsPage_ = async (rawChats: IRawChat[], offsetType?: PagingOffsetType, storeKey?: string) => {
    if (this.rawChatsStore_?.storeKey !== storeKey) {
      console.debug(`%c---->ChatsLoader.processChatsPage_ canceled ${storeKey}`, 'color: red');
      return;
    }

    // console.debug(`%c---->ChatsLoader.processChatsPage_ ${storeKey}`, 'color: brown');
    const newChats = await this.createChatList_(rawChats);

    this.loadChatsDirectPeers_(newChats);

    if (offsetType === PagingOffsetType.FIRST_PAGE || offsetType === PagingOffsetType.MIDDLE_PAGE) {
      this.setChats_(newChats);
    } else {
      this.setChats_(mergeArraysByOffsetType<Chat>(this.chats_, newChats, offsetType));
    }

    // this.chatsSortingHash_ = this.getChatsSortingHash_(this.chats_);

    if (offsetType === PagingOffsetType.MIDDLE_PAGE && rawChats.length >= PAGE_SIZE) {
      this.setPartialCollection_(true);
      console.debug(`%c----->chatsLoader partial mode enabled!`, 'color: red');
    } else if (offsetType === PagingOffsetType.NEXT_PAGE && rawChats.length < PAGE_SIZE) {
      this.setPartialCollection_(false);
      console.debug(`%c----->chatsLoader partial mode disabled!`, 'color: red');
    }

    // this.app.chatsView.search.enableSearchInActiveChannel();

    if (!this.isInit_) {
      this.setInit_(true);
      this.applyInitiallySelectedChat();
    }
  };

  private loadChatsDirectPeers_ = async (chats: Chat[]) => {
    const [keys, peerIdByChatIdMap] = getChatsDirectPeerKeys(chats);

    if (!keys.length) {
      return;
    }

    const {res} = await this.app.activeWorkspace.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.app.activeWorkspace.id,
          action: new MCMethod({
            getPeers: new MCMethodGetPeers({
              keys: keys,
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    res?.methodResponse?.getPeers?.peers?.forEach((p) => {
      if (p.peer?.id) {
        const peersChatStringId = peerIdByChatIdMap.get(p.peer.id.toString());
        const peersChatId = peersChatStringId ? Long.fromString(peersChatStringId) : null;
        const chat = chats.find((c) => peersChatId && c.id.equals(peersChatId));
        chat?.store.setDirectPeer(new Peer(p.peer, chat));
      }
    });
  };

  /* end chats section */

  /* chat event handlers */

  @action private onAddNewChat_ = (rawChatsStore: RawChatsStore, newRawChat: IRawChat) => {
    if (this.rawChatsStore_?.storeKey !== rawChatsStore.storeKey) {
      console.debug(`%c----->chatsLoader->addNewChat_ not current storeKey!`, 'color: red', newRawChat);
    }
    if (this.partialCollection_) {
      console.debug(`%c----->chatsLoader->addNewChat_ partialCollection mode!`, 'color: red', newRawChat);
      return;
    }

    const channel = this.workspace.findChannel(newRawChat.channelID);
    if (!channel) {
      return;
    }

    const newChat = new Chat(newRawChat, channel);
    console.debug('%c----->chatsLoader->onAddNewChat-->create', 'color: blue;', newChat);

    this.setChats_([newChat].concat(this.chats_));
  };

  @action private onUpdateChat_ = (rawChatsStore: RawChatsStore, rawChat: IRawChat) => {
    if (this.rawChatsStore_?.storeKey !== rawChatsStore.storeKey) {
      console.debug(`%c----->chatsLoader->onUpdateChat not current storeKey!`, 'color: red', rawChat);
    }

    const chat = this.findChat(rawChat.id, rawChat.channelID);
    const currentRawChat = rawChat.id ? this.rawChatsMap_.get(rawChat.id.toString()) : null;

    const raising = currentRawChat ? isRaisingChat(currentRawChat, rawChat) : false;

    console.debug(
      `%c----->chatsLoader.onUpdateChat partialCollection=${this.partialCollection_} raising=${raising}`,
      'color: blue',
      rawChat,
      chat,
      currentRawChat,
    );

    if (!chat) {
      return;
    }

    if (raising && this.partialCollection_) {
      this.removeChat_(chat);
    } else if (raising && !this.partialCollection_) {
      chat.update(rawChat);
      this.raiseChat_(chat);
    } else {
      chat.update(rawChat);
    }
  };

  private onRemoveChat_ = (rawChatsStore: RawChatsStore, rawChat: IRawChat) => {
    if (this.rawChatsStore_?.storeKey !== rawChatsStore.storeKey) {
      console.debug(`%c----->chatsLoader->onRemoveChat not current storeKey!`, 'color: red', rawChat);
    }

    const chat = this.findChat(rawChat.id, rawChat.channelID);

    if (!chat) {
      return;
    }

    this.removeChat_(chat);
  };

  private onClear_ = () => {
    this.setChats_([]);
  };

  @action private removeChat_ = (chat: Chat) => {
    console.debug(`%c----->chatsLoader remove chat ${chat.id.toString()}`, 'color: red');
    const idx = this.findIndex(chat.id, chat.channelID);
    if (idx >= 0) {
      this.chats_.splice(idx, 1);
      console.debug('%c----->chatsLoader.removeChat_ set chats', 'color: blue');
      this.setChats_(this.chats_.slice());
    }
  };

  @action private raiseChat_ = (chat: Chat) => {
    console.debug(`%c----->chatsLoader raise chat ${chat.id.toString()}`, 'color: red');
    const idx = this.findIndex(chat.id, chat.channelID);
    if (idx >= 0) {
      this.chats_.splice(idx, 1);
      this.chats_.unshift(chat);
      if (idx > 0) {
        console.debug('%c----->chatsLoader.raiseChat_ set chats', 'color: blue');
        this.setChats_(this.chats_.slice());
      }
    }
  };

  /* end chat event handlers */

  public findChat = (chatID?: Long | null, channelID?: Uint8Array | null): Chat | null => {
    if (!chatID) {
      return null;
    }
    if (channelID) {
      return this.chats_.find((chat) => chatID?.equals(chat.id) && equalUint8Arrays(channelID, chat.channelID)) || null;
    }
    return this.chats_.find((chat) => chatID?.equals(chat.id)) || null;
  };

  public findIndex = (chatID?: Long | null, channelID?: Uint8Array | null): number => {
    if (!chatID) {
      return -1;
    }
    if (channelID) {
      return this.chats_.findIndex((chat) => chatID?.equals(chat.id) && equalUint8Arrays(channelID, chat.channelID));
    }
    return this.chats_.findIndex((chat) => chatID?.equals(chat.id));
  };

  public getInitiallySelectedChat = async (
    chatID?: Long | null,
    channelID?: Uint8Array | null,
  ): Promise<Chat | null> => {
    /*
    console.debug(
      `%c----->ChatsLoader.getInitiallySelectedChat`,
      'color: green;',
      chatID?.toString(),
      channelID?.toString(),
    );
    */

    let chat: Chat | null = null;
    // need to get extend chat data for display in chat info sidebar
    const rawChat = (await this.rawChatsStore_?.get(chatID, channelID)) || null;
    const channel = this.workspace.channels.findChannel(rawChat?.channelID);

    if (rawChat && channel) {
      chat = this.findChat(rawChat.id, channel.id) || new Chat(rawChat, channel);
    }

    return chat;
  };

  public applyInitiallySelectedChat = async () => {
    const {initialSelections} = this.workspace.app;
    const chatID = initialSelections?.chatId ? Long.fromString(initialSelections?.chatId) : null;
    const channelID = channelIdFromParam(initialSelections?.channelId);
    const messageID = initialSelections?.messageId ? Long.fromString(initialSelections?.messageId) : null;
    const userId = initialSelections?.userId ? Long.fromString(initialSelections?.userId) : null;

    if (!chatID && !userId) {
      return;
    }

    const chat = await this.getInitiallySelectedChat(chatID, channelID);

    if (chat) {
      this.app.chatsView.setActiveChat(chat, messageID);
    }
  };
}
