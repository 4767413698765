import {MCMethodSendMessageResponse, MCMethodSendGroupedMessagesResponse} from '../../api/proto';
import {t} from '../../i18n';

export const getSendMessageError = (type?: MCMethodSendMessageResponse.Result | null): string => {
  switch (type) {
    case MCMethodSendMessageResponse.Result.R_INCORRECT_TEXT_ENTITIES:
      return t('send_message_error_incorrect_text_entities');
    case MCMethodSendMessageResponse.Result.R_INVALID_REPLY:
      return t('send_message_error_invalid_reply');
    case MCMethodSendMessageResponse.Result.R_ATTACHMENT_TOO_LARGE:
      return t('send_message_error_attachment_too_large');
    case MCMethodSendMessageResponse.Result.R_UNKNOWN_ERROR:
      return t('send_message_error_unknown_error');
    case MCMethodSendMessageResponse.Result.R_INTERNAL_SERVER_ERROR:
      return t('send_message_error_internal_server_error');
    case MCMethodSendMessageResponse.Result.R_24_HOUR_ALLOWED_WINDOW:
      return t('send_message_error_24_hour_allowed_window');
    case MCMethodSendMessageResponse.Result.R_SEND_MESSAGE_TO_BLOCKED_USER:
      return t('send_message_error_blocked_user');
    case MCMethodSendMessageResponse.Result.R_YOUR_USER_BLOCKED:
      return t('send_message_error_your_user_blocked');
  }

  if (type && MCMethodSendMessageResponse.Result[type]) {
    return MCMethodSendMessageResponse.Result[type]?.toLowerCase().replace('r_', '').replace(/_/g, ' ');
  }

  return '';
};

export const getSendGroupedMessagesError = (type?: MCMethodSendGroupedMessagesResponse.Result | null): string => {
  switch (type) {
    case MCMethodSendGroupedMessagesResponse.Result.R_INCORRECT_TEXT_ENTITIES:
      return t('send_message_error_incorrect_text_entities');
    case MCMethodSendGroupedMessagesResponse.Result.R_INVALID_REPLY:
      return t('send_message_error_invalid_reply');
    case MCMethodSendGroupedMessagesResponse.Result.R_ATTACHMENT_TOO_LARGE:
      return t('send_message_error_attachment_too_large');
    case MCMethodSendGroupedMessagesResponse.Result.R_UNKNOWN_ERROR:
      return t('send_message_error_unknown_error');
    case MCMethodSendGroupedMessagesResponse.Result.R_INTERNAL_SERVER_ERROR:
      return t('send_message_error_internal_server_error');
  }

  if (type && MCMethodSendGroupedMessagesResponse.Result[type]) {
    return MCMethodSendGroupedMessagesResponse.Result[type]?.toLowerCase().replace('r_', '').replace(/_/g, ' ');
  }

  return '';
};
