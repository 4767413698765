import Long from 'long';

export default function toNumber(n?: number | Long | null): number {
  if (n === null || n === undefined) {
    return 0;
  }

  if (typeof n === 'number') {
    return n;
  }

  return n.toNumber();
}
