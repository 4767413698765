import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';

import {CHAT_MESSAGES_PAGE_SIZE} from '../../constants';
import {
  IMCMessage,
  IMCMethodEditChat,
  IMCMethodEditChatResponse,
  MCAttachment,
  MCMessagePreview,
  MCMethodEditChat,
} from '../../api/proto';
import {navigateToMessage} from '../../routes/Paths';
import {equalUint8Arrays, swapElements} from '../../utils/arrayUtils';
import Channel from '../Channel';
import ChatsView from '../ChatsView';
import {ChatMembers} from '../ChatsView/ChatMembers';
import EditorStore from '../EditorStore';
import Message from '../Message';
import Peer from '../Peer';
import Peers from '../Peer/Peers';
import {PagingOffsetType} from '../RawChatsStore/pagingUtils';
import RawMessagesStore, {RawMessage} from '../RawMessagesStore';
import RawMessagesStoreEvent from '../RawMessagesStore/RawMessagesStoreEvent';
import {SelectingMessages} from '../SelectingMessages';
import {ITextSelection} from '../TextSelection';
import Chat from './Chat';
import ChatHistory from './ChatHistory';
import ChatMessageSuggestionStore from './ChatMessageSuggestionStore';
import DraftMessage from './DraftMessage';
import ReadMessagesQueue from './ReadMessagesQueue';
import {SendMessageData, SendMessagesQueue, SendMessagesQueueEvent} from './SendMessagesQueue';

export class ChatStore {
  @computed get api() {
    return this.channel.api;
  }

  selector?: SelectingMessages;
  editor?: EditorStore;
  fileCaptionEditor?: EditorStore;
  draftMessage?: DraftMessage;
  readMessagesQueue?: ReadMessagesQueue | null;
  protected sendMessagesQueue?: SendMessagesQueue | null;
  rawMessagesStore: RawMessagesStore | null = null;

  peers?: Peers | null;

  members?: ChatMembers | null;

  @observable editingMessage?: Message | null = null;

  @action setEditingMessage = (editingMessage: Message | null) => {
    this.editingMessage = editingMessage;

    this.editor?.setMessage(editingMessage);
    this.setPinnedMessagesMode(false);
  };

  imagesHistory?: ChatHistory;
  videosHistory?: ChatHistory;
  filesHistory?: ChatHistory;
  audiosHistory?: ChatHistory;
  voicesHistory?: ChatHistory;
  stickersHistory?: ChatHistory;
  gifsHistory?: ChatHistory;

  suggestionStore?: ChatMessageSuggestionStore;

  // TODO: fix temporary solution
  pinnedMessages: Message[] = [];

  isInit = false;

  @computed get active(): boolean {
    return (
      !!this.channel.app.chatsView.activeUsersChat && this.chat.id.equals(this.channel.app.chatsView.activeUsersChat.id)
    );
  }

  @computed get isWebWidget() {
    return this.channel.isWebWidget;
  }

  chat: Chat;

  instance: number;

  constructor(chat: Chat, public channel: Channel) {
    makeObservable(this);
    this.instance = new Date().getTime();
    this.chat = chat;
  }

  @computed get chatsView(): ChatsView {
    return this.channel.app.chatsView;
  }

  @action init = async () => {
    if (!this.isInit) {
      console.debug('%c----->ChatStore-->init', 'color: red;', this.chat.instanceId);
      if (!this.readMessagesQueue) {
        this.readMessagesQueue = new ReadMessagesQueue(this.chat);
      }

      this.editor = new EditorStore(this.channel.app.chatsView.editorProxy, this.chat);
      this.fileCaptionEditor = new EditorStore(this.channel.app.chatsView.fileCaptionEditorProxy, this.chat);
      this.draftMessage = new DraftMessage(this.chat);
      this.peers = new Peers(this.chat, this.channel.app);
      this.members = new ChatMembers(this.chat, this.channel.app);

      this.rawMessagesStore = this.chat.channel.workspace.messagesPool.getMessagesStore(
        this.chat.channelID,
        this.chat.id,
        this.chat.lastChatMessageId,
      );

      if (!this.sendMessagesQueue) {
        this.sendMessagesQueue = new SendMessagesQueue(this.rawMessagesStore, this);
      }

      this.videosHistory = new ChatHistory(MCAttachment.Type.VIDEO, this.channel.app, this.chat);
      this.imagesHistory = new ChatHistory(MCAttachment.Type.IMAGE, this.channel.app, this.chat);
      this.filesHistory = new ChatHistory(MCAttachment.Type.DOCUMENT, this.channel.app, this.chat);
      this.audiosHistory = new ChatHistory(MCAttachment.Type.AUDIO, this.channel.app, this.chat);
      this.voicesHistory = new ChatHistory(MCAttachment.Type.VOICE, this.channel.app, this.chat);
      this.stickersHistory = new ChatHistory(MCAttachment.Type.STICKER, this.channel.app, this.chat);
      this.gifsHistory = new ChatHistory(MCAttachment.Type.GIF, this.channel.app, this.chat);

      this.suggestionStore = new ChatMessageSuggestionStore(this.chat);

      this.rawMessagesStore.on(RawMessagesStoreEvent.UPDATE_MESSAGE, this.onUpdateMessage);
      this.rawMessagesStore.on(RawMessagesStoreEvent.ADD_NEW_MESSAGE, this.onNewMessage);
      this.rawMessagesStore.on(RawMessagesStoreEvent.REMOVE_MESSAGE, this.onRemoveMessage);
      this.sendMessagesQueue.on(SendMessagesQueueEvent.SEND_MESSAGE, this.onSendMessage_);

      this.isInit = true;
    }

    await this.refresh();

    this.restore();
  };

  public initChatHistory = async () => {
    console.debug('%c----->ChatStore.initChatHistory', 'color: #afb0b6', this.chat.instanceId);
    await this.refresh();

    this.videosHistory?.init();
    this.imagesHistory?.init();
    this.filesHistory?.init();
    this.audiosHistory?.init();
    this.voicesHistory?.init();
    this.stickersHistory?.init();
    this.gifsHistory?.init();
  };

  public reinitChatHistory = () => {
    console.debug('%c----->ChatStore.reinitChatHistory', 'color: #afb0b6', this.chat.instanceId);
    this.videosHistory?.reinit();
    this.imagesHistory?.reinit();
    this.filesHistory?.reinit();
    this.audiosHistory?.reinit();
    this.voicesHistory?.reinit();
    this.stickersHistory?.reinit();
    this.gifsHistory?.reinit();
  };

  public resetChatHistory = () => {
    this.videosHistory?.reset();
    this.imagesHistory?.reset();
    this.filesHistory?.reset();
    this.audiosHistory?.reset();
    this.voicesHistory?.reset();
    this.stickersHistory?.reset();
    this.gifsHistory?.reset();
  };

  getAttachmentsHistoryByType = (type?: MCAttachment.Type | null) => {
    switch (type) {
      case MCAttachment.Type.AUDIO:
        return this.audiosHistory;
      case MCAttachment.Type.VOICE:
        return this.voicesHistory;
      case MCAttachment.Type.DOCUMENT:
        return this.filesHistory;
      case MCAttachment.Type.IMAGE:
        return this.imagesHistory;
      case MCAttachment.Type.STICKER:
        return this.stickersHistory;
      case MCAttachment.Type.VIDEO:
        return this.videosHistory;
      case MCAttachment.Type.GIF:
        return this.gifsHistory;
    }

    return null;
  };

  @action close = () => {
    this.editor?.store();
    this.reset();
  };

  @observable messages: Message[] = [];
  @observable groupedMessages: (Message | Message[])[] = [];

  @computed get closestPeerId() {
    return this.messages.find((msg) => msg.peer?.id?.greaterThan(0))?.peer?.id;
  }

  @action clearMessages = () => {
    this.messages = [];
    this.groupedMessages = [];
    this.visibleMessages = observable.array<Message>([]);
  };

  public sendMessage = async (data: SendMessageData) => {
    await this.chatScrollToEnd();
    this.sendMessagesQueue?.sendMessage(data);
  };

  editChat = async (chatInfo: Omit<IMCMethodEditChat, 'chatID' | 'channelID'>) => {
    const {id: chatID, name, alternativeName, showAlternativeName} = this.chat;
    const {error, res} = await this.channel.channelsRequest<IMCMethodEditChatResponse>(
      {
        editChat: new MCMethodEditChat({
          channelID: this.channel.id,
          chatID,
          alternativeName,
          name,
          showAlternativeName,
          ...chatInfo,
        }),
      },
      'editChat',
    );

    return {error, res: res};
  };

  @action addMessage = (message: Message, unshift?: boolean) => {
    unshift ? this.messages.unshift(message) : this.messages.push(message);
    this.addNewMessageToGrouped(message, unshift);
    return message;
  };

  @action setMessages = (rawMessages: RawMessage[]) => {
    // this.loadedNextPage = false;
    this.setMessagesInitialized(true);

    this.messages = [];
    this.groupedMessages = [];

    rawMessages.forEach((m) => {
      this.addMessage(new Message(m, this.chat));
    });

    this.recheckPageRange();
  };

  @action addMessages = (rawMessages: RawMessage[]) => {
    // this.loadedNextPage = false;
    this.setMessagesInitialized(true);

    rawMessages.forEach((m) => {
      if (!this.messages.some((message) => m.localID?.equals(message.id))) {
        this.addMessage(new Message(m, this.chat));
      }
    });

    this.messages = this.messages.slice();
    this.groupedMessages = this.groupedMessages.slice();

    this.recheckPageRange();
  };

  @action unshiftMessages = (messages: RawMessage[]) => {
    // this.loadedNextPage = true;

    this.setMessagesInitialized(true);

    messages
      .slice()
      .reverse()
      .forEach((m) => {
        this.addMessage(new Message(m, this.chat), true);
      });

    this.messages = this.messages.slice();
    this.groupedMessages = this.groupedMessages.slice();

    this.recheckPageRange();
  };

  @computed get firstInGrouped(): Message | Message[] | null {
    return this.groupedMessages.length > 0 ? this.groupedMessages[0] : null;
  }

  @computed get lastInGrouped(): Message | Message[] | null {
    return this.groupedMessages.length > 0 ? this.groupedMessages[this.groupedMessages.length - 1] : null;
  }

  isSameAlbum = (message: Message, prevMessage: Message | Message[]) => {
    return Array.isArray(prevMessage) && prevMessage.some((m) => equalUint8Arrays(m.groupID, message.groupID));
  };

  @action addNewMessageToGrouped = (message: Message, unshift?: boolean) => {
    if (unshift && message.groupID) {
      if (Array.isArray(this.firstInGrouped) && this.isSameAlbum(message, this.firstInGrouped)) {
        this.firstInGrouped.unshift(message);
        this.firstInGrouped.sort((a, b) => a.id.toNumber() - b.id.toNumber());
      } else {
        unshift ? this.groupedMessages.unshift([message]) : this.groupedMessages.push([message]);
      }
    } else if (!unshift && message.groupID) {
      if (Array.isArray(this.lastInGrouped) && this.isSameAlbum(message, this.lastInGrouped)) {
        this.lastInGrouped.push(message);
        this.lastInGrouped.sort((a, b) => a.id.toNumber() - b.id.toNumber());
      } else {
        unshift ? this.groupedMessages.unshift([message]) : this.groupedMessages.push([message]);
      }
    } else {
      unshift ? this.groupedMessages.unshift(message) : this.groupedMessages.push(message);
    }
  };

  loadInitialMessages = async () => {
    if (this.chat.unreadCount > 0 && this.chat.firstUnreadInboxMessageId) {
      let firstUnreadInboxMessage = this.messages.find((m) => this.chat.firstUnreadInboxMessageId?.equals(m.id));

      if (firstUnreadInboxMessage) {
        this.setTargetMessage(firstUnreadInboxMessage);

        if (this.messages.length < CHAT_MESSAGES_PAGE_SIZE) {
          this.loadPrevChatHistoryPage();
        } else {
          this.setMessagesInitialized(true);
        }
      } else {
        this.setTargetMessage(
          firstUnreadInboxMessage || new Message({localID: this.chat.firstUnreadInboxMessageId}, this.chat),
        );
        this.setSearchNavigation(true);

        await this.loadChatHistoryAround(this.chat.firstUnreadInboxMessageId);

        firstUnreadInboxMessage = this.messages.find((m) => this.chat.firstUnreadInboxMessageId?.equals(m.id));

        if (firstUnreadInboxMessage) {
          this.setTargetMessage(firstUnreadInboxMessage);
        }
      }
    } else if (this.messages.length < CHAT_MESSAGES_PAGE_SIZE) {
      await this.loadPrevChatHistoryPage();

      this.anchorLastMessage();
    } else {
      this.setMessagesInitialized(true);

      this.chatScrollToEnd();
    }

    this.setScrolledToUnreadDelimiter(false);
  };

  private onSendMessage_ = () => {
    this.fileCaptionEditor?.setText(null);
    this.editor?.setText(null);

    this.chatScrollToEnd();

    this.setReplyMessage(null);
  };

  findMessage = (localID?: Long | null): Message | null => {
    return localID ? this.messages.find((msg) => msg.localID?.equals(localID)) || null : null;
  };

  findMessageByRandomID = (randomID?: Long | null): Message | null => {
    return randomID ? this.messages.find((msg) => msg.randomID?.equals(randomID)) || null : null;
  };

  @observable pinnedMessagesMode = false;

  @action setPinnedMessagesMode = (enable: boolean) => {
    this.pinnedMessagesMode = enable;
  };

  @action purge = () => {
    this.isInit = false;

    this.clearMessages();
    this.sendMessagesQueue?.clear();
  };

  navigateToMessage = (message: Message) => {
    this.setSearchMessage(null);
    this.setPinnedMessagesMode(false);
    this.selector?.reset();

    message.restartFoundMessageAnimation();

    if (this.chatsView.activeUsersChat?.id.notEquals(message.chat.id)) {
      navigateToMessage(message);
    }

    this.setSearchMessage(message);
    this.setSearchNavigation(true);

    const inRange = this.groupedMessages.some((gm) => {
      if (Array.isArray(gm)) {
        return gm.findIndex((m) => message.id?.equals(m.id)) >= 0;
      }
      return message.id?.equals(gm.id);
    });

    if (inRange) {
      this.setTargetMessage(message);
    } else {
      this.loadChatHistoryAround(message.id);
    }
  };

  navigateToMessageByID = async (id?: Long | null) => {
    if (!id) {
      return;
    }
    this.setSearchMessage(null);
    this.setPinnedMessagesMode(false);
    this.selector?.reset();

    let foundMessage = this.findMessage(id);

    if (foundMessage) {
      this.setTargetMessage(foundMessage);
    } else {
      await this.loadChatHistoryAround(id);
    }

    if (!foundMessage) {
      foundMessage = this.findMessage(id);
    }

    if (!foundMessage) {
      return;
    }

    foundMessage.restartFoundMessageAnimation();

    if (this.chatsView.activeUsersChat?.id.notEquals(foundMessage.chat.id)) {
      navigateToMessage(foundMessage);
    }

    this.setSearchMessage(foundMessage);
    this.setSearchNavigation(true);
  };

  navigateToDate = (day: Date) => {
    console.debug(`TODO: not implemented method "navigateToDate", args:`, day);
  };

  readMessages = (messages: (MCMessagePreview | Message)[]) => {
    console.debug('%c----->ChatStore-->readMessages', 'color: red;', this.chat.instanceId);
    this.readMessagesQueue?.readMessages(messages);
  };

  readAllMessages = () => {
    if (this.chat.lastChatMessagePreview && this.chat.unreadCount) {
      if (!this.readMessagesQueue) {
        this.readMessagesQueue = new ReadMessagesQueue(this.chat);
      }
      if (this.chat.lastChatMessagePreview) {
        this.readMessagesQueue?.readMessages([this.chat.lastChatMessagePreview], true);
      }
    }
  };

  deleteMessages = (messageID: Long, bothSides?: boolean | null) => {
    this.rawMessagesStore?.delete(messageID, bothSides);
  };

  @action onNewMessage = (rawMessage: IMCMessage, randomID?: Long | null) => {
    console.debug(
      `%c----->ChatStore-->onNewMessage localID=${rawMessage.localID?.toString()} randomID=${randomID?.toString()} ${rawMessage.text
      }`,
      'color: green;',
    );
    const _newMessage = new Message(rawMessage, this.chat);

    if (!this.scrolled) {
      this.resetScrollOffset();
    }

    this.addMessage(_newMessage);

    if (!this.searchNavigation) {
      this.extendPageRange();
    }

    if (!this.scrolled && this.active) {
      this.setAnchorMessage(_newMessage);
    }

    if (randomID?.greaterThan(0)) {
      //scroll to end only for output messages. If randomID it means that output message
      this.chatScrollToEnd();
    }
  };

  @action onRemoveMessage = (rawMessage: IMCMessage, randomID?: Long | null) => {
    console.debug(
      `%c----->ChatStore-->onRemoveMessage localID=${rawMessage.localID?.toString()} randomID=${randomID?.toString()} ${rawMessage.text
      }`,
      'color: red;',
    );

    this.messages = this.messages.filter(
      (message) =>
        !(randomID
          ? message.randomID?.equals(randomID)
          : rawMessage.localID && message.localID?.equals(rawMessage.localID)),
    );

    this.groupedMessages = this.groupedMessages.filter((groupedMessage) => {
      if (!Array.isArray(groupedMessage)) {
        return !(randomID
          ? groupedMessage.randomID?.equals(randomID)
          : rawMessage.localID && groupedMessage.localID?.equals(rawMessage.localID));
      }

      if (groupedMessage.length === 1) {
        return !(randomID
          ? groupedMessage[0].randomID?.equals(randomID)
          : rawMessage.localID && groupedMessage[0].localID?.equals(rawMessage.localID));
      }

      const index = groupedMessage.findIndex((message) =>
        randomID
          ? message.randomID?.equals(randomID)
          : rawMessage.localID && message.localID?.equals(rawMessage.localID),
      );
      console.debug(`%c----->ChatStore-->onRemoveMessage foundIndex=${index}`, 'color: red;');
      if (index >= 0) {
        groupedMessage.splice(index, 1);
      }

      return true;
    });
  };

  @action private swapMessages_ = (index1: number, index2: number) => {
    console.debug(
      `%c----->ChatStore->swapMessages_ `,
      'color: red',
      ' ',
      index1,
      ' and ',
      index2,
      this.messages[index1],
      this.messages[index2],
    );
    if (index1 < 0 || index2 < 0) {
      return;
    }

    this.messages = swapElements<Message>(this.messages, index1, index2);
    this.messages[index1].swapped = true;
    this.messages[index2].swapped = true;
    console.debug(
      `%c----->ChatStore->swapMessages_ `,
      'color: red',
      ' ',
      this.messages[index1].text,
      ' and ',
      this.messages[index2].text,
      this.messages[index1],
      this.messages[index2],
    );
  };

  private checkSwapMessages_ = (message: Message | null, rawMessage: IMCMessage, randomID?: Long | null) => {
    console.debug(
      `%c----->ChatStore->checkSwapMessages_ `,
      'color: blue',
      !randomID,
      !rawMessage.localID,
      !message,
      rawMessage.localID && message?.localID?.equals(rawMessage.localID),
      message?.swapped,
    );
    if (
      !randomID ||
      !rawMessage.localID ||
      !message ||
      message?.localID?.equals(rawMessage.localID) ||
      message?.swapped
    ) {
      return;
    }

    const idx = this.messages.findIndex((m) => m.randomID?.equals(randomID));
    const newIndex = this.messages.findIndex((m) => rawMessage.localID && m.localID?.equals(rawMessage.localID));
    this.swapMessages_(idx, newIndex);
  };

  onUpdateMessage = (rawMessage: IMCMessage, randomID?: Long | null) => {
    let message: Message | null | undefined;

    if (randomID) {
      message = this.findMessageByRandomID(randomID);
      console.debug(
        `%c----->ChatStore-->onUpdateMessage found by randomID=${randomID?.toString()} localID=${message?.localID?.toString()} to localID=${rawMessage.localID?.toString()} new_text=${rawMessage.text
        } old_text=${message?.text}`,
        'color: green;',
        rawMessage,
      );
    }

    if (!message) {
      message = this.findMessage(rawMessage.localID);
    }

    console.debug(
      `%c----->ChatStore-->onUpdateMessage localID=${message?.localID?.toString()} to localID=${rawMessage.localID?.toString()} randomID=${randomID?.toString()} new_text=${rawMessage.text
      } old_text=${message?.text}`,
      'color: orange;',
    );

    if (rawMessage.localID && message?.localID?.notEquals(rawMessage.localID)) {
      console.debug(`%c----->ChatStore-->onUpdateMessage not equls randomID=${randomID?.toString()}`, 'color: red;');
    }

    this.checkSwapMessages_(message, rawMessage, randomID);
    message?.update(rawMessage);
  };

  @observable anchorMessage?: Message | null = null;
  @observable targetMessage?: Message | null = null;
  @observable searchMessage?: Message | null = null;
  @observable foundMessageId?: Long | null = null;
  @observable searchNavigation = false;
  @observable messagesInitialized = false;

  @observable messageIdWithUnreadDelimiter: Long | null = null;

  @action setMessageIdWithUnreadDelimiter = (messageId: Long | null) => {
    this.messageIdWithUnreadDelimiter = messageId;
  };

  @action setMessagesInitialized = (messagesInitialized: boolean) => {
    this.messagesInitialized = messagesInitialized;
  };

  @action setSearchNavigation = (searchNavigation: boolean) => {
    this.searchNavigation = searchNavigation;
  };

  @action setAnchorMessage = (anchorMessage?: Message | null) => {
    if (this.anchorMessage?.localID && anchorMessage?.localID?.equals(this.anchorMessage?.localID)) {
      return;
    }
    this.anchorMessage = anchorMessage;

    if (anchorMessage) {
      this.targetMessage = null;
      this.searchMessage = null;
    }
  };

  anchorTopMessage = () => {
    const topMessage = Array.isArray(this.groupedMessages[this.topMessageIndex])
      ? this.groupedMessages[this.topMessageIndex][0]
      : this.groupedMessages[this.topMessageIndex];

    this.setAnchorMessage(topMessage);
  };

  anchorLastMessage = () => {
    const lastMessage = Array.isArray(this.groupedMessages[this.groupedMessages.length - 1])
      ? this.groupedMessages[this.groupedMessages.length - 1][0]
      : this.groupedMessages[this.groupedMessages.length - 1];

    this.setAnchorMessage(lastMessage);
  };

  @action setTargetMessage = (targetMessage?: Message | null) => {
    this.targetMessage = targetMessage;
    if (targetMessage) {
      this.anchorMessage = null;
      this.searchMessage = null;
    }
  };

  @action setSearchMessage = (searchMessage?: Message | null) => {
    if (searchMessage) {
      this.anchorMessage = null;
      this.targetMessage = null;
    }
    this.searchMessage = searchMessage;
  };

  @action setFoundMessageId = (messageId?: Long | null) => {
    this.foundMessageId = messageId;
  };

  @computed get firstUnreadMessageId(): Long | null {
    return this.messages.find((m) => this.chat.firstUnreadInboxMessageId?.equals(m.id))?.id || null;
  }

  finishSearchNavigation = async () => {
    this.setSearchNavigation(false);
    this.setSearchMessage(null);
    this.clearMessages();
    this.resetScrollOffset();

    await this.loadLastMessages();
  };

  @observable scrolling: 'backwards' | 'forwards' | null = null;
  @observable scrolled = false;
  @observable scrollBottom = 0;
  @observable scrollTop = 0;
  @observable scrollStopMessage: Message | Message[] | null = null;

  @observable scrollDirection: 'backwards' | 'forwards' = 'forwards';
  @observable topMessageIndex = 0;
  @observable bottomMessageIndex = 0;

  @observable scrolledToUnreadDelimiter: boolean = false;

  @action setScrolling = (scrollBottom: number | null) => {
    if (!scrollBottom || !this.scrollBottom) {
      this.scrolling = null;
    } else {
      this.scrolling = this.scrollBottom >= scrollBottom ? 'forwards' : 'backwards';
    }
  };

  @action setScrolled = (scrolled: boolean) => {
    this.scrolled = scrolled;
  };

  @action setScrollBottom = (scrollBottom: number) => {
    this.setScrollDirection(this.scrollBottom >= scrollBottom ? 'forwards' : 'backwards');
    this.scrollBottom = scrollBottom;
  };

  @action setScrollTop = (scrollTop: number) => {
    this.scrollTop = scrollTop;
  };

  @action setTopMessageIndex = (topMessageIndex: number) => {
    if (this.topMessageIndex !== topMessageIndex) {
      this.topMessageIndex = topMessageIndex;
    }
  };

  @action setBottomMessageIndex = (bottomMessageIndex: number) => {
    if (this.bottomMessageIndex !== bottomMessageIndex) {
      this.bottomMessageIndex = bottomMessageIndex;
    }
  };

  @action setPageRange = (bottomMessageIndex: number, topMessageIndex: number) => {
    if (this.bottomMessageIndex !== bottomMessageIndex) {
      this.bottomMessageIndex = bottomMessageIndex;
    }
    if (this.topMessageIndex !== topMessageIndex) {
      this.topMessageIndex = topMessageIndex;
    }
  };

  @action setScrolledToUnreadDelimiter = (scrolled: boolean) => {
    this.scrolledToUnreadDelimiter = scrolled;
  };

  @action recheckPageRange = () => {
    if (this.topMessageIndex < CHAT_MESSAGES_PAGE_SIZE || this.topMessageIndex >= this.groupedMessages.length) {
      this.setPageRange(0, this.groupedMessages.length - 1);
    }

    if (this.bottomMessageIndex > this.groupedMessages.length) {
      this.setBottomMessageIndex(0);
    }
  };

  @action extendPageRange = () => {
    if (this.scrollDirection === 'forwards') {
      this.topMessageIndex = this.groupedMessages.length - 1;
    }
  };

  @action setNextPageRange = () => {
    if (this.scrollDirection === 'backwards') {
      const bottomMessageIndex = Math.max(this.bottomMessageIndex - CHAT_MESSAGES_PAGE_SIZE, 0);
      const topMessageIndex = this.groupedMessages.length;

      this.setPageRange(bottomMessageIndex, topMessageIndex);
    } else if (this.scrollDirection === 'forwards') {
      const topMessageIndex = Math.min(this.topMessageIndex + CHAT_MESSAGES_PAGE_SIZE, this.groupedMessages.length - 1);
      const bottomMessageIndex = 0;

      this.setPageRange(bottomMessageIndex, topMessageIndex);
    }
  };

  @action setScrollDirection = (scrollDirection: 'backwards' | 'forwards') => {
    this.scrollDirection = scrollDirection;
  };

  @action resetScrollOffset = () => {
    this.scrolled = false;
    this.scrollTop = 0;
    this.scrollBottom = 0;
    this.scrollStopMessage = null;
    this.setScrollDirection('forwards');
  };

  reset = () => {
    this.editor?.reset();
    this.fileCaptionEditor?.reset();

    this.setMessageIdWithUnreadDelimiter(null);

    this.peers?.reset();

    this.resetChatHistory();
  };

  restore = () => {
    this.editor?.restore();
  };

  resetAnchor = () => {
    this.setAnchorMessage(null);
  };

  resetTarget = () => {
    this.setTargetMessage(null);
  };

  resetSearchTarget = () => {
    this.setSearchMessage(null);
  };

  @observable _loading = false;
  @action setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  @computed get historyLoading(): boolean {
    return this._loading;
  }

  @observable loadingHistoryAround = false;
  @observable loadingNextPage = false;

  @action resetLoadingFlags = () => {
    this.loadingHistoryAround = false;
    this.loadingNextPage = false;
  };

  loadPrevChatHistoryPage = async () => {
    if (this._loading || !this.rawMessagesStore) {
      console.debug(
        `%c----->ChatStore-->loadPrevChatHistoryPage break _loading=${this._loading} rawMessagesStore=${!!this
          .rawMessagesStore}`,
        'color: red;',
      );
      return;
    }

    this.resetLoadingFlags();
    this.setSearchMessage(null);

    let fromMessageID = Long.fromNumber(CHAT_MESSAGES_PAGE_SIZE);

    if (this.messages[0]?.id) {
      fromMessageID = Long.fromNumber(this.messages[0].id.toNumber() - 1);
    } else if (this.chat.lastChatMessageId?.greaterThan(0)) {
      fromMessageID = this.chat.lastChatMessageId;
    }

    if (fromMessageID.equals(0)) {
      console.debug('%c----->ChatStore-->loadPrevChatHistoryPage is imposible', 'color: orange;');
      return;
    }

    this.setLoading(true);
    const {rawMessages} = await this.rawMessagesStore.load(PagingOffsetType.PREV_PAGE, fromMessageID);
    this.setLoading(false);

    this.unshiftMessages(rawMessages);
  };

  loadNextChatHistoryPage = async () => {
    if (this._loading || !this.rawMessagesStore) {
      console.debug(
        `%c----->ChatStore-->loadNextChatHistoryPage break _loading=${this._loading} rawMessagesStore=${!!this
          .rawMessagesStore}`,
        'color: red;',
      );
      return;
    }

    this.resetLoadingFlags();
    this.loadingNextPage = true;
    this.setSearchMessage(null);

    this.setLoading(true);
    const fromMessageID = this.messages.length ? this.messages[this.messages.length - 1].id : undefined;
    const {rawMessages} = await this.rawMessagesStore.load(PagingOffsetType.NEXT_PAGE, fromMessageID);
    this.setLoading(false);

    this.addMessages(rawMessages);
  };

  @action loadChatHistoryAround = async (searchMsgId: Long) => {
    if (this._loading || !this.rawMessagesStore) {
      console.debug(
        `%c----->ChatStore-->loadChatHistoryAround break _loading=${this._loading} rawMessagesStore=${!!this
          .rawMessagesStore}`,
        'color: red;',
      );
      return;
    }

    this.resetLoadingFlags();

    this.clearMessages();

    this.setLoading(true);
    const {rawMessages} = await this.rawMessagesStore.load(PagingOffsetType.MIDDLE_PAGE, searchMsgId);
    this.setLoading(false);

    this.setMessages(rawMessages);
  };

  loadLastMessages = async () => {
    if (this._loading || !this.rawMessagesStore) {
      return;
    }

    this.resetLoadingFlags();
    this.setSearchMessage(null);

    this.setLoading(true);
    const {rawMessages} = await this.rawMessagesStore.load(PagingOffsetType.FIRST_PAGE);
    this.setLoading(false);

    this.setMessages(rawMessages);

    const anchorMessage = Array.isArray(this.groupedMessages[this.topMessageIndex])
      ? this.groupedMessages[this.topMessageIndex][0]
      : this.groupedMessages[this.topMessageIndex];
    this.setAnchorMessage(anchorMessage);
  };

  chatScrolledToTop = async () => {
    console.debug('%c----->ChatStore-->chatScrolledToTop', 'color: green;');
    this.setScrollDirection('backwards');
    const anchorMessage = Array.isArray(this.groupedMessages[this.bottomMessageIndex])
      ? this.groupedMessages[this.bottomMessageIndex][0]
      : this.groupedMessages[this.bottomMessageIndex];

    if (this.messagesInitialized) {
      await this.loadPrevChatHistoryPage();
    }

    this.setNextPageRange();
    this.setAnchorMessage(anchorMessage);
  };

  chatScrolledToBottom = async () => {
    console.debug('%c----->ChatStore-->chatScrolledToBottom', 'color: green;');
    this.setScrollDirection('forwards');
    this.setNextPageRange();

    const anchorMessage = Array.isArray(this.groupedMessages[this.topMessageIndex])
      ? this.groupedMessages[this.topMessageIndex][0]
      : this.groupedMessages[this.topMessageIndex];

    if (
      this.searchNavigation &&
      this.messages.length &&
      this.chat.lastChatMessageId?.eq(this.messages[this.messages.length - 1].id)
    ) {
      this.setSearchNavigation(false);
    } else if (this.searchNavigation && this.topMessageIndex < this.groupedMessages.length) {
      await this.loadNextChatHistoryPage();
    }

    this.setAnchorMessage(anchorMessage);
  };

  chatScrollToEnd = async () => {
    this.resetTarget();
    this.resetScrollOffset();
    this.setScrolledToUnreadDelimiter(true);

    if (this.searchNavigation) {
      await this.finishSearchNavigation();
    } else {
      const topMessageIndex = this.groupedMessages.length - 1;
      const bottomMessageIndex = Math.max(topMessageIndex - Math.ceil((3 * CHAT_MESSAGES_PAGE_SIZE) / 2), 0);
      this.setPageRange(bottomMessageIndex, topMessageIndex);

      this.anchorTopMessage();
    }
  };

  public getAttachment = async (attachmentID: Uint8Array, fileName?: string | null) => {
    return await this.channel.workspace.attachmentStore.get(attachmentID, this.channel.id, fileName);
  };

  @observable visibleMessages = observable.array<Message>([]);

  @action addVisibleMessage = (message: Message) => {
    if (!this.visibleMessages.some((m) => m.id.equals(message.id))) {
      this.visibleMessages.push(message);
    }
  };

  @action deleteVisibleMessage = (message: Message) => {
    const idx = this.visibleMessages.findIndex((m) => m.id.equals(message.id));
    if (idx >= 0) {
      this.visibleMessages.splice(idx, 1);
    }
  };

  @computed get firstVisibleMessage() {
    const args = this.visibleMessages.map((m) => m.id.toNumber());
    const minId = Math.min(...args);
    return this.visibleMessages.find((m) => m.id.equals(minId));
  }

  @computed get lastVisibleMessage() {
    const args = this.visibleMessages.map((m) => m.id.toNumber());
    const maxId = Math.max(...args);
    return this.visibleMessages.find((m) => m.id.equals(maxId));
  }

  @observable private _replyMessage: Message | null = null;

  @computed get replyMessage() {
    return this._replyMessage;
  }

  @observable textSelection?: ITextSelection | null = null;

  @action setReplyMessage = (replyMessage: Message | null, textSelection?: ITextSelection | null) => {
    this._replyMessage = replyMessage;
    this.textSelection = textSelection;
    this.selector?.reset();
    this.editor?.focus();

    this.setPinnedMessagesMode(false);
  };

  @observable directPeer: Peer | null = null;

  @action setDirectPeer = (directPeer?: Peer | null) => {
    this.directPeer = directPeer || null;
  };

  updateDirectPeer = async () => {
    if (!this.chat.directChatWithPeer) {
      return;
    }

    const directPeer = await this.peers?.getPeer(this.chat.directChatWithPeer);
    if (directPeer) {
      this.setDirectPeer(directPeer);
    }
    return directPeer;
  };

  toggleMute = () => {
    console.debug(`TODO: not implemented method "toggleMute"`);
  };

  public refresh = async () => {
    await this.channel.workspace.updater.refreshChat(this.chat.id, this.chat.channelID);

    this.updateDirectPeer();
  };
}

export default ChatStore;
