import Long from 'long';

import {api, APIResponse, entities, MCMethod, MCMethodGetChat} from '../../api/proto';
import EventEmitter, {IEventEmitter} from '../../api/EventEmitter';
import {base64ToUint8Array, uint8ArrayToBase64} from '../../utils/arrayUtils';
import wait from '../../utils/wait';
import {RawMessage} from '../RawMessagesStore';
import {WorkspaceStore} from '../Workspaces';
import {IRawChat} from '../RawChatsStore/RawChat';


const NEXT_UPDATE_DELAY_MS = 2000; //ms

export enum ChannelsUpdaterEvent {
  UPDATE = 'UPDATE',
  NEW_OUTGOING_MESSAGE = 'NEW_OUTGOING_MESSAGE',
  NEED_RELOAD = 'NEED_RELOAD',
  REFRESH_CHAT = 'REFRESH_CHAT',
}

export interface IChannelsUpdater extends IEventEmitter {
  start(versions?: entities.IChannelVersion[] | null): void;
  addChannels(versions?: entities.IChannelVersion[] | null): void;
  removeChannels(channelIds?: Uint8Array[] | null): void;
  reset(): void;
}

export class ChannelsUpdater extends EventEmitter implements IChannelsUpdater {
  constructor(private workspace: WorkspaceStore) {
    super();
  }

  private isInit = false;

  private versionsMap: Map<string, Long> = new Map();

  private reloadDelay: number = NEXT_UPDATE_DELAY_MS;

  private setReloadDelay = (ms?: Long | null) => {
    this.reloadDelay = ms?.toNumber() || NEXT_UPDATE_DELAY_MS;
  };

  private get versions() {
    const _versions: entities.IChannelVersion[] = [];

    this.versionsMap.forEach((version, key) => {
      _versions.push({
        channelId: base64ToUint8Array(key),
        version: version,
      });
    });

    return _versions;
  }

  private setInit = (isInit: boolean) => {
    this.isInit = isInit;
  };

  private setVersions_ = (versions?: entities.IChannelVersion[] | null) => {
    versions?.forEach(({channelId, version}) => {
      if (version?.greaterThan(0) && channelId) {
        this.versionsMap.set(uint8ArrayToBase64(channelId), version);
      }
    });
  };

  public addChannels = (versions?: entities.IChannelVersion[] | null) => {
    versions?.forEach(({channelId, version}) => {
      if (version && channelId && !this.versionsMap.has(uint8ArrayToBase64(channelId))) {
        this.versionsMap.set(uint8ArrayToBase64(channelId), version);
      }
    });
  };

  public removeChannels = (channelIds?: Uint8Array[] | null) => {
    channelIds?.forEach((channelId) => {
      this.versionsMap.delete(uint8ArrayToBase64(channelId));
    });
  };

  private loadUpdates = async (versions: entities.IChannelVersion[]) => {
    const {error, res, trx} = await this.workspace.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.workspace.id,
          updates: new api.ChannelsUpdatesRequest({
            versions,
          }),
        }),
      },
      'channelsResponse',
      {
        logLevel: 'disabled',
      }
    );

    this.processUpdates_(this.versions, res?.updates, trx);

    if (error?.type === APIResponse.Status.AS_NOT_ALLOWED) {
      this.emit(ChannelsUpdaterEvent.NEED_RELOAD);
    }

    return {error, res};
  };

  public start = (versions?: entities.IChannelVersion[] | null) => {
    this.setVersions_(versions);

    if (!this.isInit) {
      this.setInit(true);
      this.ping();
    }
  };

  private ping = async () => {
    if (!this.isInit) {
      return;
    }

    if (this.versions.length) {
      await this.loadUpdates(this.versions);
    }

    await wait(this.reloadDelay);

    await this.ping();
  };

  private processUpdates_ = (versions: entities.IChannelVersion[], updates?: api.IChannelsUpdatesResponse | null, trx?: string | null) => {
    this.setReloadDelay(updates?.nextRequestAfterMs);

    if (updates?.status === api.ChannelsUpdatesResponse.Status.CU_STATUS_TOO_MANY_UPDATES) {
      console.debug(`%c-->updates status - TOO MANY UPDATES: ${versions.map((v) => `channelId=${uint8ArrayToBase64(v.channelId)} - version=${v.version?.toString()}`)}`, 'font-weight: bold; color: red;', trx);
      this.emit(ChannelsUpdaterEvent.NEED_RELOAD);
      return;
    }

    if (updates?.updates?.length) {
      const newVersion = updates?.updates?.some(({version, update}) => {
        const channelId = uint8ArrayToBase64(update?.channelID);
        if (channelId && update && version) {
          const currentVersion = this.versionsMap.get(channelId);
          if (currentVersion && version.greaterThan(currentVersion)) {
            return true;
          }
        }
        return false;
      });

      if (newVersion) {
        console.debug(`%c-->updates requsted versions: ${versions.map((v) => `channelId=${uint8ArrayToBase64(v.channelId)} - version=${v.version?.toString()}`)}`, 'font-weight: bold', trx);
        console.debug(`%c-->updates received versions ${updates?.updates.map((v) => `channelId=${uint8ArrayToBase64(v.update?.channelID)} - version=${v.version?.toString()}`)}`, 'font-weight: bold', trx);
        console.debug(`%c-->updates ${newVersion ? 'have' : 'have not'} new versions`, 'font-weight: bold', updates?.updates);
      }
    }

    const newVersionsMap: Map<string, Long> = new Map();

    updates?.updates?.forEach(({version, update}) => {
      const channelId = uint8ArrayToBase64(update?.channelID);

      if (channelId && update && version) {
        const currentVersion = this.versionsMap.get(channelId);

        if (currentVersion && version.greaterThan(currentVersion)) {
          newVersionsMap.set(channelId, version);

          this.emit(ChannelsUpdaterEvent.UPDATE, update);
        }
      }
    });

    newVersionsMap.forEach((value, key) => {
      this.versionsMap.set(key, value);
    });
  };

  public reset = () => {
    this.setInit(false);
    this.versionsMap.clear();

    this.reloadDelay = NEXT_UPDATE_DELAY_MS;
  };

  public processOutgoingMessage = (newRawMessage: RawMessage, channelID: Uint8Array) => {
    this.emit(ChannelsUpdaterEvent.NEW_OUTGOING_MESSAGE, newRawMessage, channelID);
  };


  public refreshChat = async (chatID?: Long | null, channelID?: Uint8Array | null): Promise<IRawChat | null> => {
    if (!chatID || !channelID) {
      return null;
    }

    const rawChat = await this.getChat_(chatID, channelID);
    if (!rawChat) {
      return null;
    }

    this.emit(ChannelsUpdaterEvent.REFRESH_CHAT, rawChat);

    return rawChat;
  };

  private getChat_ = async (chatID?: Long | null, channelID?: Uint8Array | null): Promise<IRawChat | null> => {
    const {res} = await this.workspace.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.workspace.id,
          action: new MCMethod({
            getChat: new MCMethodGetChat({
              chatID,
              channelID,
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    const chat = res?.methodResponse?.getChat?.chat;
    return chat ? {
      ...chat, 
      statusSetBy: chat.statusSetBy || Long.fromNumber(-1), //TODO need to test and refactor
      isExtended: true} : null;
  };
}

export default ChannelsUpdater;
