import {
  INSTAGRAM_SUPPORTED_AUDIO_CONTENT_TYPES,
  INSTAGRAM_SUPPORTED_IMAGE_CONTENT_TYPES,
  INSTAGRAM_SUPPORTED_VIDEO_CONTENT_TYPES,
  SUPPORTED_AUDIO_CONTENT_TYPES,
  SUPPORTED_IMAGE_CONTENT_TYPES,
  SUPPORTED_VIDEO_CONTENT_TYPES,
} from '../../../config';
import {
  TELEGRAM_BOT_FIXED_CONTENT_TYPES,
  TELEGRAM_BOT_SUPPORTED_AUDIO_CONTENT_TYPES,
  TELEGRAM_BOT_SUPPORTED_IMAGE_CONTENT_TYPES,
  TELEGRAM_BOT_SUPPORTED_VIDEO_CONTENT_TYPES,
} from '../../../config/telegramBot';
import Chat from '../Chat';

interface IUploadLimits {
  contentTypes: {
    audio: Set<string>;
    image: Set<string>;
    video: Set<string>;
  };
  fixedContentTypes?: Set<string>;
}

export default function getChatUploadLimits(chat?: Chat | null): IUploadLimits {
  if (chat?.channel.isInstagram) {
    return {
      contentTypes: {
        audio: INSTAGRAM_SUPPORTED_AUDIO_CONTENT_TYPES,
        image: INSTAGRAM_SUPPORTED_IMAGE_CONTENT_TYPES,
        video: INSTAGRAM_SUPPORTED_VIDEO_CONTENT_TYPES,
      },
    };
  }

  if (chat?.channel.isTelegramBot) {
    return {
      contentTypes: {
        audio: TELEGRAM_BOT_SUPPORTED_AUDIO_CONTENT_TYPES,
        image: TELEGRAM_BOT_SUPPORTED_IMAGE_CONTENT_TYPES,
        video: TELEGRAM_BOT_SUPPORTED_VIDEO_CONTENT_TYPES,
      },
      fixedContentTypes: TELEGRAM_BOT_FIXED_CONTENT_TYPES,
    };
  }

  return {
    contentTypes: {
      audio: SUPPORTED_AUDIO_CONTENT_TYPES,
      image: SUPPORTED_IMAGE_CONTENT_TYPES,
      video: SUPPORTED_VIDEO_CONTENT_TYPES,
    },
  };
}
