import React from 'react';
import {Navigate, generatePath} from 'react-router-dom';

import {Paths} from '../../routes/Paths';
import {ChatTab} from '../../stores/LayOutStore';
import workspaceIdToParam from '../../stores/Workspaces/utils/workspaceIdToParam';
import useStore from '../../stores/useStore';


export const LocalRedirect: React.FC = () => {
  const {activeWorkspace, channels, layOutStore, workspaces} = useStore();

  const showChatsTab = !!channels.list.length;
  layOutStore.setActiveTab(showChatsTab ? ChatTab.CHATS : ChatTab.SETTINGS);

  if (!activeWorkspace.id) {
    const path = workspaces.isInit ? Paths.BlockedWorkspaceAccess : null;
    console.debug('-->LocalRedirect to', path);
    return path ? <Navigate to={path} /> : null;
  }

  const redirectPath = showChatsTab
    ? generatePath(Paths.Chat, {
      workspaceId: workspaceIdToParam(activeWorkspace.id),
    })
    : generatePath(Paths.PersonalInfo, {
      workspaceId: workspaceIdToParam(activeWorkspace.id),
    });
  console.debug('-->LocalRedirect to', redirectPath);

  return (
    <Navigate
      to={redirectPath}
      replace
    />
  );
};

export default LocalRedirect;