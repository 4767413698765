// https://developers.facebook.com/docs/instagram-platform/instagram-api-with-instagram-login/messaging-api

export const INSTAGRAM_AUTH_CLIENT_ID = '3759728007577861';
export const INSTAGRAM_AUTH_BASE_URL = 'https://www.instagram.com/oauth/authorize'
export const INSTAGRAM_AUTH_RESPONSE_TYPE = 'code';

export const INSTAGRAM_ALLOWED_WINDOW_INFO_URL = 'https://developers.facebook.com/docs/messenger-platform/send-messages#standard-messaging'

// https://developers.facebook.com/docs/permissions
export const INSTAGRAM_AUTH_PERMISSION_SCOPE = [
  'instagram_business_basic',
  'instagram_business_manage_messages',
  // 'instagram_business_content_publish',
  // 'instagram_business_manage_comments',
];


export const INSTAGRAM_SUPPORTED_AUDIO_CONTENT_TYPES = new Set([
  'audio/wav',
  'audio/wave',
  'audio/x-wav',

  'audio/aac',
  'audio/x-aac',

  'audio/m4a',
  'audio/mp4',
  'audio/x-m4a',
]);

export const INSTAGRAM_SUPPORTED_IMAGE_CONTENT_TYPES = new Set([
  'image/png',
  'image/gif',
  'image/jpeg',
]);

export const INSTAGRAM_SUPPORTED_VIDEO_CONTENT_TYPES = new Set([
  'video/mp4',
  'video/ogg',
  'video/x-msvideo',
  'video/quicktime',
  'video/webm',
]);

export const INSTAGRAM_SUPPORTED_AUDIO_MAX_SIZE_MB = 25;
export const INSTAGRAM_SUPPORTED_IMAGE_MAX_SIZE_MB = 8;
export const INSTAGRAM_SUPPORTED_VIDEO_MAX_SIZE_MB = 25;
