import {action, makeObservable, observable} from 'mobx';

import * as cache from 'o-ui/utils/cache';
import {DownloadResponse, fileUploader} from '../../api/fileUploader';
import {BRAND_NAME} from '../../config';
import {uint8ArrayToUuid} from '../../utils/arrayUtils';
import {FileData} from '../../utils/file/fileReaders';
import {FileTypeParser} from '../../utils/file/fileTypeBrowser';
import {findMimeType} from '../../utils/file/mimeTypes';
import {WorkspaceStore} from '../Workspaces';

const CACHE_MAX_BYTES = 10 * 1024 * 1024; // 10 Mb
export const DOWNLOAD_CANCELED_ERROR_MESSAGE = 'Download canceled';

type PreparedAttachment = {
  src: string;
  mimeType?: string;
};

interface OnAttachmentProgress {
  (
    progress: number, // Float between 0 and 1.
    progressPercent: number,
    ...args: any[]
  ): void;

  isCanceled?: boolean;
  acceptsBuffer?: boolean;
}

interface IDownloadProgress {
  progress: number; // Float between 0 and 1.
  progressPercent: number;
  error?: string;
  isCanceled?: boolean;
}

interface IAttachmentStore {
  fetch(attachmentID?: Uint8Array | null, channelID?: Uint8Array | null): Promise<PreparedAttachment>;
}

export default class AttachmentStore implements IAttachmentStore {
  constructor(protected workspace: WorkspaceStore) {
    makeObservable(this);
  }

  @observable public progressMap: {[attachmentID: string]: IDownloadProgress | undefined} = {};

  private attachmentIDToString_ = (attachmentID?: Uint8Array | null): string => {
    if (!attachmentID) {
      return '';
    }
    return uint8ArrayToUuid(attachmentID);
  };

  public hasInProgress = (attachmentID?: Uint8Array | null): boolean => {
    const attachmentUuid = this.attachmentIDToString_(attachmentID);
    if (!attachmentID) {
      return false;
    }

    return !!this.progressMap[attachmentUuid];
  };

  @action private addToProgress = (attachmentID: Uint8Array, data: IDownloadProgress) => {
    const attachmentUuid = this.attachmentIDToString_(attachmentID);
    const fileProgress = this.progressMap[attachmentUuid];

    this.progressMap = {
      ...this.progressMap,
      [attachmentUuid]: {
        ...fileProgress,
        ...data,
      },
    };
  };

  @action private updateFileProgress = (attachmentID: Uint8Array, data: IDownloadProgress) => {
    const attachmentUuid = this.attachmentIDToString_(attachmentID);
    if (this.progressMap[attachmentUuid]?.progressPercent !== data.progressPercent) {
      // console.debug(`%cDownload progress: ${attachmentUuid} ${data.progressPercent}`, 'color: gray');
      this.progressMap[attachmentUuid] = {
        ...this.progressMap[attachmentUuid],
        ...data,
      };
    }
  };

  @action private removeFromProgress = (attachmentID?: Uint8Array | null) => {
    const attachmentUuid = this.attachmentIDToString_(attachmentID);

    if (this.progressMap[attachmentUuid]) {
      delete this.progressMap[attachmentUuid];
    }
  };

  @action cancelDownload = (attachmentID?: Uint8Array | null) => {
    const attachmentUuid = this.attachmentIDToString_(attachmentID);

    const progress = this.progressMap[attachmentUuid];
    if (progress) {
      progress.isCanceled = true;
    }
  };

  private isCanceled_ = (attachmentID?: Uint8Array | null): boolean => {
    const attachmentUuid = this.attachmentIDToString_(attachmentID);

    return !!this.progressMap[attachmentUuid]?.isCanceled;
  };

  public fetch = async (
    attachmentID?: Uint8Array | null,
    channelID?: Uint8Array | null,
    name?: string | null,
    onProgress?: OnAttachmentProgress,
    callbackUniqueId?: string,
    scope?: string | null,
  ): Promise<PreparedAttachment> => {
    if (!attachmentID || !channelID) {
      return {
        src: '',
      };
    }

    const attachmentKey = this.formatAttachmentKey_(attachmentID, channelID);

    if (this.memoryCache_.has(attachmentKey)) {
      // console.debug(`%c----->fetch FromCache ID: ${uint8ArrayToUuid(attachmentID)}`, 'color: green; font-weight: bold', this.memoryCache_.get(attachmentKey));
      return this.memoryCache_.get(attachmentKey) as PreparedAttachment;
    }

    this.addToProgress(attachmentID, {
      progress: 0,
      progressPercent: 0,
    });

    let promise = this.fetchPromises_.get(attachmentKey);

    if (!promise) {
      promise = this.fetchFromCacheOrRemote_(attachmentID, channelID, name, scope)
        .catch((err) => {
          console.debug('fetch Error: ', err);
          throw err;
        })
        .finally(() => {
          this.fetchPromises_.delete(attachmentKey);
          this.progressCallbacks_.delete(attachmentKey);
          this.removeFromProgress(attachmentID);
        });

      this.fetchPromises_.set(attachmentKey, promise);
    }

    if (onProgress && callbackUniqueId) {
      let activeCallbacks = this.progressCallbacks_.get(attachmentKey);
      if (!activeCallbacks) {
        activeCallbacks = new Map<string, OnAttachmentProgress>();
        this.progressCallbacks_.set(attachmentKey, activeCallbacks);
      }
      activeCallbacks.set(callbackUniqueId, onProgress);
    }

    return promise;
  };

  public get = async (attachmentID: Uint8Array, channelID?: Uint8Array | null, fileName?: string | null, scope?: string | null): Promise<{error?: {message: string}; res?: PreparedAttachment}> => {
    try {
      const res = await this.fetch(
        attachmentID,
        channelID,
        fileName,
        undefined,
        undefined,
        scope,
      );
      return {res};
    } catch (err: any) {
      return {error: {message: err?.message}};
    }
  };

  public getFromCache = (attachmentID?: Uint8Array | null, channelID?: Uint8Array | null): PreparedAttachment => {
    if (!attachmentID || !channelID) {
      return {
        src: '',
      };
    }

    const attachmentKey = this.formatAttachmentKey_(attachmentID, channelID);

    if (this.memoryCache_.has(attachmentKey)) {
      // console.debug(`%c----->fetch FromCache ID: ${uint8ArrayToUuid(attachmentID)}`, 'color: green; font-weight: bold', this.memoryCache_.get(attachmentKey));
      return this.memoryCache_.get(attachmentKey) as PreparedAttachment;
    }

    return {
      src: '',
    };
  };

  private memoryCache_ = new Map<string, PreparedAttachment>();
  private fetchPromises_ = new Map<string, Promise<PreparedAttachment>>();
  private progressCallbacks_ = new Map<string, Map<string, OnAttachmentProgress>>();

  private formatAttachmentKey_ = (attachmentID: Uint8Array, channelID: Uint8Array): string => {
    return `${uint8ArrayToUuid(channelID)}_${uint8ArrayToUuid(attachmentID)}`;
  };

  private formatStoreKey_ = (scope?: string | null): string => {
    if (scope) {
      return `${BRAND_NAME}_${scope}`;
    }
    return `${BRAND_NAME}_${uint8ArrayToUuid(this.workspace.id)}`;
  };

  private prepareAttachment_ = (attachmentData: Blob): PreparedAttachment => {
    return {
      src: URL.createObjectURL(attachmentData),
      mimeType: attachmentData.type,
    };
  };

  public putToCache = (channelID: Uint8Array, dataFile: FileData, attachmentID: Uint8Array): void => {
    const attachmentKey = this.formatAttachmentKey_(attachmentID, channelID);

    this.memoryCache_.set(attachmentKey, {
      src: dataFile.objectUrl,
      mimeType: dataFile.mimeType,
    });
  };

  protected fetchFromCacheOrRemote_ = async (
    attachmentID: Uint8Array,
    channelID: Uint8Array,
    fileName?: string | null,
    scope?: string | null,
  ): Promise<PreparedAttachment> => {
    const attachmentKey = this.formatAttachmentKey_(attachmentID, channelID);
    const storeKey = this.formatStoreKey_(scope);

    const cached = await cache.fetch(storeKey, attachmentKey, cache.CacheType.Blob, false);

    if (cached) {
      const prepared = this.prepareAttachment_(cached);
      this.memoryCache_.set(attachmentKey, prepared);
      return prepared;
    }

    const {error, res} = await this.getAttachment(attachmentID, fileName);

    if (error || !res?.content) {
      throw new Error(error?.message || '');
    }

    let mimeType = findMimeType(fileName);
    let blob = new Blob([res.content], {type: mimeType});

    if (!mimeType) {
      const info = new FileTypeParser(blob).parse(res.content);
      if (info?.mime) {
        mimeType = info?.mime;
        blob = new Blob([res.content], {type: info?.mime});
      }
    }

    // console.debug(`%c----->fetchFromCacheOrRemote_ ID: ${uint8ArrayToUuid(attachmentID)}`, 'color: green', fileName, mimeType, res.content.length);

    const canCache = blob.size !== 0 && blob.size <= CACHE_MAX_BYTES;
    if (canCache) {
      cache.put(storeKey, attachmentKey, blob);
    }

    const prepared = this.prepareAttachment_(blob);
    // console.debug(`%c----->fetchFromCacheOrRemote_ ID: ${uint8ArrayToUuid(attachmentID)}`, 'color: green; font-weight: bold', prepared);

    this.memoryCache_.set(attachmentKey, prepared);

    return prepared;
  };

  public getAttachment = async (attachmentID: Uint8Array, fileName?: string | null): Promise<DownloadResponse> => {
    const response = await fileUploader.download({
      attachmentID,
      fileName,
      onDownloadProgress: ({progress}) => {
        const progressPercent = Math.floor((progress || 0) * 100);
        this.updateFileProgress(attachmentID, {
          progress: progress || 0,
          progressPercent,
        });
      },
    });

    if (this.isCanceled_(attachmentID)) {
      console.debug(`%c----->getAttachment isCanceled: ${uint8ArrayToUuid(attachmentID)}`, 'color: red');

      return {error: {message: DOWNLOAD_CANCELED_ERROR_MESSAGE}};
    }

    return response;
  };
}
