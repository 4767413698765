import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';
import React, {RefObject} from 'react';
import ReactPlayer from 'react-player';

import {equalUint8Arrays} from '../utils/arrayUtils';
import {PLAYER_DATE_TIME_FORMAT, formatDateTimeUnix} from '../utils/format';
import formatSeconds from '../utils/formatSeconds';
import {AppStore} from './AppStore';
import MessageAttachment from './Attachment/MessageAttachment';
import Message from './Message';

export class AudioStore {
  constructor(public app: AppStore) {
    makeObservable(this);
  }

  @observable activeAudioRef?: RefObject<ReactPlayer> = React.createRef<ReactPlayer>();
  @observable activeMessage?: Message;
  @observable activeAudioUrl?: string;
  @observable activeAudioInfo?: MessageAttachment;
  @observable activeAudioSenderName?: string;
  @observable activeAudioStamp?: Long | null;

  @observable isPlaying = false;
  @observable played = 0;
  @observable playedSeconds = 0;

  @observable willClosing = false;

  @observable volume = 100;

  @action setVolume = (volume: number) => {
    this.volume = volume;
  };

  @action setWillClosing = (closing: boolean) => {
    this.willClosing = closing;
  };

  @computed get isVoiceNote() {
    return false;
  }

  @computed get activeAudioDate() {
    if (this.activeAudioStamp) {
      return formatDateTimeUnix(this.activeAudioStamp, PLAYER_DATE_TIME_FORMAT);
    }
    return undefined;
  }

  @computed get activeAudioDuration(): number {
    if (this.played) {
      return Math.floor(this.playedSeconds / this.played);
    }
    return this.activeAudioInfo?.duration?.toNumber() || 0;
  }

  @computed get activeAudioTime() {
    if (this.activeAudioDuration) {
      return `${formatSeconds(this.playedSeconds)} / ${formatSeconds(this.activeAudioDuration)}`;
    }
    return '-';
  }

  @action setActiveAudio = (message: Message, info: MessageAttachment, senderName?: string, stamp?: Long | null) => {
    this.reset();
    this.activeMessage = message;
    this.activeAudioInfo = info;
    this.activeAudioSenderName = senderName;
    this.activeAudioStamp = stamp;

    this.loadAudio(message);
  };

  public isActive = (attachment: MessageAttachment): boolean => {
    return equalUint8Arrays(attachment.id, this.activeAudioInfo?.id);
  };

  loadAudio = async (message: Message) => {
    if (!this.activeAudioInfo?.id) {
      return;
    }

    const res = await message.chat.store?.getAttachment?.(this.activeAudioInfo?.id);

    if (res.res?.src) {
      this.setActiveAudioUrl(res.res?.src);
    }
  };

  @action setActiveAudioUrl = (src: string) => {
    this.activeAudioUrl = src;
  };

  @action setPlaying = (isPlaying: boolean) => {
    this.isPlaying = isPlaying;
  };

  @action setPlayed = (played: number) => {
    this.played = played;
  };

  @action setPlayedSeconds = (playedSeconds: number) => {
    this.playedSeconds = playedSeconds;
  };

  closeTopPanel = () => {
    this.reset();
  };

  @action reset = () => {
    this.setWillClosing(false);

    this.played = 0;
    this.playedSeconds = 0;
    this.isPlaying = false;
    this.activeMessage = undefined;
    this.activeAudioInfo = undefined;
    this.activeAudioSenderName = undefined;
    this.activeAudioStamp = undefined;
    this.activeAudioUrl = undefined;
  };
}

export default AudioStore;
