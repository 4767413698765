import React from 'react';
import CircularProgress from 'o-ui/CircularProgress';

const ChatAreaLoader: React.FC = () => {
  return (
    <div className="chat-area chat-area--no-transition">
      <div className="chat-area__body chat-area__body--empty-body">
          <CircularProgress
            size={72}
            style={{
              color: 'var(--brand-primary)',
              zIndex: 3,
            }}
          />
      </div>
    </div>
  );
};

export default ChatAreaLoader;
