import React from 'react';
import {Trans} from 'react-i18next';

import {MCMethodSendMessageResponse} from '../../../api/proto';
import {AppStore} from '../../AppStore';
import {INSTAGRAM_ALLOWED_WINDOW_INFO_URL} from '../../../config';
import ChatStore from '../ChatStore';
import getChannelTypeName from '../../Channel/utils/getChannelTypeName';

export type SendMessageError = {
  message?: string | null;
  customType?: MCMethodSendMessageResponse.Result | null;
}

type SendMessageContext = {
  app: AppStore;
  chatStore: ChatStore;
}

export default function processSendMessageError(error: SendMessageError, context: SendMessageContext) {
  if (error.customType === MCMethodSendMessageResponse.Result.R_24_HOUR_ALLOWED_WINDOW) {
    const channelName = getChannelTypeName(context.chatStore.channel.type);

    context.app.notifications.error(
      (
        <Trans i18nKey="send_message_error_24_hour_allowed_window" />
      ),
      (
        <Trans
          i18nKey="send_message_error_24_hour_allowed_window_info"
          values={{
            url: INSTAGRAM_ALLOWED_WINDOW_INFO_URL,
            channelName,
          }}
          components={[
            <a
              key="0"
              className="link"
              href={INSTAGRAM_ALLOWED_WINDOW_INFO_URL}
              target="_blank"
              rel="noreferrer"
            >
              {channelName}
            </a>
          ]}
        />
      )
    );
    return;
  }

  context.app.notifications.error(error.message);
}