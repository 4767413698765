const addZero = (value: number): string => {
  return value < 10 ? `0${value}` : `${value}`;
};

export const formatDuration = (ms: number): string => {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const hours = Math.floor((ms % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
  const minutes = Math.floor((ms % (60 * 60 * 1000)) / (60 * 1000));
  const seconds = Math.floor((ms % (60 * 1000)) / 1000);

  if (days) {
    return `${days}d, ${addZero(hours)}:${addZero(minutes)}:${addZero(seconds)}`;
  }

  if (hours) {
    return `${addZero(hours)}:${addZero(minutes)}:${addZero(seconds)}`;
  }

  return `${addZero(minutes)}:${addZero(seconds)}`;
};

export default formatDuration;