import React from 'react';
import {Provider} from 'mobx-react';

import AppContainer from './AppContainer';
import AppLoader from './components/AppLoader';
import LogoSwitcher from './components/LogoSwitcher';
import Notifier from './components/Notifier';
import ThemeSelector from './components/ThemeWrappers/ThemesSelector';
import VersionUpdateAppBar from './components/VersionUpdateAppBar';
import WindowTitleBarArea from './components/WindowTitleBar/WindowTitleBarArea';
import SnackbarProvider from './plugins/notistack/SnackbarProvider';
import Routes from './routes';
import store from './stores/AppStore';

import './assets/scss/common.scss';

export const App = () => {
  return (
    <Provider store={store}>
      <AppContainer>
        <ThemeSelector>
          <WindowTitleBarArea />
          <AppLoader>
            <SnackbarProvider>
              <VersionUpdateAppBar />
              <Routes />
              <Notifier />
            </SnackbarProvider>
          </AppLoader>
          <LogoSwitcher />
        </ThemeSelector>
      </AppContainer>
    </Provider>
  );
};

export default App;
