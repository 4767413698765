import React from 'react';

//import AuthLayout from '../layouts/AuthLayout';
//import AuthSideMenuLayout from '../layouts/AuthSideMenuLayout';
import NonAuthLayout from '../layouts/NonAuthLayout';

import Logout from '../pages/Auth/Logout';
import SettingsRedirect from '../pages/Settings/SettingsRedirect';
import Paths from '../routes/Paths';
//import BillingRedirect from './redirects/BillingRedirect';
import LocalRedirect from './redirects/LocalRedirect';
import ResetPasswordRedirect from './redirects/ResetPasswordRedirect';

const AuthLayout = React.lazy(() => import('../layouts/AuthLayout'));
const AuthSideMenuLayout = React.lazy(() => import('../layouts/AuthSideMenuLayout'));

const AcceptInvitePage = React.lazy(() => import('../pages/Auth/AcceptInvitePage'));
const RecoveryPasswordPage = React.lazy(() => import('../pages/Auth/RecoveryPasswordPage'));
const RegistrationCompletePage = React.lazy(() => import('../pages/Auth/RegistrationCompletePage'));
const OnboardingPage = React.lazy(() => import('../pages/Onboarding/OnboardingPage'));
const QuickOnboardingPage = React.lazy(() => import('../pages/Onboarding/QuickOnboardingPage'));
const ChannelsInstagramCallbackPage = React.lazy(() => import('../pages/Settings/ChannelsInstagramCallbackPage'));
const BlockedWorkspaceAccess = React.lazy(() => import('../pages/Workspace/BlockedWorkspaceAccess'));

const ApprovePrivateRegistrationPage = React.lazy(() => import('../pages/Auth/ApprovePrivateRegistrationPage'));
const EmailVerificationCompletePage = React.lazy(() => import('../pages/Auth/EmailVerificationCompletePage'));
const EmailVerificationPage = React.lazy(() => import('../pages/Auth/EmailVerificationPage'));
const ExpiredInvitePage = React.lazy(() => import('../pages/Auth/ExpiredInvitePage'));
const ForgotPasswordPage = React.lazy(() => import('../pages/Auth/ForgotPasswordPage'));
const LoginPage = React.lazy(() => import('../pages/Auth/LoginPage'));
const PrivateRegistrationPage = React.lazy(() => import('../pages/Auth/PrivateRegistrationPage'));
const RegistrationPage = React.lazy(() => import('../pages/Auth/RegistrationPage'));
const ResetPasswordPage = React.lazy(() => import('../pages/Auth/ResetPasswordPage'));
const WidgetApiDocsPage = React.lazy(() => import('../pages/WidgetApiDocs/WidgetApiDocsPage'));

const ChatPage = React.lazy(() => import('../pages/Chat/ChatPage'));
const SettingsPage = React.lazy(() => import('../pages/Settings/SettingsPage'));

interface ReactPropTypes extends React.ReactPropTypes {
  children?: React.ReactNode;
}

export type RouteLayoutType = React.ComponentType<ReactPropTypes>;

export type RouteComponentType = React.ComponentType<ReactPropTypes>;

export interface IRoutePathProps {
  children?: React.ReactNode;
  path: string;
  isAuthProtected?: boolean;
  caseSensitive?: boolean;
  strict?: boolean;
}

export interface IRouteProps extends IRoutePathProps {
  layout: React.FC<React.PropsWithChildren>;
  component: React.FC<IRoutePathProps>;
  lazy?: boolean;
}

export const settingsRoutes: IRouteProps[] = [
  {path: Paths.Settings, component: SettingsPage, lazy: true, layout: AuthSideMenuLayout},

  {path: Paths.PersonalInfo, component: SettingsPage, lazy: true, layout: AuthSideMenuLayout},
  {path: Paths.Channels, component: SettingsPage, lazy: true, layout: AuthSideMenuLayout},
  {path: Paths.WorkspaceMembers, component: SettingsPage, lazy: true, layout: AuthSideMenuLayout},
  {path: Paths.Snippets, component: SettingsPage, lazy: true, layout: AuthSideMenuLayout},
  {path: Paths.WorkspaceSettings, component: SettingsPage, lazy: true, layout: AuthSideMenuLayout},

  {path: Paths.Billing, component: SettingsPage, lazy: true, layout: AuthSideMenuLayout},
  //{path: Paths.BillingPayCancel, component: BillingRedirect, layout: AuthSideMenuLayout},
  {path: Paths.BillingPaySuccess, component: SettingsPage, lazy: true, layout: AuthSideMenuLayout},
  {path: Paths.BillingTariff, component: SettingsPage, lazy: true, layout: AuthSideMenuLayout},
];

export const chatRoutes: IRouteProps[] = [
  {path: Paths.SpecificMessage, component: ChatPage, lazy: true, layout: AuthSideMenuLayout},
  {path: Paths.SpecificChat, component: ChatPage, lazy: true, layout: AuthSideMenuLayout},
  {path: Paths.Chat, component: ChatPage, lazy: true, layout: AuthSideMenuLayout},
  {path: Paths.Channel, component: ChatPage, lazy: true, layout: AuthSideMenuLayout},

  {path: Paths.UserChat, component: ChatPage, lazy: true, layout: AuthSideMenuLayout},
];

export const authProtectedRoutes: IRouteProps[] = [
  {path: Paths.ResetPasswordProfile, component: SettingsPage, lazy: true, layout: AuthSideMenuLayout},
  //...settingsRoutes,
  //...chatRoutes,

  {path: Paths.ChannelsInstagramCallback, component: ChannelsInstagramCallbackPage, lazy: true, layout: AuthSideMenuLayout},
  {path: Paths.RawBillingTariff, component: SettingsRedirect, layout: AuthSideMenuLayout},

  {path: Paths.BlockedWorkspaceAccess, component: BlockedWorkspaceAccess, lazy: true, layout: AuthLayout},

  {path: Paths.RegistrationComplete, component: RegistrationCompletePage, lazy: true, layout: AuthLayout},
  {path: Paths.Onboarding, component: OnboardingPage, lazy: true, layout: AuthLayout},
  {path: Paths.QuickOnboarding, component: QuickOnboardingPage, lazy: true, layout: AuthLayout},
  {path: Paths.RecoveryPassword, component: RecoveryPasswordPage, lazy: true, layout: AuthLayout},
  {path: Paths.AcceptInvite, component: AcceptInvitePage, lazy: true, layout: AuthLayout},

  {path: Paths.Default, component: LocalRedirect, layout: AuthLayout},
  {path: '*', component: LocalRedirect, layout: AuthLayout},
].map((item) => ({...item, isAuthProtected: true}));


export const publicRoutes: IRouteProps[] = [
  {path: Paths.Logout, component: Logout, layout: NonAuthLayout},
  {path: Paths.Login, component: LoginPage, lazy: true, layout: NonAuthLayout},
  {path: Paths.Registration, component: RegistrationPage, lazy: true, layout: NonAuthLayout},
  {path: Paths.RegistrationByInvite, component: PrivateRegistrationPage, lazy: true, layout: NonAuthLayout},
  {path: Paths.PrivateRegistration, component: PrivateRegistrationPage, lazy: true, layout: NonAuthLayout},
  {path: Paths.ApprovePrivateRegistration, component: ApprovePrivateRegistrationPage, lazy: true, layout: NonAuthLayout},
  {path: Paths.EmailVerification, component: EmailVerificationPage, lazy: true, layout: NonAuthLayout},
  {path: Paths.EmailVerificationComplete, component: EmailVerificationCompletePage, lazy: true, layout: NonAuthLayout},
  {path: Paths.ForgotPassword, component: ForgotPasswordPage, lazy: true, layout: NonAuthLayout},
  {path: Paths.ResetPasswordRedirect, component: ResetPasswordRedirect, layout: NonAuthLayout},
  {path: Paths.ResetPasswordNonAuth, component: ResetPasswordPage, lazy: true, layout: NonAuthLayout},
  {path: Paths.ExpiredInvite, component: ExpiredInvitePage, lazy: true, layout: NonAuthLayout},
  {path: Paths.WidgetApiDocs, component: WidgetApiDocsPage, lazy: true, layout: NonAuthLayout},
];

export const routes = [
  ...publicRoutes,
  ...authProtectedRoutes,
];

export const authPageOpen = (): boolean => {
  return (
    window.location.pathname === Paths.Login ||
    window.location.pathname === Paths.Registration ||
    window.location.pathname === Paths.RegistrationComplete ||
    window.location.pathname.startsWith(Paths.EmailVerification.replace(':code', '')) ||
    window.location.pathname === Paths.EmailVerificationComplete ||
    window.location.pathname === Paths.ForgotPassword ||
    window.location.pathname.startsWith(Paths.ResetPasswordRedirect.replace(':code', '').replace(':email', ''))
  );
};

export const pageIsOpen = (path: string): boolean => {
  return window.location.pathname === path;
};
