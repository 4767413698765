import {WebWidgetChannelState} from '../../../../api/proto';
import i18n from '../../../../i18n';


export default function getWebWidgetStateTitle(state?: WebWidgetChannelState.State | null): string {
  switch (state) {
    case WebWidgetChannelState.State.CREATE_CHANNEL:
      return i18n.t('settings_channel_manage_status_authorization');
    case WebWidgetChannelState.State.EVENT_LISTENING:
      return i18n.t('settings_channel_manage_status_launch');

    default:
      return '-';
  }
}