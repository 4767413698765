import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';
import {generatePath} from 'react-router-dom';

import {NetworkResponse} from '../../api/network';
import {APIResponse, api, entities} from '../../api/proto';
import unknownError from '../../api/unknownError';
import i18n from '../../i18n';
import Paths from '../../routes/Paths';
import browserHistory from '../../routes/browserHistory';
import {APILayer, APIRequestOptions} from '../../stores/APILayer';
import {equalUint8Arrays, uint8ArrayToBase64} from '../../utils/arrayUtils';
import {FileData} from '../../utils/file/fileReaders';
import formatAttachment from '../../utils/file/formatAttachment';
import {AppStore} from '../AppStore';
import AttachmentStore from '../Attachment/AttachmentStore';
import BillingStore from '../Billing';
import Channel, {ChannelsStore} from '../Channel';
import ChannelsUpdater, {ChannelsUpdaterEvent} from '../Channel/ChannelsUpdater';
import Chat from '../Chat';
import ChatsLoader from '../ChatsView/ChatsLoader';
import ChatsView from '../ChatsView/ChatsView';
import Peer from '../Peer';
import ChatsHistoryPool from '../RawChatHistory/ChatsHistoryPool';
import ChatsPool from '../RawChatsStore/ChatsPool';
import {IRawChat} from '../RawChatsStore/RawChat';
import MessagesPool from '../RawMessagesStore/MessagesPool';
import SnippetsStore, {SnippetType} from '../Snippets';
import ChatsSource from './ChatsSource';
import InvitesStore from './InvitesStore';
import Workspace from './Workspace';
import WorkspaceLocalStore from './WorkspaceLocalStore';
import WorkspaceMember from './WorkspaceMember';
import equalAccessRecords from './utils/equalAccessRecords';
import workspaceIdToParam from './utils/workspaceIdToParam';

const {WorkspaceRole} = entities;

type OneOfWorkspaceResponse = api.WorkspaceResponse[keyof api.WorkspaceResponse];

export const getUserRoleTitle = (role?: entities.WorkspaceRole | null): string => {
  switch (role) {
    case WorkspaceRole.WR_NONE:
      return 'NONE';
    case WorkspaceRole.WR_OWNER:
      return i18n.t('Owner');
    case WorkspaceRole.WR_ADMIN:
      return i18n.t('Admin');
    case WorkspaceRole.WR_OPERATOR:
      return i18n.t('Operator');
  }
  return role === null || role === undefined ? 'null' : WorkspaceRole[role];
};

export const isOwnerRole = (role?: entities.WorkspaceRole | null): boolean => {
  return role === WorkspaceRole.WR_OWNER || role === null || typeof role === 'undefined';
};

export const isEditableRole = (role?: entities.WorkspaceRole | null): boolean => {
  return !isOwnerRole(role);
};

export const getUserRoles = (): {caption: string; value: number}[] => {
  return Object.keys(WorkspaceRole)
    .filter((r) => WorkspaceRole[r] !== WorkspaceRole.WR_NONE && WorkspaceRole[r] !== WorkspaceRole.WR_OWNER)
    .map((r) => ({
      caption: getUserRoleTitle(WorkspaceRole[r]),
      value: WorkspaceRole[r],
    }));
};

export const WORKSPACE_NOT_INIT_ERROR = 'Workspace not init!';

export class WorkspaceStore extends APILayer {
  @observable private initializing = false;

  @action private setInitializing = (initializing: boolean) => {
    this.initializing = initializing;
  };

  @observable private members_: WorkspaceMember[] = [];

  @computed get members(): WorkspaceMember[] {
    return this.members_.slice().sort((a, b) => {
      const aCreatedAt = a.createdAt?.toNumber() || 0;
      const bCreatedAt = b.createdAt?.toNumber() || 0;

      return bCreatedAt - aCreatedAt;
    });
  }

  @computed get activeMembers(): WorkspaceMember[] {
    return this.members
      .filter((m) => m.active && (m.role === WorkspaceRole.WR_ADMIN || m.role === WorkspaceRole.WR_OPERATOR));
  }

  @computed get admins(): WorkspaceMember[] {
    return this.members.filter((m) => m.role === WorkspaceRole.WR_ADMIN);
  }

  @computed get operators(): WorkspaceMember[] {
    return this.members.filter((m) => m.role === WorkspaceRole.WR_OPERATOR);
  }

  @computed get membersCount(): number {
    return this.admins.length + this.operators.length + this.invites.inviteLinksActive.length;
  }

  @computed get isBlocked(): boolean {
    return this.app.workspaces.isBlocked(this.id);
  }

  localStore: WorkspaceLocalStore;

  updater: ChannelsUpdater;

  channels: ChannelsStore;

  invites: InvitesStore;

  chatsPool: ChatsPool;
  chatsHistoryPool: ChatsHistoryPool;
  messagesPool: MessagesPool;
  chatsSource: ChatsSource;

  chatsLoader: ChatsLoader;
  chatsView: ChatsView;

  attachmentStore: AttachmentStore;

  personalSnippets: SnippetsStore;
  workspaceSnippets: SnippetsStore;

  billing: BillingStore;

  @computed get id() {
    return this.workspace?.id;
  }

  @computed get name() {
    return this.workspace?.name || uint8ArrayToBase64(this.id);
  }

  @computed get avatar() {
    return {source: this.workspace?.avatar};
  }

  @computed get blocked() {
    return this.workspace?.blocked;
  }

  constructor(public workspace: Workspace | undefined | null, public app: AppStore) {
    super(app);

    this.localStore = new WorkspaceLocalStore(this);

    this.personalSnippets = new SnippetsStore(this.app, SnippetType.Personal);
    this.workspaceSnippets = new SnippetsStore(this.app, SnippetType.Workspace);


    this.updater = new ChannelsUpdater(this);
    this.channels = new ChannelsStore(this);
    this.chatsPool = new ChatsPool(this);
    this.chatsHistoryPool = new ChatsHistoryPool(this);
    this.messagesPool = new MessagesPool(this);

    this.invites = new InvitesStore(this);

    this.chatsLoader = new ChatsLoader(this);
    this.attachmentStore = new AttachmentStore(this);
    this.chatsView = new ChatsView(this);
    this.chatsSource = new ChatsSource(this);

    this.billing = new BillingStore(this);

    this.updater.on(ChannelsUpdaterEvent.NEED_RELOAD, this.reload);

    makeObservable(this);
  }

  @action update = (raw?: entities.IWorkspace | null) => {
    this.workspace?.update(raw);
  };

  workspaceRequest = async <T = OneOfWorkspaceResponse>(
    data: api.IWorkspaceRequest,
    responseType: keyof api.WorkspaceResponse | null = null,
    options?: APIRequestOptions,
    force?: boolean,
  ) => {
    if (!this.id && !force) {
      console.error(`%c---->WorkspaceRequest: ${WORKSPACE_NOT_INIT_ERROR}`, 'color: red');
      return {error: {message: WORKSPACE_NOT_INIT_ERROR}, res: null};
    }

    const response = await this.request<api.WorkspaceResponse>(
      {
        workspaceRequest: {
          workspaceId: this.id,
          ...data,
        },
      },
      'workspaceResponse',
      options,
    );

    let res: T | null | undefined = null;
    if (responseType && response.res && response.res[responseType]) {
      res = response.res[responseType] as T;
    }

    // this.processWorkspaceError_(response);

    let error = response.error || null;
    if (!res && !error) {
      error = unknownError;
    }

    return {error, res};
  };

  private processWorkspaceError_ = (response: NetworkResponse<api.WorkspaceResponse, APIResponse.Status>) => {
    if (
      browserHistory.location.pathname !== Paths.BlockedWorkspaceAccess &&
      response.error?.type === APIResponse.Status.AS_NOT_ALLOWED
    ) {
      browserHistory.push(Paths.BlockedWorkspaceAccess);
    }

    if (
      browserHistory.location.pathname === Paths.BlockedWorkspaceAccess &&
      response.error?.type !== APIResponse.Status.AS_NOT_ALLOWED
    ) {
      browserHistory.replace(
        generatePath(Paths.PersonalInfo, {
          workspaceId: workspaceIdToParam(this.app.activeWorkspace.id),
        }),
      );
    }
  };


  @observable public updateInfoInProcess = false;

  @action protected setUpdateInfoInProcess_ = (val: boolean) => {
    this.updateInfoInProcess = val;
  };

  public updateInfo = async (name: string, dataFile?: FileData | null, deleteAvatar?: boolean) => {
    this.setUpdateInfoInProcess_(true);

    let attachmentSource = this.avatar.source;
    if (dataFile && this.id) {
      dataFile.attachment = formatAttachment(dataFile);
      this.app.uploader.addToProgress([dataFile]);

      const {attachments} = await this.app.uploader.uploadFiles(this.id, [dataFile]);

      if (attachments.length) {
        attachmentSource = attachments[0].source;
      }
    }

    const {error, res} = await this.workspaceRequest<api.IWorkspaceUpdateResponse>(
      {
        update: new api.WorkspaceUpdateRequest({
          workspaceId: this.id,
          name,
          avatar: deleteAvatar ? null : attachmentSource,
        }),
      },
      'update',
    );
    this.setUpdateInfoInProcess_(false);

    if (res?.workspace) {
      this.update(res?.workspace);
    }


    return {error, res};
  };

  @observable public isInit = false;

  @action protected setInit_ = (isInit: boolean) => {
    this.isInit = isInit;
  };

  init = async () => {
    if (this.initializing || this.isInit || !this.app.userStore.isLoggedIn) {
      return;
    }

    await this.load();

    await this.loadSnippets();

    this.setInit_(true);
  };

  load = async () => {
    console.debug(`%c---->Workspace.load`, 'color: red');
    if (this.initializing) {
      return;
    }

    this.setInitializing(true);
    await this.loadMembers();

    if (this.app.userStore.isAdmin || this.app.userStore.isOwner) {
      await this.invites.load();
    }

    await this.channels.load();

    await this.billing.init();

    this.setInitializing(false);
    this.updater.start();
  };

  loadSnippets = async () => {
    await this.workspaceSnippets.load();
    await this.personalSnippets.load();
  };

  @computed get availableChannels() {
    return this.channels?.availableChannels;
  }

  @computed get availableChannelsIds() {
    return this.availableChannels.map((c) => c.id);
  }

  @computed get currentMember(): WorkspaceMember | null {
    const userId = this.app.userStore.userId;
    return userId ? this.members_.find((member) => member.userId?.equals(userId)) || null : null;
  }

  findChat = (chatID?: Long | null, channelID?: Uint8Array | null): Chat | null => {
    return this.chatsLoader.findChat(chatID, channelID);
  };

  public findRawChat = (chatId?: Long | null, channelId?: Uint8Array | null): IRawChat | null => {
    if (!chatId) {
      return null;
    }

    return this.chatsPool.find(chatId, channelId);
  };

  loadMembers = async () => {
    const {error, res} = await this.workspaceRequest<api.IWorkspaceMembersResponse>(
      {
        members: new api.WorkspaceMembersRequest({
          offset: Long.ZERO,
          filters: {
            roles: [
              entities.WorkspaceRole.WR_NONE,
              entities.WorkspaceRole.WR_OWNER,
              entities.WorkspaceRole.WR_ADMIN,
              entities.WorkspaceRole.WR_OPERATOR,
            ],
            statuses: [
              entities.WorkspaceMemberStatus.WM_ACTIVE,
              entities.WorkspaceMemberStatus.WM_BLOCKED,
              entities.WorkspaceMemberStatus.WM_INVITED,
              entities.WorkspaceMemberStatus.WM_INVITE_EXPIRED,
            ],
            sources: [
              entities.WorkspaceMemberSource.WIT_CREATOR,
              entities.WorkspaceMemberSource.WIT_EMAIL_INVITE,
              entities.WorkspaceMemberSource.WIT_LINK_INVITE,
            ],
          },
        }),
      },
      'members',
    );

    if (res) {
      this.processMembers(res.members);
    }

    return {error, res};
  };

  @action processMembers = (members?: entities.IWorkspaceMember[] | null) => {
    this.setInit_(true);

    this.members_ =
      members?.map((rawMember) => {
        const member = this.findMember(rawMember.id);
        member?.set({
          ...rawMember,
          status: rawMember.status || null,
        });
        return member || new WorkspaceMember(rawMember, this);
      }) ?? [];

    console.debug(
      `%c processMembers:`,
      'color: green;',
      this.members_.map(
        ({
          id,
          userId,
          email,
          fullName,
          emailInvite,
          inviteTargetEmail,
          inviteLink,
          inviteUrl,
          emailInviteUrl,
          role,
          status,
          invitedByMember,
        }) => ({
          id,
          userId,
          email,
          fullName,
          emailInvite,
          inviteTargetEmail,
          inviteLink,
          inviteUrl,
          emailInviteUrl,
          role,
          status,
          invitedByMember,
        }),
      ),
    );
  };

  findChannel = (channelId?: Uint8Array | null): Channel | null => {
    return this.channels.findChannel(channelId);
  };

  findUser = (userId?: Long | null, channelId?: Uint8Array | null): Peer | null => {
    if (!userId) {
      return null;
    }

    let user: Peer | null = null;

    if (channelId) {
      const channel = this.findChannel(channelId);
      user = channel?.findUser(userId) || null;
    }

    return user;
  };

  findMember = (id?: Long | null): WorkspaceMember | null => {
    if (!id) {
      return null;
    }

    return this.members_.find((member) => member.id?.equals(id)) || null;
  };

  findMemberByUserId = (userId?: Long | null): WorkspaceMember | null => {
    if (!userId || userId.equals(Long.fromNumber(0))) {
      return null;
    }
    return this.members_.find((member) => member.userId?.equals(userId)) || null;
  };

  getSnippetsStore = (type: SnippetType) => {
    return type === SnippetType.Workspace ? this.workspaceSnippets : this.personalSnippets;
  };

  @action public reset = () => {
    this.setInit_(false);
    this.setLoading(false);
    this.setInitializing(false);

    this.workspaceSnippets.reset();
    this.personalSnippets.reset();

    this.updater.reset();
    this.channels.reset();
    this.chatsPool.reset();
    this.chatsHistoryPool.reset();
    this.messagesPool.reset();

    this.invites.reset();

    this.chatsLoader.reset();
    this.chatsView.reset();

    this.members_ = [];
  };

  @action resetChatsPool = () => {
    this.chatsPool.reset();
    this.chatsLoader.clear();
  };

  reload = () => {
    this.reset();
    this.init();
  };

  refreshMembers = async () => {
    const oldAccessRecords = this.currentMember?.access?.records?.slice();
    const {error, res} = await this.loadMembers();
    const newAccessRecords = this.currentMember?.access?.records?.slice();

    if (this.app.userStore.isAdmin || this.app.userStore.isOwner) {
      await this.invites.load();
    }

    return {
      error,
      res,
      accessIsChanged: equalAccessRecords(oldAccessRecords, newAccessRecords),
    };
  };

  recheckMemberChatsChannelsAccess = async () => {
    const records = this.currentMember?.access?.records;
    console.debug(`%c---->Workspace.recheckMemberChatsChannelsAccess`, 'color: red', records);

    if (!records) {
      return;
    }

    records.forEach(({channelID, chatsDenyList, disableChannel, chatsAllowList}) => {
      if (!channelID) {
        return;
      }

      if (
        equalUint8Arrays(this.app.chatsView.activeUsersChat?.channelID, channelID) &&
        ((chatsAllowList?.length && !chatsAllowList?.some((id) => this.app.chatsView.activeUsersChat?.id.equals(id))) ||
          (chatsDenyList?.length && chatsDenyList?.some((id) => this.app.chatsView.activeUsersChat?.id.equals(id))) ||
          disableChannel)
      ) {
        this.chatsView.closeActiveChat();

        browserHistory.push(
          generatePath(Paths.Chat, {
            workspaceId: workspaceIdToParam(this.app.activeWorkspace.id),
          }),
        );
      }
    });

    this.resetChatsPool();

    await this.channels.load();

    this.chatsView.reactivateChannel();
  };
}

export default WorkspaceStore;
