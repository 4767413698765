import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import Button from 'o-ui/Button';
import Card from 'o-ui/Card';
import CardActions from 'o-ui/CardActions';

import {SnackbarContent, useSnackbar} from '../plugins/notistack';
import {VariantType} from '../plugins/notistack/types';
import {useStore} from '../stores/AppStore';
import {ISnackbarAction} from '../stores/NotificationsStore';

import {ReactComponent as SnackbarError} from '../assets/image-icons/snackbarError.svg';
import {ReactComponent as SnackbarErrorDark} from '../assets/image-icons/snackbarErrorDark.svg';
import {ReactComponent as SnackbarInfo} from '../assets/image-icons/snackbarInfo.svg';
import {ReactComponent as SnackbarInfoDark} from '../assets/image-icons/snackbarInfoDark.svg';
import {ReactComponent as SnackbarSuccess} from '../assets/image-icons/snackbarSuccess.svg';
import {ReactComponent as SnackbarSuccessDark} from '../assets/image-icons/snackbarSuccessDark.svg';
import {ReactComponent as SnackbarWarning} from '../assets/image-icons/snackbarWarning.svg';
import {ReactComponent as SnackbarWarningDark} from '../assets/image-icons/snackbarWarningDark.svg';

export interface SnackMessageProps {
  className?: string;
  variant?: VariantType;
  id?: string | number;
  lightTheme?: boolean;
  message: string | React.ReactNode;
  subMessage?: string | React.ReactNode;
  hideCloseIcon?: boolean;
  actions?: ISnackbarAction[];
}

const SnackMessage = observer(
  React.forwardRef<HTMLDivElement, SnackMessageProps>((props, ref) => {
    const {lightThemeChosen} = useStore();
    const {closeSnackbar} = useSnackbar();

    const {
      className,
      variant = 'default',
      id,
      lightTheme = lightThemeChosen,
      message,
      subMessage,
      hideCloseIcon = false,
      actions
    } = props;

    const handleDismiss = React.useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref} className={cn('snack-message', variant, className)}>
        <Card className="snack-message__card">
          <CardActions className="snack-message__action">
            <div className="snack-message__icon-wrapper">
              {lightTheme ? (
                <>
                  <SnackbarSuccess className="snack-message__icon snack-message__icon--success" />
                  <SnackbarError className="snack-message__icon snack-message__icon--error" />
                  <SnackbarInfo className="snack-message__icon snack-message__icon--info" />
                  <SnackbarWarning className="snack-message__icon snack-message__icon--warning" />
                </>
              ) : (
                <>
                  <SnackbarSuccessDark className="snack-message__icon snack-message__icon--success" />
                  <SnackbarErrorDark className="snack-message__icon snack-message__icon--error" />
                  <SnackbarInfoDark className="snack-message__icon snack-message__icon--info" />
                  <SnackbarWarningDark className="snack-message__icon snack-message__icon--warning" />
                </>
              )}
            </div>

            <div className="snack-message__content-wrapper">
              <span className="snack-message__text h5-medium">{message}</span>
              {subMessage ? <div className="snack-message__sub-text">{subMessage}</div> : null}
              {actions ? (
                <div className="snack-message__actions">
                  {actions.map(({title, variant, size, className, ...rest}, idx) => (
                    <Button
                      className={cn('snack-message__action-btn mr-3', className)}
                      key={idx}
                      variant={variant || 'contained'}
                      size={size || 'small'}
                      {...rest}
                    >
                      {title}
                    </Button>
                  ))}
                </div>
              ) : null}
            </div>

            {hideCloseIcon ? null : (
              <div className="snack-message__close-wrapper">
                <span className="snack-message__close-icon tg-icon-cross-line cursor-pointer" onClick={handleDismiss} />
              </div>
            )}
          </CardActions>
        </Card>
      </SnackbarContent>
    );
  }),
);

SnackMessage.displayName = 'SnackMessage';

export default SnackMessage;
