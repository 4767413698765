import {InstagramChannelState} from '../../../../api/proto';
import i18n from '../../../../i18n';


export default function getInstagramChannelStateTitle(state?: InstagramChannelState.State | null): string {
  switch (state) {
    case InstagramChannelState.State.CREATE_CHANNEL:
      return i18n.t('settings_channel_manage_status_authorization');
    case InstagramChannelState.State.EVENT_LISTENING:
      return i18n.t('settings_channel_manage_status_launch');

    default:
      return '-';
  }
}