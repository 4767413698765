import {action, makeObservable, observable} from 'mobx';

import {AppStore} from '../AppStore';
import ContextMenuType from './ContextMenuType';
import ContextMenuItem, {ContextMenuData, IMenuPosition} from './ContextMenuItem';


export class ContextMenuStore {
  @observable current: ContextMenuItem | null = null;

  constructor(private app: AppStore) {
    makeObservable(this);
  }

  @action close = () => {
    this.current = null;
  };

  @action open = (position: IMenuPosition, type: ContextMenuType, data: ContextMenuData) => {
    const menu = new ContextMenuItem(
      {
        id: new Date().getTime(),
        position,
        type,
        data,
      },
      this,
    );

    this.current = menu;
    return menu;
  };

  isOpen = (type: ContextMenuType) => {
    return this.current?.type === type;
  };
}

export default ContextMenuStore;
