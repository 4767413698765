import React from 'react';
import {useTranslation} from 'react-i18next';
import Button from 'o-ui/Button';


interface PageLoadingErrorProps {
  onRetry?: () => void;
}

const PageLoadingError: React.FC<PageLoadingErrorProps> = ({onRetry}) => {
  const {t} = useTranslation();

  return (
    <div className="page-loading-error p-6 d-flex flex-column justify-content-center align-items-center text-center">
      <h1 className="h1-medium color-body-secondary text-capitalize">{t('page_loading_error_title')}</h1>
      <p className="h4-medium color-body-tertiary">{t('page_loading_error_subtitle')}</p>

      {onRetry && (
        <Button variant="contained" color="primary" onClick={onRetry}>
          {t('page_loading_error_reload_button')}
        </Button>
      )}
    </div>
  );
};

export default PageLoadingError;
