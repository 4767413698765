import {action, computed, makeAutoObservable, observable} from 'mobx';
import wait from '../../utils/wait';
import {AppStore} from '../AppStore';
import Chat from '../Chat';
import Message from '../Message';
import Peer from '../Peer';
import WorkspaceMember from '../Workspaces/WorkspaceMember';
import ChatInfoType from './ChatInfoType';
import RightSideBarType from './RightSideBarType';

export class RightSideBarStore {
  @observable peer?: Peer | null = null;
  @observable member?: WorkspaceMember | null = null;

  @observable chatInfoType: ChatInfoType = ChatInfoType.NONE;
  @observable peerInfoType: ChatInfoType = ChatInfoType.NONE;

  @observable chatInfoBarAnimationAllow = this.app.animationsEnabled;

  @computed get chat() {
    return this.app.chatsView.activeUsersChat;
  }

  @observable type: RightSideBarType = RightSideBarType.NONE;
  @observable isOpen = false;

  @action setType = (type: RightSideBarType) => {
    this.type = type;
    this.isOpen = type !== RightSideBarType.NONE;
  };

  constructor(private app: AppStore) {
    makeAutoObservable(this);
  }

  @action chatInfoBarAnimationEnable = () => {
    if (this.app.animationsEnabled) {
      this.chatInfoBarAnimationAllow = true;
    }
  };

  @action chatInfoBarAnimationDisable = () => {
    this.chatInfoBarAnimationAllow = false;
  };

  @observable chatInfoBarGalleryItemsDisplayAllow = false;

  @action setChatInfoBarGalleryItemsDisplayAllow = (allow: boolean) => {
    this.chatInfoBarGalleryItemsDisplayAllow = allow;
  };

  reset = () => {
    this.setType(RightSideBarType.NONE);
    this.setChat_(null);
    this.setPeer(null);
    this.setMember(null);
  };

  public open = async (chat?: Chat | null) => {
    if ((chat?.channel?.isWebWidget || chat?.isPrivate) && this.chat?.directChatWithPeer) {
      await chat?.store.updateDirectPeer();

      if (chat?.store?.directPeer) {
        this.setPeer(chat?.store?.directPeer);
        return;
      }
    }

    this.setChat_(chat);
  };

  public reopen = async (chat?: Chat | null) => {
    await this.open(chat);
  };

  public toggle = async (chat?: Chat | null) => {
    if (this.isOpen) {
      this.close();
    } else {
      await this.open(chat);
    }
  };

  @action public close = async () => {
    this.setChatInfoBarGalleryItemsDisplayAllow(false);

    this.isOpen = false;

    await wait(300); // need wait that right sidebar animation ended
    this.setChatInfoType(ChatInfoType.NONE);
    this.setPeerInfoType(ChatInfoType.NONE);
    this.setType(RightSideBarType.NONE);
  };

  private setChat_ = (chat?: Chat | null) => {
    chat?.store?.initChatHistory();
    this.setType(chat ? RightSideBarType.CHAT_INFO : RightSideBarType.NONE);
  };

  @action setPeer = (peer?: Peer | null) => {
    peer?.chat?.store?.initChatHistory();
    this.setType(peer ? RightSideBarType.PEER_INFO : RightSideBarType.NONE);
    this.peer = peer;
  };

  @action setMember = (member?: WorkspaceMember | null) => {
    this.setType(member ? RightSideBarType.MEMBER_INFO : RightSideBarType.NONE);
    this.member = member;
  };

  @action protected setChatInfoType = (type: ChatInfoType) => {
    this.chatInfoType = type;
  };

  @action protected setPeerInfoType = (type: ChatInfoType) => {
    this.peerInfoType = type;
  };

  public openChatGallery = (type: ChatInfoType, chat?: Chat | null) => {
    if (chat) {
      this.setChat_(chat);
    }
    this.setChatInfoType(type);
  };

  public closeChatGallery = () => {
    this.setChatInfoType(ChatInfoType.NONE);
  };

  public openPeerGallery = (type: ChatInfoType, peer?: Peer | null) => {
    if (peer) {
      this.setPeer(peer);
    }
    this.setPeerInfoType(type);
  };

  public closePeerGallery = () => {
    this.setPeerInfoType(ChatInfoType.NONE);
  };

  public openMessageSenderInfo = async (message: Message) => {
    if (message.operator) {
      this.setMember(message.operator);
    } else if (message.peer?.id) {
      this.setPeer(message.peerInstance);
      const peer = await message.chat.store?.peers?.getPeer(message.peer?.id);
      if (peer) {
        this.setPeer(peer);
      }
    }
  };

  public openChatInfo = (chat: Chat) => {
    this.setChat_(chat);
  };
}

export default RightSideBarStore;
