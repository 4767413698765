import React from 'react';
import {observer} from 'mobx-react';

import useStore from '../stores/useStore';
import NonAuthModalWrapper from './NonAuthModalWrapper';

import '../assets/scss/pages/_modals.scss';

export const NonAuthModals: React.FC<React.PropsWithChildren> = observer(() => {
  const {modals} = useStore();

  return (
    <>
      {modals.list.map((modal) => (
        <NonAuthModalWrapper key={modal.id.toString()} modal={modal} />
      ))}
    </>
  );
});

export default NonAuthModals;