// eslint-disable-next-line  @typescript-eslint/no-var-requires
const gifDurations = require('gif-me-duration');

import Chat from '../../stores/Chat';
import getChatUploadLimits from '../../stores/Chat/utils/getChatUploadLimits';
import {randomUint8Array} from '../arrayUtils';
import FileData from './FileData';
import {fileTypeFromBlob} from './fileTypeBrowser';
import getFileExtension from './getFileExtension';

export * from './FileData';


export function isImageFile(mimeType?: string | null, fileName?: string | null): boolean {
  if (mimeType) {
    return mimeType?.startsWith('image');
  }
  return !!fileName?.match(/.(jpg|jpeg|png|gif)$/i);
}

export function isGifFile(mimeType?: string | null, fileName?: string | null): boolean {
  if (mimeType) {
    return mimeType?.startsWith('image/gif');
  }
  return !!fileName?.match(/.(gif)$/i);
}

export function isVideoFile(mimeType?: string | null): boolean {
  return !!mimeType?.startsWith('video');
}

export function getAudioDuration(src: string): Promise<number> {
  return new Promise(function (resolve) {
    const audio = new Audio();
    audio.addEventListener(
      'loadedmetadata',
      function () {
        resolve(audio.duration);
      },
      false,
    );
    audio.src = src;
  });
}

const readFileInfo = async (file: File) => {
  let fileType = file.type;
  let fileName = file.name;
  let fileExt = getFileExtension(fileName);

  if (!fileType) {
    const fileInfo = await readFileType(file);
    console.debug('fileInfo', fileInfo);
    fileType = fileInfo?.mime || '';
    fileName = fileExt ? fileName : fileName + '.' + fileInfo?.ext;
    fileExt = fileExt || fileInfo?.ext || '';
  }

  return {
    fileType,
    fileName,
    fileExt,
  };
};

export type FileReaderCallback = (dataFiles: FileData[]) => void;

const fileReader = async (files: File[], callback: FileReaderCallback, chat?: Chat | null) => {
  const dataFiles: FileData[] = [];

  for (const file of files) {
    const fileId = randomUint8Array();
    const {fileType, fileName} = await readFileInfo(file);
    const objectUrl = URL.createObjectURL(file);
    const {contentTypes, fixedContentTypes} = getChatUploadLimits(chat);

    const baseFileInfo: FileData = {
      fileId,
      file,
      fileName,
      objectUrl,
      mimeType: fileType,
      fixedMimeType: fixedContentTypes?.has(fileType),
    };

    if (contentTypes.image.has(fileType)) {
      const image = new Image();
      const reader = new FileReader();
      const objectUrl = URL.createObjectURL(file);
      image.onload = () => {
        reader.readAsArrayBuffer(file);
      };

      let duration;
      if (fileType.endsWith('gif')) {
        try {
          const result: {duration?: number; url?: string}[] | null = await gifDurations(objectUrl);
          if (result?.length && result[0].duration) {
            duration = result[0].duration / 1000;
          }
        } catch (e) {
          console.warn(e);
        }
      }

      reader.addEventListener('load', () => {
        dataFiles.push({
          ...baseFileInfo,
          animation: fileType.endsWith('gif') ? image : undefined,
          image: fileType.endsWith('gif') ? undefined : image,
          width: image.width,
          height: image.height,
          duration,
        });

        if (files.length === dataFiles.length) {
          callback(dataFiles);
        }
      });

      image.src = objectUrl;
    } else if (contentTypes.video.has(fileType)) {
      const video = document.createElement('video');
      const objectUrl = URL.createObjectURL(file);
      video.addEventListener(
        'loadedmetadata',
        function () {
          dataFiles.push({
            ...baseFileInfo,
            video,
            width: this.videoWidth,
            height: this.videoHeight,
            duration: this.duration || 0,
          });

          if (files.length === dataFiles.length) {
            callback(dataFiles);
          }
        },
        false,
      );
      video.src = objectUrl;
    } else if (contentTypes.audio.has(fileType)) {
      const audio = new Audio();
      const objectUrl = URL.createObjectURL(file);
      audio.addEventListener(
        'loadedmetadata',
        function () {
          dataFiles.push({
            ...baseFileInfo,
            audio,
            duration: this.duration || 0,
          });

          if (files.length === dataFiles.length) {
            callback(dataFiles);
          }
        },
        false,
      );
      audio.src = objectUrl;
    } else {
      dataFiles.push(baseFileInfo);

      if (files.length === dataFiles.length) {
        callback(dataFiles);
      }
    }
  }
};

export const dataFileReader = (files: File[], callback: FileReaderCallback, chat?: Chat | null) => {
  fileReader(files, callback, chat);
};

export default dataFileReader;

export const readFileType = async (file: File) => {
  const fileBlob = await fileToBlob(file);
  const fileType = await fileTypeFromBlob(fileBlob);
  return fileType;
};

export function fileToBlob(file: File): Promise<Blob> {
  return new Promise((resolve, reject) => {
    file
      .arrayBuffer()
      .then((arrayBuffer) => {
        const blob = new Blob([new Uint8Array(arrayBuffer)], {type: file.type});
        resolve(blob);
      })
      .catch(() => {
        reject(file);
      });
  });
}
