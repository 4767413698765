
export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';

export const MESSAGE_TIME_FORMAT = 'HH:mm';
export const MESSAGE_DATE_FORMAT = 'd.MM.yy';

export const MESSAGE_DAY_DELIMITER_DATE_FORMAT = 'MMMM d';
export const MESSAGE_DAY_DELIMITER_DATE_YEAR_FORMAT = 'MMMM d yyyy';

export const ATTACHMENT_MONTH_DELIMITER_FORMAT = 'MMMM yyyy';

export const FILES_DATE_TIME_FORMAT = "dd MMM, yyyy 'at' HH:mm";
export const PLAYER_DATE_TIME_FORMAT = "MMMM dd 'at' HH:mm";
export const BILLING_DATE_FORMAT = 'MMMM dd, yyyy';
export const BILLING_DATE_TIME_FORMAT = 'MMMM dd, yyyy HH:mm';
