import {TelegramBotChannelState} from '../../../../api/proto';
import i18n from '../../../../i18n';


export default function getTelegramBotStateTitle(state?: TelegramBotChannelState.State | null): string {
  switch (state) {
    case TelegramBotChannelState.State.CREATE_CHANNEL:
      return i18n.t('settings_channel_manage_status_authorization');
    case TelegramBotChannelState.State.EVENT_LISTENING:
      return i18n.t('settings_channel_manage_status_launch');
    case TelegramBotChannelState.State.ERROR_CHANNEL:
      return i18n.t('settings_channel_manage_status_conflict');

    default:
      return '-';
  }
}