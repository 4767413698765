export const TELEGRAM_BOT_SUPPORTED_AUDIO_CONTENT_TYPES = new Set([
  'audio/mp3',
  'audio/ogg',
  'audio/wav',
  'audio/mpeg',
  'audio/flac',
  'audio/aac',
  'audio/m4a',
  'audio/mp4',
  'audio/x-m4a',
]);

export const TELEGRAM_BOT_SUPPORTED_IMAGE_CONTENT_TYPES = new Set([
  'image/png',
  'image/gif',
  'image/jpeg',
  'image/webp',
]);

export const TELEGRAM_BOT_SUPPORTED_VIDEO_CONTENT_TYPES = new Set([
  'video/mp4',
]);


export const TELEGRAM_BOT_FIXED_CONTENT_TYPES = new Set([
  'image/webp',
]);
