import {IMeta} from '../../../api/proto';
import IPeerMetaInfo from './IPeerMetaInfo';

function stringToBoolean(value?: string | null): boolean | undefined {
  if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  }
  return undefined;
}

function stringToNumber(value?: string | null): number | undefined {
  if (value) {
    return parseFloat(value);
  }
  return undefined;
}

export default function parsePeerMeta(peerMeta?: IMeta | null): IPeerMetaInfo | null | undefined {
  if (!peerMeta?.opts) {
    return undefined;
  }

  const info: IPeerMetaInfo = {};
  peerMeta.opts.forEach((opt) => {
    switch (opt.key) {
      case 'userId':
        info.userId = opt.value;
        break;
      case 'user id':
        info.userId = opt.value;
        break;
      case 'user_id':
        info.userId = opt.value;
        break;
      case 'phoneNumber':
        info.phoneNumber = opt.value;
        break;
      case 'email':
        info.email = opt.value;
        break;
      case 'firstName':
        info.firstName = opt.value;
        break;
      case 'lastName':
        info.lastName = opt.value;
        break;
      case 'metadata':
        info.metadata = opt.value;
        break;
      case 'userDebugInfo':
        info.userDebugInfo = opt.value;
        break;
      case 'user-agent':
        info.userAgent = opt.value;
        break;
      case 'project':
        info.project = opt.value;
        break;
      case 'domain':
        info.domain = opt.value;
        break;
      case 'registration level':
        info.registrationLevel = opt.value;
        break;
      case 'registration_level':
        info.registrationLevel = opt.value;
        break;
      case 'email status':
        info.emailStatus = opt.value;
        break;
      case 'email_status':
        info.emailStatus = opt.value;
        break;

      case 'follower_count':
        info.followerCount = stringToNumber(opt.value);
        break;
      case 'is_business_follow_user':
        info.isBusinessFollowUser = stringToBoolean(opt.value);
        break;
      case 'is_user_follow_business':
        info.isUserFollowBusiness = stringToBoolean(opt.value);
        break;
      case 'is_verified_user':
        info.isVerifiedUser = stringToBoolean(opt.value);
        break;
    }
  });

  return info;
}
