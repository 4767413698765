import {action, computed, makeAutoObservable, observable} from 'mobx';
import {matchPath} from 'react-router';

import Paths from '../../routes/Paths';
import browserStorage from '../../utils/browserStorage';
import {AppStore} from '../AppStore';
import AppThemeStore from './AppThemeStore';
import ChatTab from './ChatTab';
import OnboardingStepType from './OnboardingStepType';
import RightBarStore from './RightSideBarStore';
import SettingsTab from './SettingsTab';
import getSettingsTabByPath from './utils/getSettingsTabByPath';

export class LayOutStore {
  rightSideBar: RightBarStore;

  theme: AppThemeStore = new AppThemeStore();

  @observable compactMode: boolean = false;

  @observable activeTab: ChatTab = matchPath(Paths.Chat, location.pathname) ? ChatTab.CHATS : ChatTab.SETTINGS;
  @observable prevActiveTab: ChatTab = matchPath(Paths.Chat, location.pathname) ? ChatTab.CHATS : ChatTab.SETTINGS;
  @observable activeSettingsTab: SettingsTab = getSettingsTabByPath(location.pathname) || SettingsTab.PERSONAL_INFO;

  @observable leftBarCollapsed = false;
  @observable showSettingsTabContent = false;

  constructor(private app: AppStore) {
    makeAutoObservable(this);

    this.rightSideBar = new RightBarStore(this.app);

    this.init();
  }

  @computed get allChatsReadButtonVisible(): boolean {
    if (this.app.channels.selectedChannel) {
      return !!this.app.channels.selectedChannel.unreadNumber;
    }

    return !!this.app.channels.list.filter((channel) => channel.unreadNumber).length;
  }

  @action init = () => {
    this.leftBarCollapsed = window.innerWidth > 992 ? !!browserStorage.getItem('leftBarCollapsed') : true;

    this.theme.init();
  };

  reset = () => {
    this.setActiveTab(ChatTab.CHATS);
    this.setActiveSettingsTab(SettingsTab.PERSONAL_INFO);

    this.rightSideBar.reset();
  };

  @action setLeftBarCollapsed = (collapsed: boolean) => {
    this.leftBarCollapsed = collapsed;

    if (collapsed) {
      browserStorage.setItem('leftBarCollapsed', '1');
    } else {
      browserStorage.removeItem('leftBarCollapsed');
    }
  };

  toggleLeftBarCollapsed = () => {
    this.setLeftBarCollapsed(!this.leftBarCollapsed);
  };

  @action setActiveTab = (tabId: ChatTab) => {
    if (this.activeTab !== tabId) {
      this.prevActiveTab = this.activeTab;
      this.activeTab = tabId;
    }

    if (tabId === ChatTab.SETTINGS) {
      this.rightSideBar.close();
      this.app.chatsView.reset();
    }
  };

  @action setActiveSettingsTab = (tabId: SettingsTab) => {
    this.activeSettingsTab = tabId;
  };

  @action setShowSettingsTabContent = (show: boolean) => {
    this.showSettingsTabContent = show;
  };

  @action toggleSettingsTabContent = () => {
    this.showSettingsTabContent = !this.showSettingsTabContent;
  };

  public closeContactsTab = () => {
    if (this.activeTab === ChatTab.CONTACTS) {
      this.setActiveTab(ChatTab.CHATS);
    }
  };

  @action setCompactMode = (compactMode: boolean) => {
    this.compactMode = compactMode;
  };

  @computed get chatLeftSidebarBottomMenuVisible(): boolean {
    /*
     if (
       this.app.settings.systemUser?.showContacts &&
       (this.activeTab === ChatTab.CHATS || this.activeTab === ChatTab.CONTACTS) &&
       this.app.accounts.selectedChannel?.isWebWidget !== true &&
       this.app.accounts.availableExternalChannels.some((channel) => !channel.isWebWidget)
     ) {
       return true;
     }
     */
    return false;
  }

  @computed get onboardingSteps(): OnboardingStepType[] {
    if (this.app.activeWorkspace.billing.hasAccess) {
      return [
        OnboardingStepType.PERSONAL_INFO,
        OnboardingStepType.WORKSPACE_SETTINGS,
        OnboardingStepType.CHANNEL_CREATION,
        OnboardingStepType.OPERATORS_INVITATION,
        OnboardingStepType.BILLING,
      ];
    }

    return [
      OnboardingStepType.PERSONAL_INFO,
      OnboardingStepType.WORKSPACE_SETTINGS,
      OnboardingStepType.CHANNEL_CREATION,
      OnboardingStepType.OPERATORS_INVITATION,
    ];
  }

  findOnboardingStepIndex = (step: OnboardingStepType): number => {
    return this.onboardingSteps.findIndex((s) => s === step);
  };
}

export default LayOutStore;
