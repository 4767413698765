export enum ModalType {
  CONFIRM,
  INFO,
  ADD_MEMBER,
  ADD_MEMBERS,
  REMOVE_MEMBER,

  LEAVE_CHAT,
  LEAVE_GROUP,
  LEAVE_CHANNEL,

  DELETE_CHAT_MESSAGE,
  DELETE_CHAT_HISTORY,
  DELETE_GROUP_HISTORY,
  DELETE_CHANNEL_HISTORY,

  DELETE_CHAT_HISTORY_AND_LEAVE,
  DELETE_GROUP_HISTORY_AND_LEAVE,
  DELETE_CHANNEL_HISTORY_AND_LEAVE,

  FORWARD,
  PIN_MESSAGE,
  UNPIN_MESSAGE,
  UNPIN_ALL_MESSAGES,
  JUMP_TO_DATE,

  NEW_CONTACT,

  UPLOAD_PHOTO_VIDEO,
  UPLOAD_FILE,
  UPLOAD_AVATAR,
  UPLOAD_PHOTO_VIDEO_ASYNC,
  UPLOAD_FILE_ASYNC,

  ANIMATION_LIGHT_BOX,
  MEDIA_LIGHT_BOX,

  CREATE_SNIPPET,
  EDIT_SNIPPET,
  DELETE_SNIPPET,
  MESSAGE_TAG_CREATING,

  CHANNEL_CREATION,
  ACCOUNT_CREATION_TELEGRAM_BOT,
  ACCOUNT_CREATION_SIGNAL,
  ACCOUNT_CREATION_WEB_WIDGET,
  ACCOUNT_CREATION_INSTAGRAM,
  ACCOUNT_BLOCKING,
  CHANNEL_DELETING,
  CHANNEL_REACTIVATION,

  MEMBER_BLOCKING,
  MEMBER_DELETING,
  MEMBER_INVITATION_TO_CHAT,
  MEMBER_ACCESS_ADD_CHAT,
  INVITE_DELETING,

  PROFILE_CHANGE_EMAIL,
  PROFILE_VERIFICATION_EMAIL,
  PROFILE_CHANGE_PASSWORD,
  PROFILE_FORGOT_PASSWORD,
  PROFILE_FORGOT_PASSWORD_EMAILED,
  PROFILE_FORGOT_PASSWORD_CONFIRM_TO_SEND_EMAIL,
  PROFILE_FORGOT_PASSWORD_EXPIRED_LINK,
  PROFILE_RESET_PASSWORD,

  WEB_WIDGET_THEME_SETTINGS,
  WEB_WIDGET_WELCOME_MESSAGE_SETTINGS,
  WEB_WIDGET_SNIPPET_SETTINGS,

  CHAT_UNBLOCKING,
  EDIT_CHAT_INFO,
  EDIT_INTERNAL_GROUP,
  GROUP_CREATING,
  CHAT_CREATING,
  INTERNAL_CHAT_CREATING,

  INVITE_TO_CHAT_VIA_LINK,
  SHARE_INVITE_LINK,

  CREATED_INVITATION_LINK,

  FORCE_VERSION_UPDATE_APP,
  INVITE_MEMBER_BY_EMAIL,
  INVITE_MEMBER_BY_LINK,

  WORKSPACE_CREATING,

  BILLING_APPLY_TARIFF_WARNING,
  BILLING_FREE_PLAN_ACTIVATED,
  BILLING_TARIFF_PAY_WITH_MEMBERS,
}

export default ModalType;
