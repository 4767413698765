import React, {Component, ReactNode} from 'react';

interface ErrorBoundaryFallbackProps {
  onRetry?: () => void;
  error: any;
}

interface ErrorBoundaryProps {
  fallback: React.ComponentType<ErrorBoundaryFallbackProps>;
  children: ReactNode;
  onRetry?: () => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: any;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  handleRetry = () => {
    this.setState({hasError: false});
    this.props.onRetry?.();
  };

  render() {
    const {
      fallback,
      children,
      onRetry,
    } = this.props;

    if (this.state.hasError) {
      return React.createElement<ErrorBoundaryFallbackProps>(fallback, {
        onRetry: onRetry ? this.handleRetry : undefined,
        error: this.state.error
      });
    }

    return children;
  }
}

export default ErrorBoundary;
