import {action, makeObservable, observable} from 'mobx';

import {
  IMCChannelShownFields,
  IMCCreateChannelResponse,
  MCCreateChannel,
  MCDropChannelResponse,
  TelegramBotAuthorization,
  entities
} from '../../../../api/proto';
import Channel from '../../Channel';
import ChannelsStore from '../../ChannelsStore';

export class TelegramBot {
  constructor(public channels: ChannelsStore) {
    makeObservable(this);
  }


  @observable loading = false;
  @observable processingRequest: MCCreateChannel | null = null;

  @action protected setProcessingRequest_ = (processingRequest: MCCreateChannel | null) => {
    this.loading = !!processingRequest;
    this.processingRequest = processingRequest;
  };

  public createChannel = async (token: string, shownFields?: IMCChannelShownFields | null) => {
    if (this.loading) {
      return {error: {message: 'In process'}, res: null};
    }

    const createChannel = new MCCreateChannel({
      type: entities.OzekonChannelType.OIT_TELEGRAM_BOT,
      shownFields,
      telegramBotAuthFlow: new TelegramBotAuthorization({
        token,
      }),
    });

    this.setProcessingRequest_(createChannel);
    const {error, res} = await this.channels.channelsRequest<IMCCreateChannelResponse>(
      {
        createChannel,
      },
      'createChannel',
    );
    this.setProcessingRequest_(null);

    if (error) {
      return {error, res};
    }

    let channel: Channel | null = null;
    if (res) {
      channel = new Channel(
        {
          channelID: res?.channelID,
          shownFields,
          type: entities.OzekonChannelType.OIT_TELEGRAM_BOT,
          workspaceID: this.channels.workspace.id,
        },
        this.channels.workspace,
      );
      this.channels.workspace.app.anal.channelSetupEvent(channel);
    }

    this.processCreateChannel_(res);

    return {error, res, channel};
  };

  private processCreateChannel_ = async (createChannel?: IMCCreateChannelResponse | null) => {
    if (createChannel?.result !== MCDropChannelResponse.Result.R_INTERNAL_SERVER_ERROR) {
      await this.channels.load();
      await this.channels.workspace.refreshMembers();
    }
  };
}

export default TelegramBot;