import Long from 'long';

import {IMCMessage, IMCMessagePreview, IMCPeerPreview, IMCServiceMessage, MCMessageAction} from '../../api/proto';
import {t} from '../../i18n';
import Message from './Message';
import MessagePreview from './MessagePreview';

export const getPeerName = (peer?: IMCPeerPreview | null): string => {
  return peer?.name || peer?.id?.toString() || '';
};

export const getMessageServiceText = (serviceMessage: IMCServiceMessage, message: Message | MessagePreview): string => {
  const messagePeer = message.peer;
  const messagePeerName = getPeerName(messagePeer);

  const findOperator = (operatorID?: Long | null) => {
    return message.chat.store.channel.workspace.findMemberByUserId(operatorID);
  };

  if (serviceMessage?.createChat) {
    return serviceMessage?.createChat?.title
      ? t('service_message_created_chat_with_title', {
          peer: messagePeerName,
          title: serviceMessage?.createChat?.title,
        })
      : t('service_message_created_chat', {
          peer: messagePeerName,
        });
  }

  if (serviceMessage?.addPeer) {
    return serviceMessage?.addPeer?.peers
      ? serviceMessage?.addPeer?.peers
          ?.map((p) => {
            return p.id && messagePeer?.id?.equals(p.id)
              ? t('service_message_add_peer_by_invite_link', {
                  peer: getPeerName(p),
                })
              : t('service_message_add_peer', {
                  peer: messagePeerName,
                  addedPeer: getPeerName(p),
                });
          })
          .join(' ')
      : t('service_message_add_peer_by_invite_link', {
          peer: messagePeerName,
        });
  }

  if (serviceMessage?.editChat?.title) {
    return t('service_message_changed_group_name', {
      peer: messagePeerName,
      name: serviceMessage?.editChat?.title,
    });
  }

  if (serviceMessage?.editChat?.avatar) {
    return t('service_message_changed_group_photo', {
      peer: messagePeerName,
    });
  }

  if (serviceMessage?.removePeer) {
    if (serviceMessage?.removePeer?.peers) {
      return serviceMessage?.removePeer?.peers
        ?.map((p) => {
          console.debug(`--->getMessageServiceText `, messagePeer, p);
          if (messagePeer?.id && p.id?.equals(messagePeer.id)) {
            return t('service_message_peer_left_group', {
              peer: getPeerName(p),
            });
          }

          return t('service_message_removed_peer', {
            peer: messagePeerName,
            removedPeer: getPeerName(p),
          });
        })
        .join(' | ');
    }

    return t('service_message_peer_left_group', {
      peer: messagePeerName,
    });
  }

  if (serviceMessage?.pinMessage) {
    return t('service_message_pinned_message', {
      peer: messagePeerName,
      message: serviceMessage?.pinMessage?.messageID?.toString(),
    });
  }

  if (serviceMessage?.migratedFromSuperGroup?.migrated) {
    return t('service_message_updated_to_super_group');
  }

  if (serviceMessage?.widgetUserConnected?.connected) {
    return t('service_message_widget_user_connected');
  }

  if (serviceMessage?.widgetUserDisconnected?.disconnected) {
    return t('service_message_widget_user_disconnected');
  }

  if (serviceMessage?.widgetOperatorJoined) {
    return t('service_message_widget_operator_joined', {
      name: findOperator(serviceMessage?.widgetOperatorJoined.operatorID)?.fullName,
    });
  }

  if (serviceMessage?.widgetOperatorLeft) {
    return t('service_message_widget_operator_left', {
      name: findOperator(serviceMessage?.widgetOperatorLeft.operatorID)?.fullName,
    });
  }

  if (serviceMessage?.widgetResolved) {
    return t('service_message_widget_operator_resolved', {
      name: findOperator(serviceMessage?.widgetResolved.operatorID)?.fullName,
    });
  }

  if (serviceMessage?.widgetReopen?.reopen) {
    return t('service_message_widget_operator_reopen');
  }

  if (serviceMessage.legacy?.text) {
    return serviceMessage.legacy?.text;
  }

  return 'Unknown system message';
};

export const messageIsEdited = (message: IMCMessage): boolean => {
  return !!message.actions?.some((a) => a.type === MCMessageAction.Type.EDIT);
};

export const messageIsDeleted = (message: IMCMessage): boolean => {
  return !!message.actions?.some((a) => a.type === MCMessageAction.Type.DELETE);
};

export const messageDeletedAt = (message: IMCMessage): Long | null => {
  return message.actions?.find((a) => a.type === MCMessageAction.Type.DELETE)?.timestamp || null;
};

export const convertToMessagePreview = (message: IMCMessage): IMCMessagePreview => {
  return {
    localID: message.localID,
    peer: message.peer,
    text: message.text,
    operatorID: message.operatorID,
    attachments: message.attachments,
    serviceMessage: message.serviceMessage,
  };
};

export const convertToMessage = (messagePreview: IMCMessagePreview, chatID?: Long | null): IMCMessage => {
  return {
    localID: messagePreview.localID,
    peer: messagePreview.peer,
    text: messagePreview.text,
    operatorID: messagePreview.operatorID,
    attachments: messagePreview.attachments,
    serviceMessage: messagePreview.serviceMessage,
    chatID,
  };
};
