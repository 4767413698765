import {matchPath} from 'react-router';
import Paths from '../../../routes/Paths';
import SettingsTab from '../SettingsTab';

export function getSettingsTabByPath(path: string): SettingsTab | null {
  const dict = {
    [Paths.PersonalInfo]: SettingsTab.PERSONAL_INFO,
    [Paths.Channels]: SettingsTab.CHANNELS,
    [Paths.WorkspaceMembers]: SettingsTab.WORKSPACE_MEMBERS,
    [Paths.Snippets]: SettingsTab.SNIPPETS,
    [Paths.WorkspaceSettings]: SettingsTab.WORKSPACE_SETTINGS,
    [Paths.Billing]: SettingsTab.BILLING,
    [Paths.BillingPayCancel]: SettingsTab.BILLING,
    [Paths.BillingPaySuccess]: SettingsTab.BILLING,
    [Paths.BillingTariff]: SettingsTab.BILLING,
    [Paths.Tags]: SettingsTab.MESSAGE_TAGS,
  };

  for (const settingPath of Object.keys(dict)) {
    if (matchPath(settingPath, path)) {
      return dict[settingPath];
    }
  }

  return null;
}

export default getSettingsTabByPath;