import Long from 'long';
import {action, configure, makeAutoObservable, observable, reaction} from 'mobx';

import Network, {IConnectionOpenEvent, NetworkEvent} from '../api/network';
import Analytics from './Analytics/Analytics';
import AppVersionUpdater from './AppVersionUpdater';
import ChatsView from './ChatsView';
import ContextMenuStore from './ContextMenu/ContextMenuStore';
import ExternalApiCallHandler from './ExternalApp/ExternalApiCallHandler';
import ExternalAppStore from './ExternalApp/ExternalAppStore';
import FeaturesStore from './FeaturesStore';
import LayOutStore from './LayOutStore';
import ModalsStore from './ModalsStore';
import NotificationsStore from './NotificationsStore';
import PageStore from './PageStore';
import RoutingHistoryStore from './RoutingHistory';
import UploadClient from './UploadClient';
import GreetingsStore from './User/GreetingsStore';
import UserStore from './User/UserStore';
import WorkspacesStore from './Workspaces/WorkspacesStore';

export * from './useStore';

configure({
  enforceActions: 'observed',
  disableErrorBoundaries: true,
});

export class AppStore {
  version = process.env.REACT_APP_VERSION;
  forceUpdateVersion = process.env.REACT_APP_FORCE_UPDATE_VERSION;

  externalApp = new ExternalAppStore();

  externalApiCallHandler: ExternalApiCallHandler = new ExternalApiCallHandler(this);

  @observable animationsEnabled = true;

  api: Network = new Network(this);
  appVersionUpdater: AppVersionUpdater = new AppVersionUpdater(this);
  userStore: UserStore = new UserStore(this);
  protected greetings: GreetingsStore = new GreetingsStore(this);
  public features: FeaturesStore = new FeaturesStore(this);

  uploader: UploadClient = new UploadClient(this);

  page: PageStore = new PageStore(this);
  routingHistory: RoutingHistoryStore = new RoutingHistoryStore(this);

  layOutStore: LayOutStore = new LayOutStore(this);
  notifications: NotificationsStore = new NotificationsStore(this);
  modals: ModalsStore = new ModalsStore(this);
  contextMenu: ContextMenuStore = new ContextMenuStore(this);
  workspaces: WorkspacesStore = new WorkspacesStore(this);

  anal: Analytics = new Analytics(this);

  get channels() {
    return this.activeWorkspace?.channels;
  }

  constructor() {
    makeAutoObservable(this);

    this.api.on(NetworkEvent.CONNECTION_OPEN, this.onWsOpen);

    reaction(
      () => this.activeWorkspace.channels.allChannelsUnreadCount,
      (totalUnreadCount) => {
        this.externalApiCallHandler.updateUnreadCounter(totalUnreadCount);
      },
    );
  }

  get isInit(): boolean {
    return true;
    // return (
    //   this.authStore.isInit && this.settings.isInit && this.channels.isInit && this.channels.availableChannelsIsInit
    // );
  }

  get activeWorkspace() {
    return this.workspaces.currentWorkspace;
  }

  get hasUnread() {
    return !!this.activeWorkspace.channels.allChannelsUnreadCount;
  }

  get chatsView(): ChatsView {
    return this.activeWorkspace.chatsView;
  }

  get lightThemeChosen(): boolean {
    return this.layOutStore.theme.lightThemeChosen;
  }

  onWsOpen = (e: IConnectionOpenEvent) => {
    console.debug('*** -->AppStore:onWsOpen', e);
    this.reinitAppOnWsOpen(e);
  };

  reinitAppOnWsOpen = async (e: IConnectionOpenEvent) => {
    if (!e.initial) {
      await this.workspaces.load();
    }
  };

  @observable initialSelections: InitialSelectedParams | null = null;

  @action setInitialSelections = (params: InitialSelectedParams) => {
    this.initialSelections = {...params};
  };

  @action reset = () => {
    this.initialSelections = null;
    this.workspaces.reset();
    this.userStore.reset();
    this.chatsView.reset();
    this.layOutStore.reset();
  };
}

export interface InitialSelectedParams {
  workspaceId?: string;
  channelId?: string;
  chatId?: string;
  userId?: string;
  messageId?: string;
}

const store = new AppStore();

window.appStore = store;
window.Long = Long;

export default store;
