import Long from 'long';

import EventEmitter from '../../api/EventEmitter';
import {
  api,
  MCMethod,
  MCMethodGetMessages,
} from '../../api/proto';
import {CHAT_MESSAGES_PAGE_SIZE} from '../../constants';
import {swapElements} from '../../utils/arrayUtils';
import {
  getOffsetRange,
  PagingOffset,
  PagingOffsetType,
  SliceRange,
} from '../RawChatsStore/pagingUtils';
import WorkspaceStore from '../Workspaces/WorkspaceStore';
import {IRawMessagesStore, RawMessage, RawMessagesData} from './IRawMessagesStore';
import messagesProcessor from './messagesProcessor';
import RawMessagesStoreEvent from './RawMessagesStoreEvent';


export class RawMessagesCore extends EventEmitter implements IRawMessagesStore {
  protected isInit_: boolean = false;
  protected partialCollection_: boolean = false;
  protected rawMessages_: RawMessage[] = [];
  private messageIdNumbersDict_: {[id: string]: boolean} = {};

  protected get sendingMessages(): RawMessage[] {
    return this.rawMessages_.filter(({sending}) => sending);
  }

  protected isSending = (id?: Long | null): boolean => {
    return !!id && this.sendingMessages.some(({localID}) => localID?.equals(id));
  };

  protected get firstLoadedMessageID(): Long | null {
    return this.rawMessages_.length > 0 ? (this.rawMessages_[0].localID || null) : null;
  }

  protected get lastLoadedMessageID(): Long | null {
    return this.rawMessages_.length > 0 ? (this.rawMessages_[this.rawMessages_.length - 1].localID || null) : null;
  }

  protected get inOnePageToEnd(): boolean {
    if (
      this.lastMessageId &&
      this.lastLoadedMessageID?.lessThan(this.lastMessageId) &&
      this.lastMessageId.subtract(this.lastLoadedMessageID).toNumber() <= CHAT_MESSAGES_PAGE_SIZE
    ) {
      return true;
    }
    return false;
  }

  public clear = () => {
    console.debug(`%c----->RawMessagesCore->clear chatID=${this.chatID?.toString()} `, 'color: orange');
    this.isInit_ = false;
    this.disablePartialMode();
    this.rawMessages_ = [];
    this.messageIdNumbersDict_ = {};
    this.emit(RawMessagesStoreEvent.CLEAR, this);
  };

  protected has = (id?: Long | null): boolean => {
    return !!id && this.messageIdNumbersDict_[id.toString()];
  };

  protected hasByRandomID = (randomID?: Long | null): boolean => {
    return !!randomID?.greaterThan(Long.ZERO) && this.messageIdNumbersDict_[randomID.toString()];
  };

  protected enablePartialMode = () => {
    console.debug(`%c----->RawMessagesCore partial mode enabled!`, 'color: red');
    this.partialCollection_ = true;
  };

  protected disablePartialMode = () => {
    console.debug(`%c----->RawMessagesCore partial mode disabled!`, 'color: orange');
    this.partialCollection_ = false;
  };

  constructor(
    protected workspace: WorkspaceStore,
    protected channelID: Uint8Array,
    protected chatID: Long,
    protected lastMessageId?: Long | null,
  ) {
    super();
  }

  public find = (messageID?: Long | null) => {
    return this.rawMessages_.find(({localID}) => messageID && localID?.equals(messageID)) || null;
  };

  private findIndex_ = (messageID?: Long | null) => {
    return messageID ? this.rawMessages_.findIndex(({localID}) => localID?.equals(messageID)) : -1;
  };

  protected isOutgoing = (rawMessage: RawMessage): boolean => {
    return !!(rawMessage.operatorID && this.workspace.app.userStore.user?.userId?.equals(rawMessage.operatorID));
  };

  public load = async (offsetType: PagingOffsetType = PagingOffsetType.FIRST_PAGE, fromMessageID?: Long | null): Promise<RawMessagesData> => {
    console.debug(`%c----->RawMessagesCore->load fromMessageID=${fromMessageID?.toString()} offsetType=${offsetType} lastMessageId=${this.lastMessageId?.toString()}`, 'color: blue');
    const {targetMessageID, limit} = this.calcTargetMessageID_(offsetType, fromMessageID);

    if (limit.equals(Long.ZERO)) {
      return {
        rawMessages: [],
        offsetType,
      };
    }


    if ((offsetType === PagingOffsetType.FIRST_PAGE || offsetType === PagingOffsetType.NEXT_PAGE) && this.inOnePageToEnd) {
      await this.loadInsufficientPage();
    }

    const offsetRange = getOffsetRange(offsetType, CHAT_MESSAGES_PAGE_SIZE);
    console.debug(`%c----->RawMessagesCore->load fromMessageID=${fromMessageID?.toString()} offsetType=${offsetType} offsetRange=`, 'color: gray', offsetRange);

    // const foundRawMessages = this.getSlice_(fromMessageID, limit);

    const foundRawMessages = this.getSlice_(fromMessageID || targetMessageID, offsetRange);

    if (foundRawMessages) {
      console.debug(`%c----->RawMessagesCore->load fromMessageID=${fromMessageID?.toString()} offsetType=${offsetType} lastMessageId=${this.lastMessageId?.toString()} foundRawMessages=`, 'color: blue', foundRawMessages.map((c) => c.localID?.toNumber()), offsetRange);
      return {
        rawMessages: foundRawMessages,
        offsetType,
      };
    }

    const {res} = await this.getRemoteData_(targetMessageID, limit);
    const rawMessages = res?.methodResponse?.getMessages?.messages || [];

    this.prepareCollectionState_(rawMessages, offsetRange.offsetType);
    this.isInit_ = true;

    const msg = rawMessages.find((m) => fromMessageID && m.localID?.equals(targetMessageID));
    console.debug(
      `%c----->RawMessagesCore->getRemoteData_ result (message found - ${!!msg})
        targetMessageID=${targetMessageID.toString()}
        fromMessageID=${fromMessageID?.toString()}
        lastMessageId=${this.lastMessageId?.toString()}
        offsetType=${offsetRange.offsetType}
      `,
      msg ? 'color: green' : 'color: red',
      rawMessages.map((m) => m.localID?.toNumber()),
      msg
    );

    const processedMessages = this.processRemoteData_(rawMessages, offsetRange.offsetType);

    return {
      rawMessages: processedMessages,
      offsetType,
    };
  };

  private loadInsufficientPage = async () => {
    if (!this.inOnePageToEnd || !this.lastMessageId || !this.lastLoadedMessageID) {
      return;
    }

    const {res} = await this.getRemoteData_(this.lastMessageId, this.lastMessageId.subtract(this.lastLoadedMessageID));
    const rawMessages = res?.methodResponse?.getMessages?.messages || [];

    this.prepareCollectionState_(rawMessages, PagingOffsetType.NEXT_PAGE);

    console.debug(`%c----->RawMessagesCore->loadInsufficientPage`, 'color: orange', rawMessages.map((m) => m.localID?.toNumber()));

    this.processRemoteData_(rawMessages, PagingOffsetType.NEXT_PAGE);
  };

  private calcTargetMessageID_ = (offsetType: PagingOffsetType, fromMessageID?: Long | null): {targetMessageID: Long; limit: Long} => {
    console.debug(`%c----->RawMessagesCore->calcTargetMessageID_ 0 ${offsetType} fromMessageID=${fromMessageID?.toString()}`, 'color: orange');

    if (this.lastMessageId?.lessThanOrEqual(CHAT_MESSAGES_PAGE_SIZE)) {
      return offsetType === PagingOffsetType.PREV_PAGE ? {
        targetMessageID: fromMessageID || Long.ZERO,
        limit: fromMessageID || Long.ZERO,
      } : {
        targetMessageID: this.lastMessageId,
        limit: this.lastMessageId,
      };
    }

    let limit = Long.fromNumber(CHAT_MESSAGES_PAGE_SIZE);
    let targetMessageID: Long = fromMessageID || this.lastMessageId || Long.ZERO;

    if (fromMessageID && offsetType === PagingOffsetType.MIDDLE_PAGE && !this.lastMessageId?.equals(fromMessageID)) {
      targetMessageID = fromMessageID.add(CHAT_MESSAGES_PAGE_SIZE / 2);
      console.debug(`%c----->RawMessagesCore->calcTargetMessageID_ 1 ${offsetType} fromMessageID=${fromMessageID?.toString()} fromMessageID=${targetMessageID?.toString()}`, 'color: orange');
    } else if (offsetType === PagingOffsetType.NEXT_PAGE) {
      targetMessageID = fromMessageID && !this.lastMessageId?.equals(fromMessageID) ? fromMessageID.add(CHAT_MESSAGES_PAGE_SIZE) : limit;
      console.debug(`%c----->RawMessagesCore->calcTargetMessageID_ 2 ${offsetType} fromMessageID=${fromMessageID?.toString()} fromMessageID=${targetMessageID?.toString()}`, 'color: orange');
    }

    if (this.lastMessageId?.lessThan(targetMessageID)) {
      targetMessageID = this.lastMessageId;
      console.debug(`%c----->RawMessagesCore->calcTargetMessageID_ 3 ${offsetType} fromMessageID=${fromMessageID?.toString()} fromMessageID=${targetMessageID?.toString()}`, 'color: orange');
      if (this.lastMessageId.lessThan(limit)) {
        limit = this.lastMessageId.subtract(fromMessageID || Long.ZERO);
        console.debug(`%c----->RawMessagesCore->calcTargetMessageID_ 4 ${offsetType} fromMessageID=${fromMessageID?.toString()} fromMessageID=${targetMessageID?.toString()}`, 'color: orange');
      }
    }

    return {
      targetMessageID: targetMessageID,
      limit,
    };
  };

  private getSlice_ = (id?: Long | null, offsetRange?: PagingOffset): RawMessage[] | null => {
    console.debug(`%c----->RawMessagesCore->getSlice_ startMessageID=${id?.toNumber()} offsetBefore=${offsetRange?.offsetBefore?.toNumber()} offsetAfter=${offsetRange?.offsetAfter?.toNumber()}`, 'color: green', offsetRange);
    if (!this.rawMessages_.length) {
      return null;
    }

    if (!id) {
      return this.rawMessages_.slice(0, offsetRange?.offsetBefore?.toNumber() || CHAT_MESSAGES_PAGE_SIZE);
    }

    const index = this.findIndex_(id);
    if (index === -1) {
      return null;
    }

    const range = this.getSliceRange_(
      index,
      offsetRange?.offsetBefore,
      offsetRange?.offsetAfter,
      CHAT_MESSAGES_PAGE_SIZE
    );
    console.debug(`%c----->RawMessagesCore->getSlice_ index=${index} offsetBefore=${offsetRange?.offsetBefore} offsetAfter=${offsetRange?.offsetAfter} range=`, 'color: blue', range);

    const foundMessages: RawMessage[] | null = this.rawMessages_.slice(range.start, range.end);
    console.debug(`%c----->RawChatsStore->getSlice_ result`, 'color: red', foundMessages.map((c) => c.localID?.toNumber()));

    return foundMessages?.length ? foundMessages : null;
  };

  private getSliceRange_ = (index: number, offsetBefore?: Long, offsetAfter?: Long, pageSize: number = CHAT_MESSAGES_PAGE_SIZE): SliceRange => {
    if (offsetBefore && offsetAfter) {
      let start = index - offsetAfter.toNumber();
      start = start < 0 ? 0 : start;
      const end = index + offsetBefore.toNumber();
      return {start, end};
    } else if (offsetBefore) {
      let start = index - offsetBefore.toNumber();
      start = start < 0 ? 0 : start;
      const end = index + 1;
      return {start, end};
    } else if (offsetAfter) {
      const start = index + 1;
      const end = index + 1 + offsetAfter.toNumber();
      return {start, end};
    }
    return {start: index + 1, end: index + pageSize};
  };

  private getRemoteData_ = async (fromMessageID?: Long | null, limit?: Long) => {
    console.debug(`%c----->RawMessagesCore->getRemoteData_ get ${limit?.toString()} messages fromMessageID=${fromMessageID?.toString()}`, 'color: blue');
    return await this.workspace.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.workspace.id,
          action: new MCMethod({
            getMessages: new MCMethodGetMessages({
              channelID: this.channelID,
              chatID: this.chatID,
              fromMessageID,
              limit,
            }),
          }),
        }),
      },
      'channelsResponse',
    );
  };

  private prepareCollectionState_ = (rawMessages: RawMessage[], offsetType: PagingOffsetType) => {
    const isLastPage = this.partialCollection_ && this.checkForLastPage_(rawMessages);

    if (!isLastPage && offsetType === PagingOffsetType.FIRST_PAGE) {
      this.clear();
    } else if (isLastPage) {
      this.disablePartialMode();
    } else if (offsetType === PagingOffsetType.MIDDLE_PAGE && !rawMessages.some((m) => this.lastMessageId && m.localID?.equals(this.lastMessageId))) {
      this.clear();
      this.enablePartialMode();
    }
  };

  private checkForLastPage_ = (rawMessages: RawMessage[]): boolean => {
    if (!this.lastLoadedMessageID) {
      return false;
    }

    if (
      rawMessages.some((m) => this.lastMessageId && m.localID?.equals(this.lastMessageId)) &&
      rawMessages.some((m) => this.lastLoadedMessageID && m.localID?.equals(this.lastLoadedMessageID.add(1)))
    ) {
      return true;
    }

    return false;
  };

  private processRemoteData_ = (rawMessages: RawMessage[], offsetType: PagingOffsetType) => {
    if (!rawMessages.length) {
      return [];
    }

    const newMessages: RawMessage[] = [];
    rawMessages.forEach((raw) => {
      if (!raw.localID) {
        console.debug(`%c----->RawMessagesCore->processRemoteData_ null id!`, 'color: red', raw);
      } else if (!this.has(raw.localID)) {
        newMessages.unshift(raw);
        this.messageIdNumbersDict_[raw.localID?.toString()] = true;
      } else {
        console.debug(`%c----->RawMessagesCore->processRemoteData_ dup entry!`, 'color: red', raw.localID?.toString());
      }
    });

    if (!newMessages.length) {
      return [];
    }

    console.debug(`%c----->RawMessagesCore->processRemoteData_ merge`, 'color: blue', this.rawMessages_.map((c) => c.localID?.toNumber()), ' and newMessages=', newMessages.map((c) => c.localID?.toNumber()));
    this.setNewMessages_(this.rawMessages_, newMessages, offsetType);
    console.debug(`%c----->RawMessagesCore->processRemoteData_ merge result`, 'color: blue', this.rawMessages_.map((c) => c.localID?.toNumber()));

    return newMessages;
  };

  private setNewMessages_ = (targetArray: RawMessage[], newArray: RawMessage[], offsetType?: PagingOffsetType) => {
    const newMessages = messagesProcessor(newArray);

    if (!newMessages.length) {
      this.rawMessages_ = targetArray;
      return;
    }

    if (!targetArray.length) {
      this.rawMessages_ = newMessages;
      return;
    }

    if (offsetType === PagingOffsetType.PREV_PAGE) {
      this.rawMessages_ = [...newMessages, ...targetArray];
      return;
    } else if (offsetType === PagingOffsetType.NEXT_PAGE) {
      this.rawMessages_ = [...targetArray, ...newMessages];
      return;
    }

    this.clear();
    this.rawMessages_ = newArray;
  };

  protected addMessage_ = (newRawMessage: RawMessage, randomID?: Long | null, outgoing?: boolean) => {
    console.debug(`%c----->RawMessagesCore->addMessage_`, 'color: green', newRawMessage, randomID?.toString(), newRawMessage.text);
    if (newRawMessage.localID) {
      this.lastMessageId = newRawMessage.localID;

      if (this.partialCollection_) {
        console.debug(`%c----->RawMessagesCore->addMessage_ partialCollection mode - message declined! ${this.chatID?.toString()}`, 'color: red', newRawMessage, randomID, outgoing);
        return;
      }

      this.addMessageToRawList_(newRawMessage);
      this.messageIdNumbersDict_[newRawMessage.localID.toString()] = true;

      if (randomID) {
        this.messageIdNumbersDict_[randomID.toString()] = true;
      }

      this.emit(RawMessagesStoreEvent.ADD_NEW_MESSAGE, newRawMessage, randomID);

      if (outgoing || this.isOutgoing(newRawMessage)) {
        this.workspace.updater.processOutgoingMessage(newRawMessage, this.channelID);
      }
    }
  };

  private addMessageToRawList_ = (rawMessage: RawMessage) => {
    this.rawMessages_ = this.rawMessages_.concat(messagesProcessor([rawMessage]));
    console.debug(`%c----->RawMessagesCore->addMessageToRawList_`, 'color: blue', this.rawMessages_.map((c) => c.localID?.toNumber()));
  };

  private swapMessages_ = (index1: number, index2: number) => {
    if (index1 < 0 || index2 < 0) {
      return;
    }

    this.rawMessages_ = swapElements<RawMessage>(this.rawMessages_, index1, index2);
    this.rawMessages_[index1].swapped = true;
    this.rawMessages_[index2].swapped = true;
    console.debug(`%c----->RawMessagesCore->swapMessages_ `, 'color: red', ' ', this.rawMessages_[index1].text, ' and ', this.rawMessages_[index2].text, this.rawMessages_[index1], this.rawMessages_[index2]);
  };

  protected updateMessage_ = (rawMessage: RawMessage, randomID?: Long | null) => {
    const hasRandomId = !!randomID?.greaterThan(0);

    console.debug(`%c----->RawMessagesCore->updateMessage_`, 'color: green', rawMessage, randomID, ' new text=', rawMessage.text);

    let idx = -1;

    if (randomID?.greaterThan(0)) {
      idx = this.rawMessages_.findIndex((raw) => raw.randomID && rawMessage.randomID?.equals(raw.randomID));
    } else {
      idx = this.rawMessages_.findIndex((raw) => raw.localID && rawMessage.localID?.equals(raw.localID));
    }

    if (idx >= 0) {
      const currentMessage = this.rawMessages_[idx];
      const updatedMessage = {...currentMessage, ...rawMessage, sending: rawMessage.sending || false};

      const needSwap = hasRandomId && !updatedMessage.swapped && updatedMessage.localID && currentMessage?.localID?.notEquals(updatedMessage.localID);
      const newIndex = needSwap ? this.rawMessages_.findIndex((raw) => raw.localID && updatedMessage.localID?.equals(raw.localID)) : -1;

      this.rawMessages_[idx] = updatedMessage;
      this.swapMessages_(idx, newIndex);

      this.emit(RawMessagesStoreEvent.UPDATE_MESSAGE, updatedMessage, randomID);
    } else if (!this.has(randomID) && !this.has(rawMessage.localID)) {
      console.debug(`%c----->RawMessagesCore->updateMessage_ message not found!`, 'color: red', rawMessage, randomID?.toString(), ' new text=', rawMessage.text);
      this.addMessage_(rawMessage, randomID);
    }
  };


  public setMessageError = (newRawMessage: RawMessage, sendingError?: string | null) => {
    this.updateMessage_({...newRawMessage, sending: false, sendingError}, newRawMessage.randomID);
  };

  public removeMessage = (localID?: Long | null, randomID?: Long | null) => {
    console.debug(`%c----->RawMessagesCore->removeMessage_`, 'color: red', localID, randomID);

    const idx = randomID?.greaterThan(0) ? this.rawMessages_.findIndex((raw) => raw.randomID?.equals(randomID)) :
      this.rawMessages_.findIndex((raw) => raw.localID && localID?.equals(raw.localID));

    if (idx >= 0) {
      const rawMessage = this.rawMessages_[idx];

      this.rawMessages_.splice(idx, 1);

      const randomID_ = randomID?.equals(0) ? randomID : rawMessage.randomID;

      if (randomID_?.greaterThan(0) && this.messageIdNumbersDict_[randomID_.toString()]) {
        delete this.messageIdNumbersDict_[randomID_.toString()];
      }


      this.emit(RawMessagesStoreEvent.REMOVE_MESSAGE, rawMessage, randomID);
    }
  };
}

export default RawMessagesCore;
