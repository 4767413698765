import {
  TelegramBotChannelState,
  TelegramChannelState,
  entities,
} from '../../../api/proto';
import Channel from '../Channel';
import getInstagramChannelStateTitle from '../platforms/Instagram/getInstagramChannelStateTitle';
import getTelegramStateTitle from '../platforms/Telegram/getTelegramStateTitle';
import getTelegramBotStateTitle from '../platforms/TelegramBot/getTelegramBotStateTitle';
import getWebWidgetStateTitle from '../platforms/WebWidget/getWebWidgetStateTitle';

export const getChannelStateTitle = ({state, type}: Channel) => {
  switch (type) {
    case entities.OzekonChannelType.OIT_TELEGRAM:
      return getTelegramStateTitle(state?.telegram?.state);
    case entities.OzekonChannelType.OIT_WEB_WIDGET:
      return getWebWidgetStateTitle(state?.webWidget?.state);
    case entities.OzekonChannelType.OIT_INSTAGRAM:
      return getInstagramChannelStateTitle(state?.instagram?.state);
    case entities.OzekonChannelType.OIT_TELEGRAM_BOT:
      return getTelegramBotStateTitle(state?.telegramBot?.state);
    case entities.OzekonChannelType.OIT_NONE:
      return null;

    default:
      return null;
  }
};

export const filterAvailableChannel = ({state, type}: Channel): boolean => {
  if (type === entities.OzekonChannelType.OIT_TELEGRAM) {
    return state?.telegram?.state === TelegramChannelState.State.EVENT_LISTENING;
  } else if (type === entities.OzekonChannelType.OIT_TELEGRAM_BOT) {
    return state?.telegramBot?.state === TelegramBotChannelState.State.EVENT_LISTENING;
  }

  return true;
};

export function filterFaultyChannel({state, type}: Channel): boolean {
  if (type === entities.OzekonChannelType.OIT_TELEGRAM) {
    return state?.telegram?.state === TelegramChannelState.State.UNAUTHORIZED;
  } else if (type === entities.OzekonChannelType.OIT_TELEGRAM_BOT) {
    return state?.telegramBot?.state === TelegramBotChannelState.State.ERROR_CHANNEL;
  }

  return false;
}
