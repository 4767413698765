import {TelegramChannelState} from '../../../../api/proto';
import i18n from '../../../../i18n';


export default function getTelegramStateTitle(state?: TelegramChannelState.State | null): string {
  switch (state) {
    case TelegramChannelState.State.CREATE_CHANNEL:
    case TelegramChannelState.State.ENTER_CODE:
    case TelegramChannelState.State.ENTER_NUMBER:
    case TelegramChannelState.State.ENTER_PASSWORD:
      return i18n.t('settings_channel_manage_status_authorization');
    case TelegramChannelState.State.EVENT_LISTENING:
      return i18n.t('settings_channel_manage_status_launch');
    case TelegramChannelState.State.UNAUTHORIZED:
      return i18n.t('settings_channel_manage_status_unauthorized');
    default:
      return '-';
  }
}