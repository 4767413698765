import Long from 'long';

import {
  api,
  MCMessagePreview,
  MCMethod,
  MCMethodMarkRead,
  MCMethodMarkReadResponse,
} from '../../api/proto';
import Chat from './Chat';
import {t} from '../../i18n';
import Message from '../Message';



const getErrorByMarkReadResult = (type?: MCMethodMarkReadResponse.Result | null): string | null => {
  switch (type) {
    case MCMethodMarkReadResponse.Result.R_MessageNotExist:
      return t('Message not exist');
    case MCMethodMarkReadResponse.Result.R_INTERNAL_SERVER_ERROR:
      return t('Internal server error');
  }

  if (type && MCMethodMarkReadResponse.Result[type]) {
    return MCMethodMarkReadResponse.Result[type]?.toLowerCase().replace(/_/g, ' ');
  }

  return null;
};

const READ_MESSAGE_TIMEOUT = 400;

export interface IReadMessagesQueue {
  readMessages: (messages: (MCMessagePreview | Message)[], readImmediately?: boolean) => void;
}

export class ReadMessagesQueue implements IReadMessagesQueue {
  constructor(public chat: Chat) { }

  private readMessageInQ: Long | null = null;

  private sendReadTimeout: NodeJS.Timeout | null = null;

  private findMaxMessage = (messages: (MCMessagePreview | Message)[]): Long | null => {
    let maxMessage: Long | null = null;
    messages.forEach((message) => {
      if (!maxMessage || message.localID.greaterThan(maxMessage)) {
        maxMessage = message.localID;
      }
    });
    return maxMessage;
  };

  private addMessageToReadQueue = (messages: (MCMessagePreview | Message)[], readImmediately?: boolean) => {
    const maxMessageId: Long | null = this.findMaxMessage(messages);

    console.debug(`%c******addMessageToReadQueue maxMessageId=${maxMessageId?.toString()}`, 'color: orange');
    if (!this.readMessageInQ || maxMessageId?.greaterThan(this.readMessageInQ)) {
      console.debug(`%c******addMessageToReadQueue +`, 'color: orange');
      this.readMessageInQ = maxMessageId;
    } else {
      console.debug(`%c******addMessageToReadQueue -`, 'color: orange');
    }
    this.runSendReadTimeout(readImmediately);
  };

  resetReadQueue = () => {
    this.readMessageInQ = null;
  };

  private runSendReadTimeout = (readImmediately?: boolean) => {
    if (!this.readMessageInQ || this.sendingMessageId?.greaterThan(this.readMessageInQ)) {
      console.debug(`%c******runSendReadTimeout reject`, 'color: orange');
      return;
    }

    if (readImmediately) {
      this.sendReadRequest(this.readMessageInQ);
      return;
    }

    if (this.sendReadTimeout) {
      clearTimeout(this.sendReadTimeout);
    }

    this.sendReadTimeout = setTimeout(() => {
      if (this.readMessageInQ) {
        this.sendReadRequest(this.readMessageInQ);
      }
    }, READ_MESSAGE_TIMEOUT);
  };

  public readMessages = (messages: (MCMessagePreview | Message)[], readImmediately?: boolean) => {
    if (!this.chat.store.channel.app.page.isObserving && !readImmediately) {
      console.debug(`%c******readMessages reject`, 'color: orange');
      return;
    }

    console.debug(`%c******readMessages ${messages.map((m) => m.localID.toString()).join()}`, 'color: orange');
    this.addMessageToReadQueue(messages, readImmediately);
  };

  private sendingMessageId: Long | null = null;
  
  private sendReadRequest = async (messageID: Long) => {
    this.sendingMessageId = messageID;
    console.debug(`%c******sendReadRequest=${messageID.toString()}`, 'color: blue');
    const {error, res} = await this.chat.store.api.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.chat.channel.workspace.id,
          action: new MCMethod({
            markRead: new MCMethodMarkRead({
              channel: {channelID: this.chat.channel.id},
              chatID: this.chat.id,
              messageID,
            }),
          }),
        }),
      },
      'channelsResponse',
    );
    this.sendingMessageId = null;
    

    let errorMessage: string | null | undefined = null;

    if (res) {
      errorMessage = getErrorByMarkReadResult(res.methodResponse?.markRead?.result);
    } else if (error) {
      errorMessage = error.message;
    }

    if (errorMessage) {
      console.debug(`%c${errorMessage}`, 'color: red');
    }
    
    if (res && !errorMessage && !this.readMessageInQ?.greaterThan(messageID)) {
      this.resetReadQueue();
    }

    return {error: errorMessage ? {message: errorMessage} : null, res};
  };
}

export default ReadMessagesQueue;
