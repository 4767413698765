import {computed, makeObservable} from 'mobx';
import Chat from './Chat';

export class ChatPermissions {
  constructor(public chat: Chat) {
    makeObservable(this);
  }

  @computed get canSendMessage() {
    return (!this.chat.channel.isWebWidget || this.chat.assignment.youAreJoined) &&
      !this.chat.isChannel;
  }

  @computed get canDeleteMessage() {
    return (!this.chat.channel.isWebWidget || this.chat.assignment.youAreJoined)
      && !this.chat.isChannel
      && !this.chat.channel.isInstagram
      ;
  }
}

export default ChatPermissions;
