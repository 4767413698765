import {
  IMCChannelShownFields,
  IMCCreateChannelResponse,
  InstagramAuthorizationResponse,
  MCCreateChannelResponse,
  entities
} from '../../../api/proto';
import {randomUint8Array} from '../../../utils/arrayUtils';
import wait from '../../../utils/wait';
import Channel from '../../Channel';
import Instagram from './Instagram/Instagram';

export class InstagramEmulator extends Instagram {

  public createChannel = async (callbackCode: string, shownFields?: IMCChannelShownFields | null) => {
    console.debug(callbackCode, shownFields);
    if (this.loading) {
      return {error: {message: 'In process'}, res: null};
    }

    this.setAuthError_('');
    this.setLoading_(true);

    console.log('instagram: callbackCode before replace: ' + callbackCode);
    callbackCode = callbackCode.replace(/#_$/, '');

    const createChannelRes = new MCCreateChannelResponse({
      channelID: randomUint8Array(),
      instagramAuthFlow: {
        status: InstagramAuthorizationResponse.Status.OK,
      },
    });

    const newChannel: Channel | null = createChannelRes ?
      new Channel(
        {
          channelID: createChannelRes?.channelID,
          shownFields,
          type: entities.OzekonChannelType.OIT_INSTAGRAM,
          workspaceID: this.channels.workspace.id,
        },
        this.channels.workspace,
      ) : null;

    this.setLoading_(true);
    await wait(3000);
    this.setLoading_(false);

    this.processCreateChannel_(callbackCode, createChannelRes);

    return {error: null, res: createChannelRes, channel: newChannel};
  };

  protected processCreateChannel_ = (callbackCode: string, res?: IMCCreateChannelResponse | null) => {
    console.debug('instagram: instagramAuthFlow.status=', res?.instagramAuthFlow?.status);
    if (!res?.instagramAuthFlow?.status) {
      this.setAuthTokenSuccessful_(callbackCode);
    }
  };

}

export default InstagramEmulator;