import {APIResponse, api, entities} from './proto';
import i18n from '../i18n';


const {
  ChangeEmailResult,
  ConfirmCredsResult,
  ResendConfirmResult,
  RestorePasswordResult,
  UserLoginResult,
  ChangePasswordResult,
  ResetPasswordResult,
  UserRegisterResult,
} = entities;

const {t} = i18n;

export const getErrorByType = (type?: APIResponse.Status | null): string => {
  switch (type) {
    case APIResponse.Status.AS_INVALID_HTTP_METHOD:
      return t('api_status_invalid_http_method');
    case APIResponse.Status.AS_NON_PARSABLE_REQUEST:
      return t('api_status_non_parsable_request');
    case APIResponse.Status.AS_TRX_SHOULD_BE_UUID:
      return t('api_status_trx_should_be_uuid');
    case APIResponse.Status.AS_INTERNAL_ERROR:
      return t('api_status_internal_error');
    case APIResponse.Status.AS_PENDING_GREETINGS:
      return t('api_status_pending_greetings');
    case APIResponse.Status.AS_DIFFERENT_TOKEN_PROVIDED:
      return t('api_status_different_token_provided');
    case APIResponse.Status.AS_NOT_ALLOWED:
      return t('api_status_not_allowed');
  }

  if (type && APIResponse.Status[type]) {
    return APIResponse.Status[type]?.toLowerCase().replace(/_/g, ' ');
  }

  return '';
};

export default getErrorByType;

interface IUserResponseStatus {
  login: entities.UserLoginResult;
  register: entities.UserRegisterResult;
  changeEmail: entities.ChangeEmailResult;
  resendConfirmEmail: entities.ResendConfirmResult;
  confirmEmail: entities.ConfirmCredsResult;
  forgotPassword: entities.RestorePasswordResult;
  changePassword: entities.ChangePasswordResult;
  resetPassword: entities.ResetPasswordResult;
  changeDetails: api.ChangeDetailsResponse.Status;
}

type StatusByUserResponseType = {
  [key in keyof IUserResponseStatus]: IUserResponseStatus[key];
};

export const getUserResponseStatusError = <T extends keyof StatusByUserResponseType>(
  userResponseType: T,
  status?: StatusByUserResponseType[T] | null,
): string | null => {
  const errorsDictionary: Record<keyof IUserResponseStatus, Record<number, string>> = {
    login: {
      [UserLoginResult.LS_INVALID_CREDENTIALS]: t('api_login_status_invalid_credentials'),
      [UserLoginResult.LS_PENDING_STAMP]: t('api_login_status_pending_stamp'),
    },
    register: {
      [UserRegisterResult.RS_INVALID_EMAIL]: t('api_register_status_invalid_email'),
      [UserRegisterResult.RS_EMAIL_USED]: t('api_register_status_email_used'),
      [UserRegisterResult.RS_PASSWORD_TOO_SHORT]: t('api_register_status_password_to_short'),
      [UserRegisterResult.RS_ONLY_SPECIFIC_DOMAINS]: t('api_register_status_only_specific_domains'),
      [UserRegisterResult.RS_PASSWORDS_MISMATCH]: t('api_register_status_password_mismatch'),
      [UserRegisterResult.RS_TERMS_NOT_ACCEPTED]: t('api_register_status_terms_not_accepted'),
      [UserRegisterResult.RS_PENDING_STAMP]: t('api_register_status_pending_stamp'),
      [UserRegisterResult.RS_ALREADY_REGISTERED]: t('api_register_status_already_registered'),
      [UserRegisterResult.RS_INVALID_INVITE]: t('api_register_status_invalid_invite'),
      [UserRegisterResult.RS_UPGRADE_REQUIRED]: t('api_register_status_upgrade_required'),
      [UserRegisterResult.RS_NOT_ALLOWED]: t('api_register_status_not_allowed'),
    },
    changeEmail: {
      [ChangeEmailResult.CER_EMAIL_USED]: t('api_change_email_status_email_used'),
      [ChangeEmailResult.CER_INVALID_EMAIL]: t('api_change_email_status_invalid_email'),
      [ChangeEmailResult.CER_INVALID_PASSWORD]: t('api_change_email_status_invalid_password'),
      [ChangeEmailResult.CER_NOT_REGISTERED]: t('api_change_email_status_not_registered'),
      [ChangeEmailResult.CER_PENDING_STAMP]: t('api_change_email_status_pending_stamp'),
    },
    resendConfirmEmail: {
      [ResendConfirmResult.RCS_ALREADY_CONFIRMED]: t('api_resend_confirm_email_status_already_confirmed'),
      [ResendConfirmResult.RCS_INVALID_TOKEN]: t('api_resend_confirm_email_status_invalid_token'),
      [ResendConfirmResult.RCS_PENDING_STAMP]: t('api_resend_confirm_email_status_pending_stamp'),
    },
    confirmEmail: {
      [ConfirmCredsResult.CS_ALREADY_CONFIRMED]: t('api_confirm_email_status_already_confirmed'),
      [ConfirmCredsResult.CS_CODE_EXPIRED]: t('api_confirm_email_status_code_expired'),
      [ConfirmCredsResult.CS_INVALID_CODE]: t('api_confirm_email_status_invalid_code'),
      [ConfirmCredsResult.CS_INVALID_TOKEN]: t('api_confirm_email_status_invalid_token'),
      [ConfirmCredsResult.CS_PENDING_STAMP]: t('api_confirm_email_status_pending_stamp'),
    },
    forgotPassword: {
      [RestorePasswordResult.RPR_INVALID_EMAIL]: t('api_forgot_password_status_invalid_email'),
      [RestorePasswordResult.RPR_UNKNOWN_EMAIL]: t('api_forgot_password_status_unknown_email'),
      [RestorePasswordResult.RPR_PENDING_STAMP]: t('api_forgot_password_status_pending_stamp'),
    },
    changePassword: {
      [ChangePasswordResult.CPR_INVALID_NEW_PASSWORD]: t('api_change_password_status_invalid_new_password'),
      [ChangePasswordResult.CPR_INVALID_OLD_PASSWORD]: t('api_change_password_status_invalid_old_password'),
      [ChangePasswordResult.CPR_NOT_REGISTERED]: t('api_change_password_status_not_registered'),
      [ChangePasswordResult.CPR_PENDING_STAMP]: t('api_change_password_status_pending_stamp'),
    },
    resetPassword: {
      [ResetPasswordResult.RP_CODE_EXPIRED]: t('api_reset_password_status_code_expired'),
      [ResetPasswordResult.RP_INVALID_CODE]: t('api_reset_password_status_invalid_code'),
      [ResetPasswordResult.RP_INVALID_EMAIL]: t('api_reset_password_status_invalid_email'),
      [ResetPasswordResult.RP_PASSWORD_MISMATCH]: t('api_reset_password_status_password_mismatch'),
      [ResetPasswordResult.RP_PASSWORD_TOO_SHORT]: t('api_reset_password_status_password_too_short'),
      [ResetPasswordResult.RP_PENDING_STAMP]: t('api_reset_password_status_pending_stamp'),
    },
    changeDetails: {
      [api.ChangeDetailsResponse.Status.CDS_HAS_ERRORS]: t('api_change_details_status_has_errors'),
      [api.ChangeDetailsResponse.Status.CDS_NOT_REGISTERED]: t('api_change_details_status_not_registered'),
    },
  };

  if (!status) {
    return null;
  }

  return errorsDictionary[userResponseType][status] || null;
};
