import {createBrowserHistory} from 'history';
import Long from 'long';

import channelIdFromParam from '../stores/Channel/utils/channelIdFromParam';
import {IRawChat} from '../stores/RawChatsStore/RawChat';


export interface IBrowserHistoryChat {
  id?: string | null;
  channelID?: string | null;
  name?: string | null;
  type?: string | null;
}

export interface IBrowserHistory {
  from?: {
    pathname?: string;
  };
  selectedChannelId?: string | null;
  chatId?: string | null;
  chat?: IBrowserHistoryChat | null;
  avatarId?: string;
}

export const browserHistory = createBrowserHistory();

export default browserHistory;

export function convertChatFromHistory(chat: IBrowserHistoryChat): IRawChat {
  return {
    id: chat.id ? Long.fromString(chat.id) : Long.ZERO,
    channelID: channelIdFromParam(chat.channelID),
    name: chat.name,
    type: chat.type ? Number(chat.type) : 0,
  };
}
