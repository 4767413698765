import {observer} from 'mobx-react';
import React, {Suspense} from 'react';
import {Routes as BrowserRoutes, Navigate, Route, matchPath, useLocation} from 'react-router-dom';

import Loader from 'o-ui/Loader';

import ErrorBoundary from '../components/ErrorBoundary';
import PageLoader from '../components/PageLoader';
import PageLoadingError from '../components/PageLoadingError';
import LazyLayout from '../layouts/LazyLayout';
import LazyPage from '../layouts/LazyPage';
import Paths from '../routes/Paths';
import LocationType from '../stores/RoutingHistory/LocationType';
import useStore from '../stores/useStore';
import {BrowserRouter} from './BrowserRouter';
import requireRegistrationForPath from './Paths/requireRegistrationForPath';
import browserHistory from './browserHistory';
import BillingRedirect from './redirects/BillingRedirect';
import {IRouteProps, routes, settingsRoutes} from './routes';

const WorkspaceLayout = React.lazy(() => import('../layouts/WorkspaceLayout'));
const ChatPage = React.lazy(() => import('../pages/Chat/ChatPage'));
const SettingsPage = React.lazy(() => import('../pages/Settings/SettingsPage'));

const AppRouteLayout = observer(({
  component: Component,
  lazy,
  layout: Layout,
  isAuthProtected,
  ...rest
}: IRouteProps) => {
  const location = useLocation();
  const {userStore} = useStore();

  if (
    isAuthProtected &&
    userStore?.user &&
    !userStore.user.isLoggedIn &&
    !matchPath(Paths.Login, location.pathname) &&
    !matchPath(Paths.Registration, location.pathname)
  ) {
    const requireRegistration = requireRegistrationForPath(location.pathname);
    return (
      <Navigate
        to={requireRegistration ? Paths.Registration : Paths.Login}
        replace
        state={{from: location}}
      />
    );
  }

  const handleRetry = () => {
    window.location.reload();
  };

  if (lazy) {
    return (
      <ErrorBoundary
        onRetry={handleRetry}
        fallback={(props) => <PageLoadingError onRetry={props.onRetry} />}
      >
        <Suspense fallback={<PageLoader />}>
          <Layout>
            <Component {...rest} />
          </Layout>
        </Suspense>
      </ErrorBoundary>
    );
  }

  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
});

export const Routes = React.memo(() => {
  const {routingHistory} = useStore();

  React.useEffect(() => {
    const unlisted = browserHistory.listen(({location, action}) => {
      const _location = location as LocationType;
      routingHistory.locationListener({location: _location, action});
    });

    return () => {
      unlisted();
    };
  }, [routingHistory]);

  return (
    <BrowserRouter history={browserHistory}>
      <Suspense
        fallback={
          <Loader
            size={80}
            className="browser-router d-inline-flex position-absolute-center"
          />
        }
      >
        <BrowserRoutes>
          <Route
            element={<LazyLayout component={WorkspaceLayout} />}
            path={Paths.Workspace}
          >
            <Route
              element={<LazyPage component={BillingRedirect} />}
              path={Paths.BillingPayCancel}
            />


            {settingsRoutes.map((route, idx) => (
              <Route
                key={idx}
                element={<LazyPage component={SettingsPage} />}
                path={route.path}
              />
            ))}

            <Route
              element={<LazyPage component={ChatPage} />}
              path={Paths.ChatFull}
            />
            <Route
              element={<LazyPage component={ChatPage} />}
              path={Paths.UserChat}
            />
          </Route>

          {routes.map((route, idx) => (
            <Route
              key={idx}
              element={<AppRouteLayout {...route} />}
              path={route.path}
            />
          ))}
        </BrowserRoutes>
      </Suspense>
    </BrowserRouter>
  );
});

export default Routes;
