import React, {Suspense} from 'react';

import ErrorBoundary from '../components/ErrorBoundary';
import PageLoader from '../components/PageLoader';
import PageLoadingError from '../components/PageLoadingError';

export default function LazyLayout({component: Component}: {component: React.FC}) {
  const handleRetry = () => {
    window.location.reload();
  };

  return (
    <ErrorBoundary
      onRetry={handleRetry}
      fallback={(props) => <PageLoadingError onRetry={props.onRetry} />}
    >
      <Suspense fallback={<PageLoader />}>
        <Component />
      </Suspense>
    </ErrorBoundary>
  );
}