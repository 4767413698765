import React from 'react';
import Loader from 'o-ui/Loader';

const PageLoader: React.FC<React.PropsWithChildren> = () => {
  return (
    <div className="page-loader w-100 h-100 position-fixed">
      <Loader
        size={80}
        className="d-inline-flex position-absolute-center color-brand-primary"
      />
    </div>
  );
};

export default PageLoader;
